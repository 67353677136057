import { Switch, Route } from 'react-router-dom';
import paths from 'routes/paths';
import ForgotPassword from './ForgotPassword';
import PasswordReset from './PasswordReset';

function ResetPassword() {
    return (
        <Switch>
            <Route exact path={paths.RESET_PASSWORD}>
                <PasswordReset />
            </Route>
            <Route exact path={paths.FORGOT_PASSWORD}>
                <ForgotPassword />
            </Route>
        </Switch>
    );
}

export default ResetPassword;
