import TaskInfo from 'components/TaskInfo';
import { Col, Row } from 'components/Layout';

function CompletedTab({ tasks, available }) {
    if (!available || tasks.length === 0) {
        return (
            <p className="text-center color-gray">
                <small>Tasks are not available!</small>
            </p>
        );
    }

    return (
        <Row gutter={16} type="flex" justify="center" className="mb-25">
            {tasks.map((data, index) => (
                <Col xs={24} md={13} key={index} className="mt-24">
                    <TaskInfo
                        title={data.title}
                        subTitle={data.details}
                        status={'Complete!'}
                    />
                </Col>
            ))}
        </Row>
    );
}

export default CompletedTab;
