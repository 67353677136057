import { Button as AntButton } from 'antd';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const iconStyleLeft = {
    maxWidth: '20px',
    marginRight: '15px',
    position: 'absolute',
};

function SocialButton({
    title,
    onClick,
    className,
    style,
    icon
}) {
    const handleClick = () => {
        if (!onClick) return;

        onClick();
    };

    return (
        <Button
            block
            onClick={handleClick}
            className={className}
            style={style}
        >
            <img src={icon} alt="" style={iconStyleLeft} />
            {title}
        </Button>
    );
}

SocialButton.propTypes = {
    title: PropTypes.any.isRequired,
    icon: PropTypes.string,
    onClick: PropTypes.func,
    className: PropTypes.string,
    style: PropTypes.object,
};

const Button = styled(AntButton)`
    display: flex;
    align-items: center;
    height: 50px;
    margin-top: 15px;
    margin-bottom: 20px;
    img {
        width: 21px;
        height: auto;
    }
    span {
        width: 100%;
        text-align: center;
        display: block;
    }
     
    :hover {
        background: rgba(9, 30, 66, 0.02) !important;
        color: inherit;
    }
`;

export default SocialButton;
