import { Radio, Space } from 'antd';
import { Fragment, useEffect, useState } from 'react';
import { Formik } from 'formik';
import Button from 'components/Button';
import styled from 'styled-components';
import { useApi, endpoints } from 'api';
import { PageHeader } from 'components/Header';
import store from 'store';
import Version from 'components/Version';
import { ContentWrap } from 'components/Layout';
import { useHistory } from 'react-router-dom';
import paths from 'routes/paths';

function SendOTP(props) {
    const [target, setTarget] = useState('phone');
    const case_id = store.get('case_id');
    const history = useHistory();
    // eslint-disable-next-line no-unused-vars
    const [phoneraw, setPhoneraw] = useState('');
    const [initialValues, updateInitialValues] = useState({
        case_id: case_id,
        phone: '',
        email: ''
    });
    const [api] = useApi(false);
    const [responseMsg, setResponseMsg] = useState();
    const [inputShow, inputHide] = useState(false);
    const [showMsg, hideMsg] = useState(false);

    const onChange = (e) => {
        console.log('radio checked', e.target.value);
        setTarget(e.target.value)
    };

    useEffect(() => {
        const getOtpSource = async () => {
            try {
                const data = { "case_id": case_id, "dummy": "123" }
                const req = await api({ method: 'POST', url: endpoints.GET_OTP_SOURCE, data });
                const res = await req.data;

                if (res) {
                    updateInitialValues({
                        case_id: case_id,
                        phone: res.phone,
                        email: res.email
                    })
                    let phone = res.phone
                    phone = phone.replaceAll("(", "")
                    phone = phone.replaceAll(")", "")
                    phone = phone.replaceAll("-", "")
                    setPhoneraw(phone)

                    store.set('phone', res.phone);
                    store.set('email', res.email);
                }
            } catch (error) {
                console.log(error);
            }
        };

        getOtpSource();
    }, [api, case_id]);

    const sendOtpRequest = async (data) => {
        const postData = { "case_id": case_id, "target": target }
        store.set('target', target);
        // eslint-disable-next-line no-undef
        try {
            const req = await api({
                method: 'POST',
                url: endpoints.SEND_OTP,
                data: postData,
            });

            const res = await req.data;
            if (res) {
                setResponseMsg(res.msg);
                hideMsg(true);
                history.push(paths.OTP_CONFIRM);
            }
        } catch (error) {
            const { data } = error.response;
            setResponseMsg(data.detail);
            hideMsg(true);
        }
    };

    return (
        <Wrapper>
            <section style={{ flex: 1 }}>
                <PageHeader>
                    <Fragment>
                        <h1 className="mb-20">
                            Complete Signup by verifying your phone or email
                        </h1>
                        <p>To complete your account authorization, please select a delivery method to receive a 6-digit otp code.</p>
                    </Fragment>

                </PageHeader>
                <ContentWrap width="490px">
                    <p className="">
                        How do you want to receive OTP?
                    </p>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={sendOtpRequest}
                        // validationSchema={validationRules}
                        enableReinitialize
                        validateOnMount
                    >
                        {({ isValid, handleSubmit, isSubmitting }) => (
                            <form onSubmit={handleSubmit} className="mt-32">
                                <Radio.Group onChange={onChange} value={target} className="display-block">
                                    <Space direction="vertical">
                                        <Radio checked={true} value={'phone'}>Phone ({phoneraw.substring(0, 3)})-***-{phoneraw.substr(-4)}</Radio>
                                        <Radio value={'email'}>Email ({initialValues.email})</Radio>
                                    </Space>
                                </Radio.Group>
                                <Button
                                    block
                                    type="primary"
                                    htmlType="submit"
                                    className="mt-16 mb-16"
                                    disabled={!isValid}
                                    onClick={handleSubmit}
                                    loading={isSubmitting}
                                >
                                    Get OTP Code
                                </Button>
                            </form>
                        )}
                    </Formik>
                </ContentWrap>
            </section>
            <Version />
        </Wrapper>
    );
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 122px);
    form {
        @media (max-width: 767px) {
            padding: 0 32px;
        }
    }
`;

export default SendOTP;
