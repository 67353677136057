import { useApi, endpoints } from 'api';
import { useEffect, useState } from 'react';
import ToDoContext from './ToDoContext';

function ToDoProvider({ children }) {
    const [toDoStatus, setToDoStatus] = useState('');
    const [api] = useApi();

    useEffect(() => {
        const getUserToDoStatus = async () => {
            try {
                const req = await api({ url: endpoints.TO_DO_STATUS });
                const res = await req.data;

                if (res.data.id && res.data.id > 0) {
                    setToDoStatus(res.data.value);
                } else {
                    setToDoStatus('');
                }

            } catch (error) {
                console.log(error);
            }
        };

        getUserToDoStatus()
    }, [api]);

    return (
        <ToDoContext.Provider value={{ toDoStatus }}>
            {children}
        </ToDoContext.Provider>
    );
}

export default ToDoProvider;
