import styled from 'styled-components';
import { Link } from 'react-router-dom';
import paths from 'routes/paths';
import { PageHeader } from 'components/Header';
import { ContentWrap } from 'components/Layout';
function EmailSendSuccess(props) {

    return (
        <Wrapper>
            <PageHeader>
                <h1>
                    We have received your password recovery request!
                </h1>
            </PageHeader>
            <ContentWrap width="490px">
                <form>
                    <p>
                        Please check your email and click the link that is attached with email body, If you did not
                        get any email please try again{' '}
                        <Link to={paths.LOGIN} className="text-underline">
                            click here
                        </Link>
                    </p>
                </form>
            </ContentWrap>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 122px);
    form {
        @media (max-width: 767px) {
            padding: 0 32px;
        }
    }
`;

export default EmailSendSuccess;
