import { Button, Divider, Row, Col } from 'antd';
import { RadioGroup, TextInput, TextAreaInput } from 'components/FormFields';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { ConfirmationPrompt } from '../ConfirmationPrompt';
import { useState, useEffect } from 'react';


export function ExpensesDeductions({ data, previousData, handleSubmit, handleBack }) {
  const initialValues = {
    is_standart_deduction: data.is_standart_deduction || previousData.is_standart_deduction || '',
    expenses_deduction_medical_dental: data.expenses_deduction_medical_dental || previousData.expenses_deduction_medical_dental || '',
    expenses_deduction_mortgage_interest: data.expenses_deduction_mortgage_interest || previousData.expenses_deduction_mortgage_interest || '',
    expenses_deduction_state_local_income: data.expenses_deduction_state_local_income || previousData.expenses_deduction_state_local_income || '',
    expenses_deduction_charity_cash: data.expenses_deduction_charity_cash || previousData.expenses_deduction_charity_cash || '',
    expenses_deduction_sales: data.expenses_deduction_sales || previousData.expenses_deduction_sales || '',
    expenses_deduction_real_estate: data.expenses_deduction_real_estate || previousData.expenses_deduction_real_estate || '',
    expenses_deduction_other: data.expenses_deduction_other || previousData.expenses_deduction_other || '',
    comment_expenses_deductions: data.comment_expenses_deductions || previousData.comment_expenses_deductions || '',
  }

  const validationSchema = Yup.object().shape({
    is_standart_deduction: Yup.string().required('Required'),
  })

  const BOOLEAN_OPTIONS = [
    { label: 'Standard', value: 'Yes' },
    { label: 'Itemized', value: 'No' },
  ]

  const [isConfirmation, setIsConfirmation] = useState(true);

  useEffect(() => {
    setIsConfirmation(!data.is_standart_deduction && !!previousData.is_standart_deduction)
  }, [data, previousData]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnMount
      validateOnChange
      enableReinitialize>
      {({ setFieldValue, values, submitForm }) => (
        <Form>
          {!isConfirmation && (
            <>
              <Divider orientation="left" orientationMargin="0" style={{ color: '#6A7581' }}>
                Specify your Deductions
              </Divider>
              <RadioGroup
                required
                options={BOOLEAN_OPTIONS}
                label={"Would you like to take the standard deduction or enter itemized deductions?"}
                name="is_standart_deduction"
              />

              {values.is_standart_deduction == 'No' && (
                <>
                  <Row>
                    <Col span={12} style={{ paddingRight: 8 }}>
                      <TextInput
                        type="number"
                        prefix="$"
                        name="expenses_deduction_medical_dental"
                        label="Medical and dental expenses" />
                      <TextInput
                        type="number"
                        prefix="$"
                        name="expenses_deduction_mortgage_interest"
                        label="Mortgage interest" />
                      <TextInput
                        type="number"
                        prefix="$"
                        name="expenses_deduction_state_local_income"
                        label="State and local income taxes" />
                      <TextInput
                        type="number"
                        prefix="$"
                        name="expenses_deduction_charity_cash"
                        label="Donations to charity (cash)" />
                      <TextInput
                        type="number"
                        prefix="$"
                        name="expenses_deduction_sales"
                        label="Sales taxes" />
                    </Col>
                    <Col span={12}>
                      <TextInput
                        type="number"
                        prefix="$"
                        name="expenses_deduction_charity_non_cash"
                        label="Donations to charity (non-cash)" />
                      <TextInput
                        type="number"
                        prefix="$"
                        name="expenses_deduction_real_estate"
                        label="Real estate taxes" />
                      <TextInput
                        type="number"
                        prefix="$"
                        name="expenses_deduction_casualty_theft"
                        label="Casualty and theft losses" />
                      <TextInput
                        type="number"
                        prefix="$"
                        name="expenses_deduction_personal_property"
                        label="Personal property taxes" />
                      <TextInput
                        type="number"
                        prefix="$"
                        name="expenses_deduction_gambling"
                        label="Gambling losses" />
                    </Col>
                  </Row>

                </>
              )}
              <div>
                <Button type="secondary" htmlType="button" onClick={handleBack}>
                  Back
                </Button>
                <Button type="primary" htmlType="submit" style={{ float: 'right' }}>
                  Continue
                </Button>
              </div>
              <Row>
                <Col span={24}>
                  <TextAreaInput
                    name="comment_expenses_deductions"
                    label="Comment (optional)"
                    placeholder="Enter your comment here"
                  />
                </Col>
              </Row>
            </>
          )}
          {isConfirmation && (
            <ConfirmationPrompt
              submitForm={submitForm}
              setIsConfirmation={setIsConfirmation}
            >
              <p>
                Standard Deduction: <b>{previousData.is_standart_deduction}</b>
              </p>

              {previousData.is_standart_deduction == 'No' && (
                <>
                  <p>
                    Medical and dental expenses: <b>{previousData.expenses_deduction_medical_dental}</b>
                  </p>
                  <p>
                    Mortgage interest: <b>{previousData.expenses_deduction_mortgage_interest}</b>
                  </p>
                  <p>
                    State and local income taxes: <b>{previousData.expenses_deduction_state_local_income}</b>
                  </p>
                  <p>
                    Donations to charity (cash): <b>{previousData.expenses_deduction_charity_cash}</b>
                  </p>
                  <p>
                    Sales taxes: <b>{previousData.expenses_deduction_sales}</b>
                  </p>
                  <p>
                    Donations to charity (non-cash): <b>{previousData.expenses_deduction_charity_non_cash}</b>
                  </p>
                  <p>
                    Real estate taxes: <b>{previousData.expenses_deduction_real_estate}</b>
                  </p>
                  <p>
                    Casualty and theft losses: <b>{previousData.expenses_deduction_casualty_theft}</b>
                  </p>
                  <p>
                    Personal property taxes: <b>{previousData.expenses_deduction_personal_property}</b>
                  </p>
                  <p>
                    Gambling losses: <b>{previousData.expenses_deduction_gambling}</b>
                  </p>
                </>
              )}

            </ConfirmationPrompt>
          )}
        </Form>
      )}
    </Formik>
  )
}
