import styled from 'styled-components';

function InnerWrap({ children, ...props }) {
    return <Wrapper {...props}>{children}</Wrapper>;
}

const Wrapper = styled.div`
    padding: 16px 32px;
    border-bottom: ${(props) => (props.noBorder ? '0' : '1px solid #e0e0e0')};

    @media (min-width: 768px) {
        padding-left: ${(props) => (props.deskResetX ? '0' : '32px')};
        padding-right: ${(props) => (props.deskResetX ? '0' : '32px')};
    }
`;

export default InnerWrap;
