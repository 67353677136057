import { Switch, Route } from 'react-router-dom';
import paths from 'routes/paths';
import SignUp from './SignUp';
import EmailVerification from './EmailVerification';
import SendOTP from './SendOTP';
import OTPConfirm from './OTPConfirm';
import EmailResend from './EmailResend';

function SignUpProcess() {
    return (
        <Switch>
            <Route exact path={paths.SEND_OTP}>
                <SendOTP />
            </Route>
            <Route exact path={paths.OTP_CONFIRM}>
                <OTPConfirm />
            </Route>
            <Route exact path={paths.EMAIL_VERIFICATION}>
                <EmailVerification />
            </Route>
            <Route exact path={paths.EMAIL_RESEND}>
                <EmailResend />
            </Route>
            <Route exact path={paths.SIGN_UP}>
                <SignUp />
            </Route>
        </Switch>
    );
}

export default SignUpProcess;
