import { Button } from 'antd';
import { TextInput, DateInput, RadioGroup, TextAreaInput } from 'components/FormFields';
import { Formik, FieldArray, Form } from 'formik';
import { Col, Row, Divider } from 'antd';
import React from 'react';
import * as Yup from 'yup';
import { BOOLEAN_OPTIONS } from 'const';
import { ConfirmationPrompt } from '../ConfirmationPrompt';
import { useState, useEffect } from 'react';

export const SoleProprietorshipVehicles = ({ data, previousData, handleSubmit, handleBack }) => {
  const initialValues = {
    sole_prroprietorship_is_vehicles: data.sole_prroprietorship_is_vehicles || previousData.sole_prroprietorship_is_vehicles || '',
    sole_proprietorship_vehicles: data.sole_proprietorship_vehicles || [],
    comment_sole_proprietorship_vehicles: data.comment_sole_proprietorship_vehicles || previousData.comment_sole_proprietorship_vehicles || '',
  };

  const validationSchema = Yup.object().shape({
    sole_prroprietorship_is_vehicles: Yup.string().required('Required'),
    sole_prroprietorship_vehicles: Yup.array(
      Yup.object({
        description: Yup.string().required('Required'),
        date_in_service_from: Yup.string().required('Required'),
        business_miles: Yup.string().required('Required'),
        total_miles: Yup.string().required('Required'),
        expense_interest_on_loan: Yup.string().required('Required'),
        expenses_parking_and_tolls: Yup.string().required('Required'),
        expenses_porsonal_property_taxes: Yup.string().required('Required'),
        expenses_fuel_and_oil: Yup.string().required('Required'),
        expenses_repairs_and_maintenance: Yup.string().required('Required'),
        expenses_insurance: Yup.string().required('Required'),
        expenses_license_and_registration: Yup.string().required('Required'),
        expenses_lease_payments: Yup.string().required('Required'),
        expenses_other: Yup.string().required('Required'),
      })
    )
  })

  const [isConfirmation, setIsConfirmation] = useState(true);

  useEffect(() => {
    setIsConfirmation(!data.sole_prroprietorship_is_vehicles && !!previousData.sole_prroprietorship_is_vehicles)
  }, [data, previousData]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnMount
      validateOnChange
      enableReinitialize>
      {({ setFieldValue, values, submitForm }) => (
        <Form>
          {!isConfirmation && (
            <>
              <Divider orientation="left" orientationMargin="0" style={{ color: '#6A7581' }}>
                Business Vehicles
              </Divider>

              <RadioGroup
                required
                name="sole_prroprietorship_is_vehicles"
                label="Did you purchase any vehicles for your business?"
                options={BOOLEAN_OPTIONS}
              />

              {values.sole_prroprietorship_is_vehicles === 'Yes' && (
                <FieldArray name="sole_proprietorship_vehicles">
                  {({ insert, remove, push }) => (
                    <div>
                      {values.sole_proprietorship_vehicles.length > 0 &&
                        values.sole_proprietorship_vehicles.map((vehicle, index) => (
                          <div key={index}>
                            <Row key={index}>
                              <Col span={24}>
                                <TextInput
                                  required
                                  name={`sole_proprietorship_vehicles.${index}.description`}
                                  label="Year/Make/Model"
                                />
                              </Col>
                            </Row>

                            <Row key={index}>

                              <Col span={12} style={{ padding: 6 }}>
                                <DateInput
                                  required
                                  value={values.sole_proprietorship_vehicles[index].date_in_service_from}
                                  onChange={(value, valueStr) => { setFieldValue(`sole_proprietorship_vehicles.${index}.date_in_service_from`, valueStr) }}
                                  name={`sole_proprietorship_vehicles.${index}.date_in_service_from`}
                                  label="Date in service from"
                                />

                                <TextInput
                                  required
                                  name={`sole_proprietorship_vehicles.${index}.business_miles`}
                                  label="Business miles"
                                  type="number"
                                />

                                <TextInput
                                  required
                                  name={`sole_proprietorship_vehicles.${index}.total_miles`}
                                  label="Total miles"
                                  type="number"
                                />

                                <TextInput
                                  required
                                  type="number"
                                  prefix="$"
                                  name={`sole_proprietorship_vehicles.${index}.expense_interest_on_loan`}
                                  label="Expense interest on loan"
                                />

                                <TextInput
                                  required
                                  type="number"
                                  prefix="$"
                                  name={`sole_proprietorship_vehicles.${index}.expenses_parking_and_tolls`}
                                  label="Expenses parking and tolls"
                                />

                                <TextInput
                                  required
                                  type="number"
                                  prefix="$"
                                  name={`sole_proprietorship_vehicles.${index}.expenses_porsonal_property_taxes`}
                                  label="Expenses porsonal property taxes"
                                />
                              </Col>

                              <Col span={12} style={{ padding: 6 }}>

                                <TextInput
                                  required
                                  type="number"
                                  prefix="$"
                                  name={`sole_proprietorship_vehicles.${index}.expenses_fuel_and_oil`}
                                  label="Expenses fuel and oil"
                                />

                                <TextInput
                                  required
                                  type="number"
                                  prefix="$"
                                  name={`sole_proprietorship_vehicles.${index}.expenses_repairs_and_maintenance`}
                                  label="Expenses repairs and maintenance"
                                />

                                <TextInput
                                  required
                                  type="number"
                                  prefix="$"
                                  name={`sole_proprietorship_vehicles.${index}.expenses_insurance`}
                                  label="Expenses insurance"
                                />

                                <TextInput
                                  required
                                  type="number"
                                  prefix="$"
                                  name={`sole_proprietorship_vehicles.${index}.expenses_license_and_registration`}
                                  label="Expenses license and registration"
                                />

                                <TextInput
                                  required
                                  type="number"
                                  prefix="$"
                                  name={`sole_proprietorship_vehicles.${index}.expenses_lease_payments`}
                                  label="Expenses lease payments"
                                />

                                <TextInput
                                  required
                                  type="number"
                                  prefix="$"
                                  name={`sole_proprietorship_vehicles.${index}.expenses_other`}
                                  label="Expenses other"
                                />
                              </Col>

                              <Button danger htmlType="button" style={{ width: '100%' }}
                                onClick={() => remove(index)} >
                                Remove Vehicle
                              </Button>
                              <Divider plain></Divider>
                            </Row>
                          </div>
                        ))}
                      <Button type="secondary" htmlType="button" style={{ width: '100%' }}
                        onClick={() => push({ first_name: '', last_name: '', ssn: '', date_of_birth: '', relationship: '' })} >
                        Add Vehicle
                      </Button>
                    </div>
                  )}
                </FieldArray>
              )}
              <Divider plain></Divider>
              <div>
                <Button type="secondary" htmlType="button" onClick={handleBack}>
                  Back
                </Button>
                <Button type="primary" htmlType="submit" style={{ float: 'right' }}>
                  Continue
                </Button>
              </div>
              <Row>
                <Col span={24}>
                  <TextAreaInput
                    name="comment_sole_proprietorship_vehicles"
                    label="Comment (optional)"
                    placeholder="Enter your comment here"
                  />
                </Col>
              </Row>
            </>
          )}
          {isConfirmation && (
            <ConfirmationPrompt
              submitForm={submitForm}
              setIsConfirmation={setIsConfirmation}
            >
              <p>Did you purchase any vehicles for your business? <b>{previousData.sole_proprietorship_is_vehicles}</b></p>
              {previousData.sole_proprietorship_vehicles?.map((vehicle, index) => {
                return (
                  <div key={index}>
                    <p>Description: {vehicle.description}</p>
                  </div>
                )
              })}
            </ConfirmationPrompt>
          )}
        </Form>
      )}
    </Formik>
  )
};
