import Layout from 'containers/Layout';
import { useHistory } from 'react-router-dom';
import paths from './paths';

function PrivateLayout({ children }) {
    const { push } = useHistory();

    const redirectToDashboard = () => push(paths.DASHBOARD);

    return <Layout onLogoClick={redirectToDashboard}>{children}</Layout>;
}

export default PrivateLayout;
