import { Radio } from 'antd';
import FieldWrapper from './FieldWrapper';
import { useField } from 'formik';
import { useMemo } from 'react';

function RadioGroup({ label, required, name, children, onChange, ...props }) {
    const [field, meta, helper] = useField(name);
    const { touched, error } = meta;

    const isInvalid = useMemo(() => {
        return !!(touched && error);
    }, [touched, error]);

    return (
        <FieldWrapper
            isInvalid={isInvalid}
            label={label}
            required={required}
            error={error}
        >
            {
                onChange ?
                    <Radio.Group  {...field} {...props} onChange={onChange}>
                        {children}
                    </Radio.Group> 
                    : 
                    <Radio.Group  {...field} {...props} >
                        {children}
                    </Radio.Group>
            }
        </FieldWrapper>
    );
}

export default RadioGroup;
