import { Button } from 'antd';
import { TextInput, TextAreaInput } from 'components/FormFields';
import { Formik, FieldArray, Form } from 'formik';
import { Col, Row, Divider } from 'antd';
import React from 'react';
import { ConfirmationPrompt } from '../ConfirmationPrompt';
import { useState, useEffect } from 'react';

export const SoleProprietorshipExpenses = ({ data, previousData, handleSubmit, handleBack }) => {
  const initialValues = {
    sole_proprietorship_expenses_advertising: data.sole_proprietorship_expenses_advertising || previousData.sole_proprietorship_expenses_advertising || '',
    sole_proprietorship_expenses_commissions_and_fees: data.sole_proprietorship_expenses_commissions_and_fees || previousData.sole_proprietorship_expenses_commissions_and_fees || '',
    sole_proprietorship_expenses_contract_labor: data.sole_proprietorship_expenses_contract_labor || previousData.sole_proprietorship_expenses_contract_labor || '',
    sole_proprietorship_expenses_employee_benefit_programs: data.sole_proprietorship_expenses_employee_benefit_programs || previousData.sole_proprietorship_expenses_employee_benefit_programs || '',
    sole_proprietorship_expenses_insurance_other_than_health: data.sole_proprietorship_expenses_insurance_other_than_health || previousData.sole_proprietorship_expenses_insurance_other_than_health || '',
    sole_proprietorship_expenses_interest_mortgage: data.sole_proprietorship_expenses_interest_mortgage || previousData.sole_proprietorship_expenses_interest_mortgage || '',
    sole_proprietorship_expenses_interest_other: data.sole_proprietorship_expenses_interest_other || previousData.sole_proprietorship_expenses_interest_other || '',
    sole_proprietorship_expenses_legal_and_professional_services: data.sole_proprietorship_expenses_legal_and_professional_services || previousData.sole_proprietorship_expenses_legal_and_professional_services || '',
    sole_proprietorship_expenses_office_expenses: data.sole_proprietorship_expenses_office_expenses || previousData.sole_proprietorship_expenses_office_expenses || '',
    
    sole_proprietorship_expenses_pension_and_profit_sharing_plans: data.sole_proprietorship_expenses_pension_and_profit_sharing_plans || previousData.sole_proprietorship_expenses_pension_and_profit_sharing_plans || '',
    sole_proprietorship_expenses_rent: data.sole_proprietorship_expenses_rent || previousData.sole_proprietorship_expenses_rent || '',
    sole_proprietorship_expenses_repairs_and_maintenance: data.sole_proprietorship_expenses_repairs_and_maintenance || previousData.sole_proprietorship_expenses_repairs_and_maintenance || '',
    sole_proprietorship_expenses_supplies: data.sole_proprietorship_expenses_supplies || previousData.sole_proprietorship_expenses_supplies || '',
    sole_proprietorship_expenses_taxes_and_licenses: data.sole_proprietorship_expenses_taxes_and_licenses || previousData.sole_proprietorship_expenses_taxes_and_licenses || '',
    sole_proprietorship_expenses_travel: data.sole_proprietorship_expenses_travel || previousData.sole_proprietorship_expenses_travel || '',
    sole_proprietorship_expenses_meal: data.sole_proprietorship_expenses_meal || previousData.sole_proprietorship_expenses_meal || '',
    sole_proprietorship_expenses_utilities: data.sole_proprietorship_expenses_utilities || previousData.sole_proprietorship_expenses_utilities || '',
    sole_proprietorship_expenses_wages: data.sole_proprietorship_expenses_wages || previousData.sole_proprietorship_expenses_wages || '',
    
    sole_proprietorship_expenses_other: data.sole_proprietorship_expenses_other || previousData.sole_proprietorship_expenses_other || [],

    comment_sole_proprietorship_expenses: data.comment_sole_proprietorship_expenses || previousData.comment_sole_proprietorship_expenses || '',
  };

  const [isConfirmation, setIsConfirmation] = useState(true);

  useEffect(() => {
    setIsConfirmation(false)
  }, [data, previousData]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validateOnMount
      validateOnChange
      enableReinitialize>
      {({ setFieldValue, values, submitForm }) => (
        <Form>
          {!isConfirmation && (
            <>
              <Divider orientation="left" orientationMargin="0" style={{ color: '#6A7581' }}>
                Business Expenses
              </Divider>
              <Row>
                <Col span={12} style={{ paddingRight: 6 }}>
                  <TextInput
                    name="sole_proprietorship_expenses_advertising"
                    label="Advertising"
                    type="number"
                    prefix="$"
                  />

                  <TextInput
                    name="sole_proprietorship_expenses_commissions_and_fees"
                    label="Commissions and fees"
                    type="number"
                    prefix="$"
                  />

                  <TextInput
                    name="sole_proprietorship_expenses_contract_labor"
                    label="Contract labor"
                    type="number"
                    prefix="$"
                  />

                  <TextInput
                    name="sole_proprietorship_expenses_employee_benefit_programs"
                    label="Employee benefit programs"
                    type="number"
                    prefix="$"
                  />

                  <TextInput
                    name="sole_proprietorship_expenses_insurance_other_than_health"
                    label="Insurance (other than health)"
                    type="number"
                    prefix="$"
                  />

                  <TextInput
                    name="sole_proprietorship_expenses_interest_mortgage"
                    label="Interest - mortgage"
                    type="number"
                    prefix="$"
                  />

                  <TextInput
                    name="sole_proprietorship_expenses_interest_other"
                    label="Interest - other"
                    type="number"
                    prefix="$"
                  />

                  <TextInput
                    name="sole_proprietorship_expenses_legal_and_professional_services"
                    label="Legal and professional services"
                    type="number"
                    prefix="$"
                  />

                  <TextInput
                    name="sole_proprietorship_expenses_office_expenses"
                    label="Office expenses"
                    type="number"
                    prefix="$"
                  />

                </Col>
                <Col span={12}>
                  <TextInput
                    name="sole_proprietorship_expenses_pension_and_profit_sharing_plans"
                    label="Pension and profit-sharing plans"
                    type="number"
                    prefix="$"
                  />

                  <TextInput
                    name="sole_proprietorship_expenses_rent_or_lease"
                    label="Rent or lease"
                    type="number"
                    prefix="$"
                  />

                  <TextInput
                    name="sole_proprietorship_expenses_repairs_and_maintenance"
                    label="Repairs and maintenance"
                    type="number"
                    prefix="$"
                  />

                  <TextInput
                    name="sole_proprietorship_expenses_supplies"
                    label="Supplies"
                    type="number"
                    prefix="$"
                  />

                  <TextInput
                    name="sole_proprietorship_expenses_taxes_and_licenses"
                    label="Taxes and licenses"
                    type="number"
                    prefix="$"
                  />

                  <TextInput
                    name="sole_proprietorship_expenses_travel"
                    label="Travel"
                    type="number"
                    prefix="$"
                  />

                  <TextInput
                    name="sole_proprietorship_expenses_meals"
                    label="Meals"
                    type="number"
                    prefix="$"
                  />

                  <TextInput
                    name="sole_proprietorship_expenses_utilities"
                    label="Utilities"
                    type="number"
                    prefix="$"
                  />

                  <TextInput
                    name="sole_proprietorship_expenses_wages"
                    label="Wages"
                    type="number"
                    prefix="$"
                  />

                </Col>
              </Row>

              <Divider orientation="left" orientationMargin="0" style={{ color: '#6A7581' }}>
                Other Business Expenses
              </Divider>

              <FieldArray name="sole_proprietorship_expenses_other">
                {({ insert, remove, push }) => (
                  <div>
                    {values.sole_proprietorship_expenses_other.length > 0 &&
                      values.sole_proprietorship_expenses_other.map((friend, index) => (
                        <Row key={index}>
                          <Col span={12} style={{ padding: 6 }}>
                            <TextInput
                              required
                              name={`sole_proprietorship_expenses_other.${index}.description`}
                              label="Description"
                            />
                          </Col>
                          <Col span={12} style={{ padding: 6 }}>
                            <TextInput
                              required
                              type="number"
                              prefix="$"
                              name={`sole_proprietorship_expenses_other.${index}.amount`}
                              label="Amount"
                            /></Col>

                          <Button danger htmlType="button" style={{ width: '100%' }}
                            onClick={() => remove(index)} >
                            Remove Expense
                          </Button>
                          <Divider plain></Divider>
                        </Row>
                      ))}
                    <Button type="secondary" htmlType="button" style={{ width: '100%' }}
                      onClick={() => push({ description: '', amount: '' })} >
                      Add Expense
                    </Button>
                    <Divider plain></Divider>
                    <div>
                      <Button type="secondary" htmlType="button" onClick={handleBack}>
                        Back
                      </Button>
                      <Button type="primary" htmlType="submit" style={{ float: 'right' }}>
                        Continue
                      </Button>
                    </div>
                    <Row>
                      <Col span={24}>
                        <TextAreaInput
                          name="comment_sole_proprietorship_expenses"
                          label="Comment (optional)"
                          placeholder="Enter your comment here"
                        />
                      </Col>
                    </Row>
                  </div>
                )}
              </FieldArray>
            </>
          )}
          {isConfirmation && (
            <ConfirmationPrompt
              submitForm={submitForm}
              setIsConfirmation={setIsConfirmation}
            >
              <p>Advertising: {previousData.sole_proprietorship_expenses_advertising}</p>
              <p>Commissions and fees: {previousData.sole_proprietorship_expenses_commissions_and_fees}</p>
              <p>Contract labor: {previousData.sole_proprietorship_expenses_contract_labor}</p>
              <p>Employee benefit programs: {previousData.sole_proprietorship_expenses_employee_benefit_programs}</p>
              <p>Insurance (other than health): {previousData.sole_proprietorship_expenses_insurance_other_than_health}</p>
              <p>Interest - mortgage: {previousData.sole_proprietorship_expenses_interest_mortgage}</p>
              <p>Interest - other: {previousData.sole_proprietorship_expenses_interest_other}</p>
              <p>Legal and professional services: {previousData.sole_proprietorship_expenses_legal_and_professional_services}</p>
              <p>Office expenses: {previousData.sole_proprietorship_expenses_office_expenses}</p>
              <p>Pension and profit-sharing plans: {previousData.sole_proprietorship_expenses_pension_and_profit_sharing_plans}</p>
              <p>Rent or lease: {previousData.sole_proprietorship_expenses_rent_or_lease}</p>
              <p>Repairs and maintenance: {previousData.sole_proprietorship_expenses_repairs_and_maintenance}</p>
              <p>Supplies: {previousData.sole_proprietorship_expenses_supplies}</p>
              <p>Taxes and licenses: {previousData.sole_proprietorship_expenses_taxes_and_licenses}</p>
              <p>Travel: {previousData.sole_proprietorship_expenses_travel}</p>
              <p>Meals: {previousData.sole_proprietorship_expenses_meals}</p>
              <p>Utilities: {previousData.sole_proprietorship_expenses_utilities}</p>
              <p>Wages: {previousData.sole_proprietorship_expenses_wages}</p>
              <p>Other Business Expenses: </p>
              {previousData.sole_proprietorship_expenses_other?.map((expense, index) => {
                return (
                  <div key={index}>
                    <p>{expense.diescription}: {expense.amount}</p>
                  </div>
                )
              })}
            </ConfirmationPrompt>
          )}
        </Form>
      )}
    </Formik>
  )
};
