import { Button } from 'antd';
import { TextInput, DateInput, RadioGroup, TextAreaInput } from 'components/FormFields';
import { Formik, FieldArray, Form } from 'formik';
import { Col, Row, Divider } from 'antd';
import React from 'react';
import * as Yup from 'yup';
import { BOOLEAN_OPTIONS } from 'const';
import { ConfirmationPrompt } from '../ConfirmationPrompt';
import { useState, useEffect } from 'react';

export const SoleProprietorshipAssets = ({ data, previousData, handleSubmit, handleBack }) => {
  const initialValues = {
    sole_proprietorship_is_assets_purchased: data.sole_proprietorship_is_assets_purchased || previousData.sole_proprietorship_is_assets_purchased || '',
    sole_proprietorship_is_assets_sold: data.sole_proprietorship_is_assets_sold || previousData.sole_proprietorship_is_assets_sold || '',
    sole_proprietorship_assets: data.sole_proprietorship_assets || previousData.sole_proprietorship_assets || [],
    comment_sole_proprietorship_assets: data.comment_sole_proprietorship_assets || previousData.comment_sole_proprietorship_assets || '',
  };

  const validationSchema = Yup.object().shape({
    sole_proprietorship_assets: Yup.array(
      Yup.object({
        description: Yup.string().required('Required'),
        cost: Yup.string().required('Required'),
        date_acquired: Yup.string().required('Required'),
        is_sold: Yup.string().required('Required'),
      })
    )
  })

  const [isConfirmation, setIsConfirmation] = useState(true);

  useEffect(() => {
    setIsConfirmation(!data.sole_proprietorship_assets && !!previousData.sole_proprietorship_assets)
  }, [data, previousData]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnMount
      validateOnChange
      enableReinitialize>
      {({ setFieldValue, values, submitForm }) => (
        <Form>
          {!isConfirmation && (
            <>
              <Divider orientation="left" orientationMargin="0" style={{ color: '#6A7581' }}>
                Business Assets
              </Divider>

              <RadioGroup
                required
                name="sole_proprietorship_is_assets_purchased"
                label="Did you purchase any assets for your business?"
                options={BOOLEAN_OPTIONS}
              />

              <RadioGroup
                required
                name="sole_proprietorship_is_assets_sold"
                label="Did you sell any assets for your business?"
                options={BOOLEAN_OPTIONS}
              />

              {(values.sole_proprietorship_is_assets_purchased === 'Yes' || values.sole_proprietorship_is_assets_sold === 'Yes') && (


                <FieldArray name="sole_proprietorship_assets">
                  {({ insert, remove, push }) => (
                    <div>
                      {values.sole_proprietorship_assets.length > 0 &&
                        values.sole_proprietorship_assets.map((friend, index) => (
                          <Row key={index}>
                            <Col span={24}>
                              <TextInput
                                name={`sole_proprietorship_assets.${index}.description`}
                                label="Description"
                                required
                              />

                              <TextInput
                                name={`sole_proprietorship_assets.${index}.cost`}
                                label="Purchase Price"
                                required
                                type="number"
                                prefix="$"
                              />

                              <DateInput
                                value={values.sole_proprietorship_assets[index].date_acquired}
                                onChange={(value, valueStr) => { setFieldValue(`sole_proprietorship_assets.${index}.date_acquired`, valueStr) }}
                                name={`sole_proprietorship_assets.${index}.date_acquired`}
                                label="Date Acquired"
                                required
                              />

                              <RadioGroup
                                required
                                name={`sole_proprietorship_assets.${index}.is_sold`}
                                label="Is Sold"
                                options={BOOLEAN_OPTIONS}
                              />

                              {values.sole_proprietorship_assets[index].is_sold === 'Yes' && (
                                <>
                                  <DateInput
                                    value={values.sole_proprietorship_assets[index].date_sold}
                                    onChange={(value, valueStr) => { setFieldValue(`sole_proprietorship_assets.${index}.date_sold`, valueStr) }}
                                    name={`sole_proprietorship_assets.${index}.date_sold`}
                                    label="Date Sold"
                                    required
                                  />
                                  <TextInput
                                    name={`sole_proprietorship_assets.${index}.sale_price`}
                                    label="Sale Price"
                                    required
                                    type="number"
                                    prefix="$"
                                  />
                                </>
                              )}

                            </Col>

                            <Button danger htmlType="button" style={{ width: '100%' }}
                              onClick={() => remove(index)} >
                              Remove Asset
                            </Button>
                            <Divider plain></Divider>
                          </Row>
                        ))}
                      <Button type="secondary" htmlType="button" style={{ width: '100%' }}
                        onClick={() => push({})} >
                        Add Asset
                      </Button>
                    </div>
                  )}
                </FieldArray>
              )}
              <Divider plain></Divider>
              <div>
                <Button type="secondary" htmlType="button" onClick={handleBack}>
                  Back
                </Button>
                <Button type="primary" htmlType="submit" style={{ float: 'right' }}>
                  Continue
                </Button>
              </div>
              <Row>
                <Col span={24}>
                  <TextAreaInput
                    name="comment_sole_proprietorship_assets"
                    label="Comment (optional)"
                    placeholder="Enter your comment here"
                  />
                </Col>
              </Row>
            </>
          )}
          {isConfirmation && (
            <ConfirmationPrompt
              submitForm={submitForm}
              setIsConfirmation={setIsConfirmation}
            >
              {previousData.sole_proprietorship_assets?.map((property, index) => {
                return (
                  <div key={index}>
                    <p>Property purchased? <b>{property.sole_proprietorship_is_assets_purchased}</b></p>
                    <p>Property sold? <b>{property.sole_proprietorship_is_assets_sold}</b></p>

                    {property.sole_proprietorship_assets?.map((asset, index) => {
                      return (
                        <div key={index}>
                          <p>Description: <b>{asset.description}</b></p>
                          <p>Cost: <b>{asset.cost}</b></p>
                          <p>Date Acquired: <b>{asset.date_acquired}</b></p>
                          <p>Is Sold: <b>{asset.is_sold}</b></p>
                          {asset.is_sold === 'Yes' && (
                            <>
                              <p>Date Sold: <b>{asset.date_sold}</b></p>
                              <p>Sale Price: <b>{asset.sale_price}</b></p>
                            </>
                          )}

                        </div>
                      )
                    })}
                          
                  </div>
                )
              })}
            </ConfirmationPrompt>
          )}
        </Form>
      )}
    </Formik>
  )
};
