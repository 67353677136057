import { api_ver } from '../api/endpoints';

const LOGIN_PORTAL_REDIRECT = '/login-portal-redirect'
const LOGIN = '/';
const LOGIN_DIRECT = '/login';
const SIGN_UP = '/sign-up';
const EMAIL_RESEND = `${SIGN_UP}/email-resend`;
const EMAIL_VERIFICATION = `${SIGN_UP}/email-verification`;
const SEND_OTP = `${SIGN_UP}/send-otp`;
const OTP_CONFIRM = `${SIGN_UP}/otp-confirm`;
const FORGOT_PASSWORD = '/forgot-password/';
const RESET_PASSWORD = `${FORGOT_PASSWORD}reset-password`;
const RESET_REDIRECT_URL = 'reset-password';
const EMAIL_REDIRECT_URL = 'email-verification';
const SIGN_IN_GOOGLE = `${process.env.REACT_APP_AUTH_SERVICE_ENDPOINT}${api_ver}/social/google/auth`;
const SIGN_IN_FACEBOOK = `${process.env.REACT_APP_AUTH_SERVICE_ENDPOINT}${api_ver}/social/facebook/auth`;
const DASHBOARD = '/dashboard';
const DOCUMENTS = '/documents';
const PAYMENTS = '/payments';
const MAKE_PAYMENTS = `${PAYMENTS}/create`;
const TRANSCRIPT = '/transcript';
const TRANSCRIPT_RED_APPOINTMENT = '/transcript-red-appointment'
const VERIFILINK_RED_APPOINTMENT = '/verifilink-red-appointment'
const CONTACT = '/contact';
const FAQ = '/faq';
const PAYMENT_METHOD = `/payment-method/`; // acknowledge-services
const CARD_INFORMATION = `${PAYMENT_METHOD}card-information/`; // card-information
const PAYPAL_METHOD = `${PAYMENT_METHOD}paypal-options/`; // paypal-options
const PAYPAL_CARD_INFORMATION = `${PAYPAL_METHOD}paypal-card-information/`; // paypal-card-information
const BANKDRAFT_PAYMENT_OPTIONS = `${PAYMENT_METHOD}bank-draft/`; // bankdraft-options
const BANKDRAFT_ACCOUNT_INFO = `${BANKDRAFT_PAYMENT_OPTIONS}bank-account/`; // bank-account-info
const BANKDRAFT_EXISTING_ACCOUNT = `${BANKDRAFT_PAYMENT_OPTIONS}existing-account/`; // existing bank-account-info
const LOGIN_HARDCODED = '/hardcoded';
const VERIFILINK_SUMMARY = '/summary/verifilink/';
const FINANCIAL_SUMMARY = '/summary/financial/';
const CASE_STATUS = '/case-status/';
const WORKFLOW = '/workflows/:workflowId/:documentId'

export default {
    LOGIN_PORTAL_REDIRECT,
    LOGIN,
    SIGN_UP,
    EMAIL_RESEND,
    EMAIL_VERIFICATION,
    SEND_OTP,
    OTP_CONFIRM,
    EMAIL_REDIRECT_URL,
    FORGOT_PASSWORD,
    RESET_PASSWORD,
    RESET_REDIRECT_URL,
    SIGN_IN_GOOGLE,
    SIGN_IN_FACEBOOK,
    DASHBOARD,
    DOCUMENTS,
    PAYMENTS,
    MAKE_PAYMENTS,
    TRANSCRIPT,
    TRANSCRIPT_RED_APPOINTMENT,
    VERIFILINK_RED_APPOINTMENT,
    CONTACT,
    FAQ,
    PAYMENT_METHOD,
    CARD_INFORMATION,
    PAYPAL_METHOD,
    PAYPAL_CARD_INFORMATION,
    BANKDRAFT_PAYMENT_OPTIONS,
    BANKDRAFT_ACCOUNT_INFO,
    BANKDRAFT_EXISTING_ACCOUNT,
    LOGIN_HARDCODED,
    VERIFILINK_SUMMARY,
    FINANCIAL_SUMMARY,
    CASE_STATUS,
    WORKFLOW,
    LOGIN_DIRECT,
};
