import { Fragment, useState } from 'react';
import { Formik } from 'formik';
import { TextInput } from 'components/FormFields';
import Button from 'components/Button';
import styled from 'styled-components';
import * as Yup from 'yup';
import { endpoints, useApi } from 'api';
import { PageHeader } from 'components/Header';
import Version from 'components/Version';
import EmailSendSuccess from './EmailSendSuccess';
import { ContentWrap } from 'components/Layout';
import Message from 'components/Message';
import paths from 'routes/paths';
import { useHistory } from 'react-router-dom';
import { EMAIL_REGEX } from 'const';
import store from 'store';
import { FormattedText } from 'utilities';
const initialValues = {
    case_id: '',
    email: '',
};

const validationRules = Yup.object().shape({
    case_id: Yup.string().required('Please enter case id'),
    email: Yup.string()
        .matches(EMAIL_REGEX, {
            excludeEmptyString: true,
            message: 'Invalid Email',
        })
        .required('Email is Required field'),
});

function ForgotPassword(props) {
    const [show, hideSuccess] = useState(false);
    const [api] = useApi(false);
    const [errorMsg, setErrorMsg] = useState();
    const [showErrMsg, hideErrMsg] = useState(false);
    const history = useHistory();

    const forgotPassword = async (data) => {
        const { case_id, email } = data;
        store.set('case_id', data.case_id);
        try {
            const params = {
                case_id: case_id,
                email: email,
                // eslint-disable-next-line no-undef
                redirect_url: `${process.env['REACT_APP_PASSWORD_REDIRECT_URL']}`,
                // eslint-disable-next-line no-undef
                email_app_name: `${process.env['REACT_APP_EMAIL_APP_NAME']}`,
            };
            const req = await api.post(
                `${endpoints.FORGOT_PASSWORD_INIT}`,
                params
            );

            const res = await req.data;
            if (res) {
                hideSuccess(true);
            }
        } catch (error) {
            const { data } = error.response;
            const result = JSON.parse(data.detail);
            const { msg, is_email_verified } = result;
            setErrorMsg(msg);
            hideErrMsg(true);
            if (!is_email_verified && is_email_verified !== undefined) {
                store.set('key', 'only_resend');
                setTimeout(() => history.push(paths.EMAIL_RESEND), 5000);
            }
        }
    };

    return (
        <Fragment>
            {show === false ? (
                <Wrapper>
                    <section style={{ flex: 1 }}>
                        <PageHeader>
                            <h1 className="mb-8">Forgot your password?</h1>

                            <p className="mb-0">
                                {"Don't"} worry! Resetting your password is
                                easy. Just type in the email and case id you
                                used to register your{' '}
                                <FormattedText
                                    id="app.brand"
                                    defaultValue="TaxRise"
                                />{' '}
                                account.
                            </p>
                        </PageHeader>
                        <ContentWrap width="490px">
                            <Formik
                                initialValues={initialValues}
                                onSubmit={forgotPassword}
                                validationSchema={validationRules}
                                validateOnMount
                            >
                                {({ isValid, handleSubmit, isSubmitting }) => (
                                    <form onSubmit={handleSubmit}>
                                        {showErrMsg === true && (
                                            <Message message={errorMsg} />
                                        )}
                                        <TextInput
                                            name="case_id"
                                            label="Case ID"
                                        />
                                        <TextInput name="email" label="Email" />

                                        <Button
                                            block
                                            type="primary"
                                            htmlType="submit"
                                            className="mt-16"
                                            disabled={!isValid}
                                            onClick={handleSubmit}
                                            loading={isSubmitting}
                                        >
                                            Submit
                                        </Button>
                                    </form>
                                )}
                            </Formik>
                        </ContentWrap>
                    </section>
                    <Version />
                </Wrapper>
            ) : (
                <EmailSendSuccess />
            )}
        </Fragment>
    );
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 122px);
    form {
        @media (max-width: 767px) {
            padding: 0 32px;
        }
    }
`;

export default ForgotPassword;
