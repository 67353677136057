import { Route, Redirect } from 'react-router-dom';
import store from 'store';
import TaxOrganizerLayout from './TaxOrganizerLayout';

function TaxOrganizerRoute({ children, ...rest }) {
  const access_token = store.get('access_token');

  return (
    <Route
      {...rest}
      render={({ location }) =>
        access_token ? (
          <TaxOrganizerLayout>{children}</TaxOrganizerLayout>
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export default TaxOrganizerRoute;
