import Layout from '../containers/TaxOrganizerLayout/Layout';
import { useHistory } from 'react-router-dom';
import paths from './paths';

function TaxOrganizerLayout ({ children }) {
    const { push } = useHistory();

    const redirectToDashboard = () => push(paths.DASHBOARD);

    return <Layout onLogoClick={redirectToDashboard}>{children}</Layout>;
}

export default TaxOrganizerLayout;
