import { useParams } from "react-router-dom";
import { TaxOrganizer } from "./TaxOrganizer/TaxOrganizer";


export function Workflows() {

  const { workflowId, documentId } = useParams();

if (workflowId === "tax-organizer") {
  return <TaxOrganizer documentId={documentId} />;
  }
  else {
    return <div>Workflow: {workflowId} is not implemented yet </div>;
  }
}
