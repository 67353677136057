import { Button, Row, Col, Divider } from 'antd';
import { TextInput, TextAreaInput, RadioGroup } from 'components/FormFields';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { ConfirmationPrompt } from '../ConfirmationPrompt';
import { BOOLEAN_OPTIONS } from 'const';

export function TaxpayerInfo({ data, previousData, handleSubmit, handleBack }) {
  const initialValues = {
    first_name: data.first_name || previousData.first_name || '',
    last_name: data.last_name || previousData.last_name || '',
    // uncomment in case TO tool sold
    // phone: data.phone || previousData.phone || '',
    // email: data.email || previousData.email || '',
    // ssn: data.ssn || previousData.ssn || '',
    // date_of_birth: data.date_of_birth || previousData.date_of_birth || '',
    occupation: data.occupation || previousData.occupation || '',
    is_legally_blind: data.is_legally_blind || previousData.is_legally_blind || '',
    is_totally_disabled: data.is_totally_disabled || previousData.is_totally_disabled || '',
    is_dependent: data.is_dependent || previousData.is_dependent || '',
    is_full_time_student: data.is_full_time_student || previousData.is_full_time_student || '',
    is_election_fund: data.is_election_fund || previousData.is_election_fund || '',
    comment_personal_taxpayer_info: data.comment_personal_taxpayer_info || previousData.comment_personal_taxpayer_info || '',
  }

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required('Required'),
    last_name: Yup.string().required('Required'),
    // uncomment in case TO tool sold
    // phone: Yup.string().matches(
    //   /^\(\d{3}\) \d{3}-\d{4}$/,
    //   'Phone number is not valid'
    // )
    //   .required('Required'),
    // email: Yup.string().email("Valid email required").required('Required'),
    // ssn: Yup.string()
    //   .required('Required')
    //   .test('ssn', 'Invalid SSN format. It should be XXX-XX-XXXX', value => {
    //     const ssnPattern = /^[0-9]{3}-[0-9]{2}-[0-9]{4}$/;
    //     return ssnPattern.test(value);
    //   }),
    // date_of_birth: Yup.string().required('Required'),
    occupation: Yup.string().required('Required'),
  });

  const [isConfirmation, setIsConfirmation] = useState(true);

  useEffect(() => {
    setIsConfirmation(!data.first_name && !!previousData.first_name)
  }, [data, previousData]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnMount
      validateOnChange
      enableReinitialize>
      {({ setFieldValue, values, submitForm }) => (
        <Form>
          {!isConfirmation && (
            <>
              <Divider orientation="left" orientationMargin="0" style={{ color: '#6A7581' }}>
                Taxpayer Information
              </Divider>

              <Row>
                <Col span={12} style={{ paddingRight: 6 }}>
                  <TextInput
                    required
                    name="first_name"
                    label="First Name" />
                </Col>
                <Col span={12} >
                  <TextInput
                    required
                    name="last_name" label="Last Name" />
                </Col>
              </Row>

              {/* <Divider orientation="left" orientationMargin="0" style={{ color: '#6A7581' }}>
                Contact Information
              </Divider>
              <Row>
                <Col span={12} style={{ paddingRight: 6 }}>
                  <TextInput
                    required
                    name="phone"
                    label="Phone Number"
                    onChange={(event) => {
                      const { value } = event.target;
                      const phoneNumber = value.replace(/\D/g, '').slice(0, 10);
                      let formattedPhoneNumber = phoneNumber;
                      if (phoneNumber.length > 6) {
                        formattedPhoneNumber = `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6)}`;
                      } else if (phoneNumber.length > 3) {
                        formattedPhoneNumber = `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
                      } else {
                        formattedPhoneNumber = phoneNumber;
                      }
                      setFieldValue("phone", formattedPhoneNumber);
                    }}
                  />
                </Col>
                <Col span={12} >
                  <TextInput
                    required
                    name="email"
                    label="Email Address" />
                </Col>
              </Row> */}
              <Divider orientation="left" orientationMargin="0" style={{ color: '#6A7581' }}>
                Other Information
              </Divider>
              {/* <Row>
                <Col span={12} style={{ paddingRight: 6 }}>
                  <TextInput
                    required
                    name="ssn"
                    label="SSN/ITIN"
                    onChange={(event) => {
                      const { value } = event.target;
                      const ssn = value.replace(/\D/g, '').slice(0, 9);
                      let formattedSSN = ssn;
                      if (ssn.length > 5) {
                        formattedSSN = `${ssn.slice(0, 3)}-${ssn.slice(3, 5)}-${ssn.slice(5)}`;
                      } else if (ssn.length > 3) {
                        formattedSSN = `${ssn.slice(0, 3)}-${ssn.slice(3)}`;
                      }
                      setFieldValue("ssn", formattedSSN);
                    }}
                  />
                </Col>
                <Col span={12} >
                  <DateInput onChange={(value, valueStr) => { setFieldValue("date_of_birth", valueStr) }}
                    value={values.date_of_birth}
                    required
                    name="date_of_birth"
                    label="Date of Birth" />
                </Col>
              </Row> */}

              <TextInput
                required
                name="occupation"
                label="Occupation" />

              <Divider orientation="left" orientationMargin="0" style={{ color: '#6A7581' }}>
                Taxpayer Specific Questions
              </Divider>

              <Row >
                <RadioGroup
                  required
                  name="is_legally_blind"
                  label={`Were you legally blind as of 12/31/${data.period}?`}
                  options={BOOLEAN_OPTIONS}
                />
              </Row>

              <Row>
                <RadioGroup
                  required
                  name="is_totally_disabled"
                  label={`Were you permanently and totally disabled at any time during ${data.period}?`}
                  options={BOOLEAN_OPTIONS}
                />
              </Row>

              <Row>
                <RadioGroup
                  required
                  name="is_dependent"
                  label={`Were you claimed as a dependent on another individual's ${data.period} return`}
                  options={BOOLEAN_OPTIONS}
                />
              </Row>

              <Row>
                <RadioGroup
                  required
                  name="is_full_time_student"
                  label={`Were you a full-time student during ${data.period}?`}
                  options={BOOLEAN_OPTIONS}
                />
              </Row>

              <Row>
                <Col span={24}>
                  <Button type="primary" htmlType="submit" style={{ float: 'right' }}>
                    Continue
                  </Button>
                </Col>
              </Row>

              <Row>
                <Col span={24}>
                  <TextAreaInput
                    name="comment_personal_taxpayer_info"
                    label="Comment (optional)"
                    placeholder="Enter your comment here"
                  />
                </Col>
              </Row>
            </>
          )}

          {isConfirmation &&
            <ConfirmationPrompt
              submitForm={submitForm}
              setIsConfirmation={setIsConfirmation}>
              <Row>
                <Col span={12} >
                  <p>
                    First Name: <b>{previousData.first_name}</b>
                  </p>
                  <p>
                    Last Name: <b>{previousData.last_name}</b>
                  </p>
                  {/* <p>
                    SSN: <b>{previousData.ssn}</b>
                  </p>
                  <p>
                    Date of Birth: <b>{previousData.date_of_birth}</b>
                  </p>
                  <p>
                    Email: <b>{previousData.email}</b>
                  </p>
                  <p>
                    Phone: <b>{previousData.phone}</b>
                  </p> */}
                </Col>
                <Col span={12} >
                  <p>
                    Occupation: <b>{previousData.occupation}</b>
                  </p>
                  <p>
                    Is Legally Blind: <b>{previousData.is_legally_blind}</b>
                  </p>
                  <p>
                    Is Totally Disabled: <b>{previousData.is_totally_disabled}</b>
                  </p>
                  <p>
                    Is Dependent: <b>{previousData.is_dependent}</b>
                  </p>
                  <p>
                    Is Full Time Student: <b>{previousData.is_full_time_student}</b>
                  </p>
                </Col>
              </Row>
            </ConfirmationPrompt>}
        </Form>
      )}
    </Formik>
  )
}
