import { ContentWrap } from 'components/Layout';
import { Result } from 'antd';

function NotAuthorized() {
    return (
        <ContentWrap>
            <Result
                status="403"
                title="Not Authorized"
                subTitle="Sorry, you are not authorized to access this page."
            />
        </ContentWrap>
    );
}

export default NotAuthorized;
