import { Button, Alert } from 'antd';

export const ConfirmationPrompt = ({ submitForm, children, setIsConfirmation }) => {
  return (
    <div style={{ margin: 15 }}>
      <Alert style={{ marginTop: 30, marginBottom: 30 }}
        message="We have this information from your previous submissions. Please read it carefuly and let us kow if you need to edit it by pressing Edit button." type="info" />
      <div style={{margin: 15}}>
        {children}
      </div>

      <Button type="secondary" htmlType="button" onClick={() => setIsConfirmation(false)}>
        Edit
      </Button>
      <Button type="primary" htmlType="button" onClick={submitForm} style={{ float: 'right' }}>
        Confirm
      </Button>
    </div>
  )
}
