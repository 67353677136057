import { useApi, endpoints } from 'api';
import { UserDetailContext } from 'contexts';
import { useContext, useEffect, useState } from 'react';
import FeatureContext from './FeatureContext';

import SweetAlert2 from 'react-sweetalert2';
// import withReactContent from 'sweetalert2-react-content';

// const MySwal = withReactContent(Swal)

function FeatureProvider({ children }) {
    const authServiceEndpoint = process.env.REACT_APP_AUTH_SERVICE_ENDPOINT;
    const verifilinkUrl = process.env.REACT_APP_VERIFILINK_URL;

    const [featureStatus, setFeatureStatus] = useState({
        verifilink: false,
        transcript: false,
        vlink_complete: false,
        vlink_ever_completed: null,
        show433: false,
    });

    const [swalProps, setSwalProps] = useState({});

    const { userDetailInfo } = useContext(UserDetailContext);
    const [api] = useApi();


    const swalConfirm = async () => {
        try {
            const req = await api({
                method: 'POST',
                url: `${authServiceEndpoint}${endpoints.SHORT_TOKEN}`,
            });

            const res = await req.data;

            if (res['short_token']) {
                window.open(
                    `${verifilinkUrl}externalhandover/${res.short_token}`,
                    '_self'
                );
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        const getUserFeatureStatus = async (caseId) => {
            try {
                const req = await api({
                    url: endpoints.FEATURE_STATUS,
                    params: {
                        'case-id': caseId,
                    },
                });

                const res = await req.data;

                const {
                    transcript,
                    verifilink,
                    vlink_complete,
                    vlink_ever_completed,
                    show433,
                    ...rest
                } = res;

                // Need refactoring
                setFeatureStatus({
                    transcript:
                        typeof transcript === 'boolean' ? transcript : false,
                    verifilink:
                        typeof verifilink === 'boolean' ? verifilink : false,
                    vlink_complete:
                        typeof vlink_complete === 'boolean'
                            ? vlink_complete
                            : false,
                    vlink_ever_completed:
                        typeof vlink_ever_completed === 'boolean'
                            ? vlink_ever_completed
                            : false,
                    show433: typeof show433 === 'boolean' ? show433 : false,
                    ...rest,
                });

                if (!vlink_ever_completed && window.location.pathname == '/dashboard') {
                    setSwalProps({
                        show: true,
                        title: 'Important!',
                        confirmButtonText: 'Begin',
                        confirmButtonColor: '#008AC8'
                    });
                }
            } catch (error) {
                console.log(error);
            }
        };

        if (userDetailInfo['case_id']) {
            getUserFeatureStatus(userDetailInfo['case_id']);
        }
    }, [userDetailInfo, api]);

    const updateFeatureStatus = (status) => {
        const { transcript, verifilink } = status;

        if (typeof transcript === 'boolean') {
            setFeatureStatus((stat) => ({ ...stat, transcript }));
        }

        if (typeof verifilink === 'boolean') {
            setFeatureStatus((stat) => ({ ...stat, verifilink }));
        }
    };

    return (
        <>
            <SweetAlert2 {...swalProps} onConfirm={swalConfirm}>
                <p>
                    In order for us to get started,
                    Please click the button below to
                    complete Verifilink. This is the first step in your
                    Client Portal Journey.
                </p>
            </SweetAlert2>
            <FeatureContext.Provider value={{ featureStatus, updateFeatureStatus }}>
                {children}
            </FeatureContext.Provider>
        </>
    );
}

export default FeatureProvider;
