import { Button } from 'antd';
import { TextInput, DateInput, TextAreaInput } from 'components/FormFields';
import { Formik, FieldArray, Form } from 'formik';
import { Col, Row, Divider } from 'antd';
import { SelectField } from 'components/FormFields';
import React from 'react';
import * as Yup from 'yup';
import { ConfirmationPrompt } from '../ConfirmationPrompt';
import { useState, useEffect } from 'react';

export const Property = ({ data, previousData, handleSubmit, handleBack }) => {
  const initialValues = {
    properties: data.properties || [],
    comment_questionnaire_property: data.comment_questionnaire_property || previousData.comment_questionnaire_property || '',
  };

  const validationSchema = Yup.object().shape({
    properties: Yup.array(
      Yup.object({
        type: Yup.string().required('Required'),
        price_purchased: Yup.string().required('Required'),
        price_sold: Yup.string().required('Required'),
        date_sold: Yup.string().required('Required'),
        date_purchased: Yup.string().required('Required'),
        years_owned: Yup.string().when('type', {
          is: 'Primary Residence',
          then: Yup.string().required('Required'),
        }),
      })
    )
  })

  const PROPERTY_TYPE_OPTIONS = [
    { label: 'Primary Residence', value: 'Primary Residence' },
    { label: 'Investment', value: 'Investment' },
    { label: 'Rental Property', value: 'Rental Property' },
  ];

  const [isConfirmation, setIsConfirmation] = useState(true);

  useEffect(() => {
    setIsConfirmation(!data.properties && !!previousData.properties)
  }, [data, previousData]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnMount
      validateOnChange
      enableReinitialize>
      {({ setFieldValue, values, submitForm }) => (
        <Form>
          {!isConfirmation && (
            <>
              <Divider orientation="left" orientationMargin="0" style={{ color: '#6A7581' }}>
                Sold Property
              </Divider>

              <FieldArray name="properties">
                {({ insert, remove, push }) => (
                  <div>
                    {values.properties.length > 0 &&
                      values.properties.map((friend, index) => (
                        <Row key={index}>
                          <Col span={12} style={{ padding: 6 }}>
                            <SelectField
                              required
                              value={values.properties[index].type}
                              name={`properties.${index}.type`}
                              onChange={(value) => { setFieldValue(`properties.${index}.type`, value) }}
                              options={PROPERTY_TYPE_OPTIONS}
                              label="Property Type"
                            />
                          </Col>
                          <Col span={12} style={{ padding: 6 }}>
                            {values.properties[index].type === "Primary Residence" && (
                              <TextInput
                                required
                                name={`properties.${index}.years_owned`}
                                label="How many years did you live at this residence"
                              />
                            )}
                          </Col>

                          <Col span={12} style={{ padding: 6 }}>
                            <DateInput
                              required
                              name={`properties.${index}.date_purchased`}
                              value={values.properties[index].date_purchased}
                              onChange={(value, valueStr) => { setFieldValue(`properties.${index}.date_purchased`, valueStr) }}
                              label="Date Purchased"
                            />
                          </Col>

                          <Col span={12} style={{ padding: 6 }}>
                            <TextInput
                              required
                              type="number"
                              prefix="$"
                              name={`properties.${index}.price_purchased`}
                              label="Purchase Price"
                            />
                          </Col>

                          <Col span={12} style={{ padding: 6 }}>
                            <DateInput
                              required
                              name={`properties.${index}.date_sold`}
                              value={values.properties[index].date_sold}
                              onChange={(value, valueStr) => { setFieldValue(`properties.${index}.date_sold`, valueStr) }}
                              label="Date Sold"
                            />
                          </Col>


                          <Col span={12} style={{ padding: 6 }}>
                            <TextInput
                              required
                              type="number"
                              prefix="$"
                              name={`properties.${index}.price_sold`}
                              label="Sale Price"
                            />
                          </Col>


                          <Button danger htmlType="button" style={{ width: '100%' }}
                            onClick={() => remove(index)} >
                            Remove Property
                          </Button>
                          <Divider plain></Divider>
                        </Row>
                      ))}
                    <Button type="secondary" htmlType="button" style={{ width: '100%' }}
                      onClick={() => push({ first_name: '', last_name: '', ssn: '', date_of_birth: '', relationship: '' })} >
                      Add Property
                    </Button>
                    <Divider plain></Divider>
                    <div>
                      <Button type="secondary" htmlType="button" onClick={handleBack}>
                        Back
                      </Button>
                      <Button type="primary" htmlType="submit" style={{ float: 'right' }}>
                        Continue
                      </Button>
                    </div>
                    <Row>
                      <Col span={24}>
                        <TextAreaInput
                          name="comment_questionnaire_property"
                          label="Comment (optional)"
                          placeholder="Enter your comment here"
                        />
                      </Col>
                    </Row>
                  </div>
                )}
              </FieldArray>
            </>
          )}
          {isConfirmation && (
            <ConfirmationPrompt
              submitForm={submitForm}
              setIsConfirmation={setIsConfirmation}
            >
              <Divider orientation="left" orientationMargin="0" style={{ color: '#6A7581' }}>
                Sold Property
              </Divider>

              {previousData.properties?.map((property, index) => {
                return (
                  <div key={index} style={{ marginBottom: 10 }}>
                    <p>Type: {property.type}</p>
                    <p>Date Sold: {property.date_sold}</p>
                    <p>Purchase Price: {property.price_purchased}</p>
                    <p>Sale Price: {property.price_sold}</p>
                    {property.type === "Primary Residence" && (
                      <p>Years Lived at this Residence: {property.years_owned}</p>
                    )}
                  </div>
                )
              })}
            </ConfirmationPrompt>
          )}
        </Form>
      )}
    </Formik>
  )
};
