import { useActions } from 'hooks/useAction';
import { useSelector } from 'react-redux';
import { useEffect, useContext } from 'react';
import { Col, Row } from 'antd';

import { UserDetailContext } from '../../../contexts';
import { useApi } from 'api';

import Header from './Header';
import { Card } from './Card';

import { TaxpayerInfo } from './Questions/TaxpayerInfo';
import { FilingStatus } from './Questions/FilingStatus';
import { SpouseInfo } from './Questions/SpouseInfo';
import { Dependents } from './Questions/Dependents';
import { Finish } from './Questions/Finish';
import { Address } from './Questions/Address';
import { Property } from './Questions/Property';
import { LifeEvents } from './Questions/LifeEvents';
import { FinancialEvents } from './Questions/FinancialEvents';
import { HealthInsurance } from './Questions/HealthInsurance';
import { OtherEvents } from './Questions/OtherEvents';
import { IncomeSources } from './Questions/IncomeSources';
import { IncomeDetails } from './Questions/IncomeDetails';
import { ExpensesSummary } from './Questions/ExpensesSummary';
import { ExpensesDetails } from './Questions/ExpensesDetails';
import { ExpensesDeductions } from './Questions/ExpensesDeductions';
import { SoleProprietorshipGeneral } from './Questions/SoleProprietorshipGeneral';
import { SoleProprietorshipSpecific } from './Questions/SoleProprietorshipSpecific';
import { SoleProprietorshipIncome } from './Questions/SoleProprietorshipIncome';
import { SoleProprietorshipExpenses } from './Questions/SoleProprietorshipExpenses.jsx';
import { SoleProprietorshipAssets } from './Questions/SoleProprietorshipAssets';
import { SoleProprietorshipVehicles } from './Questions/SoleProprietorshipVehicles';
import { SoleProprietorshipHome } from './Questions/SoleProprietorshipHome';

import _ from 'lodash';

const QUESTIONS = {
  "personal/taxpayer_info": {
    key: "personal/taxpayer_info",
    next: (data) => 'personal/address',
    previous: (data) => 'personal/taxpayer_info',
    show: (data) => true,
    title: 'Personal',
    subtitle: 'Taxpayer Info',
  },
  "personal/address": {
    key: "personal/address",
    next: (data) => 'personal/filing_status',
    previous: (data) => 'personal/taxpayer_info',
    show: (data) => true,
    title: 'Personal',
    subtitle: 'Address',
  },
  "personal/filing_status": {
    key: "personal/filing_status",
    next: (data) => ['Married Filing Jointly', 'Qualifying Widow(er)', 'Married Filing Separately'].includes(data.filing_status) ? 'personal/spouse_info' : 'personal/dependents',
    previous: (data) => 'personal/address',
    show: (data) => true,
    title: 'Personal',
    subtitle: 'Filing Status',
  },
  "personal/spouse_info": {
    key: "personal/spouse_info",
    next: (data) => 'personal/dependents',
    previous: (data) => 'personal/filing_status',
    show: (data) => ['Married Filing Jointly', 'Qualifying Widow(er)', 'Married Filing Separately'].includes(data.filing_status),
    title: 'Personal',
    subtitle: 'Spouse Info',
  },
  "personal/dependents": {
    key: "personal/dependents",
    next: (data) => 'personal/health_insurance',
    previous: (data) => 'personal/filing_status',
    show: (data) => true,
    title: 'Personal',
    subtitle: 'Dependents',
  },
  "personal/health_insurance": {
    key: "personal/health_insurance",
    next: (data) => 'questionnaire/life_events',
    previous: (data) => 'personal/dependents',
    show: (data) => true,
    title: 'Personal',
    subtitle: 'Health Insurance',
  },
  "questionnaire/life_events": {
    key: "questionnaire/life_events",
    next: (data) => 'questionnaire/financial_events',
    previous: (data) => 'personal/health_insurance',
    show: (data) => true,
    title: 'Questionnaire',
    subtitle: 'Life Events',
  },
  "questionnaire/financial_events": {
    key: "questionnaire/financial_events",
    next: (data) => {
      if (data.is_property_sold === 'Yes') {
        return 'questionnaire/property';
      } else if (data.additional_questions ? data.additional_questions.length > 0: false) {
        return 'questionnaire/other';
      } else {
        return 'income/sources';
      }
    },
    previous: (data) => 'questionnaire/life_events',
    show: (data) => true,
    title: 'Questionnaire',
    subtitle: 'Financial Events',
  },
  "questionnaire/property": {
    key: "questionnaire/property",
    next: (data) => {
      if (data.additional_questions)  {
        return data.additional_questions.length > 0 ? 'questionnaire/other' : 'income/sources'
      } else {
        return 'income/sources'
      }
    },
    previous: (data) => 'questionnaire/financial_events',
    show: (data) => data.is_property_sold === 'Yes',
    title: 'Questionnaire',
    subtitle: 'Property',
  },
  "questionnaire/other": {
    key: "questionnaire/other",
    next: (data) => 'income/sources',
    previous: (data) => 'questionnaire/financial_events',
    show: (data) => {      
      return data.additional_questions ? data.additional_questions.length > 0: false},
    title: 'Questionnaire',
    subtitle: 'Other Events',
  },
  "income/sources": {
    key: "income/sources",
    next: (data) => {
      if (data.income_is_alimony) {
        return 'income/details';
      } else if (data.income_is_self_employment || data.income_is_rents) {
        return 'sole_proprietorship/general';
      } else {
        return 'expenses/summary';
      }
    },
    previous: (data) => {
      if (data.additional_questions) {  
        return data.additional_questions.length > 0 ? 'questionnaire/other' : 'questionnaire/financial_events'
      }},
    show: (data) => true,
    title: 'Income',
    subtitle: 'Income Sources',
  },
  "income/details": {
    key: "income/details",
    next: (data) => data.income_is_self_employment ? 'sole_proprietorship/general' : 'expenses/summary',
    previous: (data) => 'income/sources',
    show: (data) => data.income_is_alimony,
    title: 'Income',
    subtitle: 'Income Details',
  },
  "sole_proprietorship/general": {
    key: "sole_proprietorship/general",
    next: (data) => 'sole_proprietorship/specific',
    previous: (data) => 'income/sources',
    show: (data) => data.income_is_self_employment,
    title: 'Sole Proprietorship',
    subtitle: 'General Information',
  },
  "sole_proprietorship/specific": {
    key: "sole_proprietorship/specific",
    next: (data) => 'sole_proprietorship/income',
    previous: (data) => 'sole_proprietorship/general',
    show: (data) => data.income_is_self_employment,
    title: 'Sole Proprietorship',
    subtitle: 'Specific Information',
  },
  "sole_proprietorship/income": {
    key: "sole_proprietorship/income",
    next: (data) => data.sole_proprietorship_profit_and_loss === 'Manually' ? 'sole_proprietorship/expenses' : 'expenses/summary',
    previous: (data) => 'sole_proprietorship/specific',
    show: (data) => data.income_is_self_employment,
    title: 'Sole Proprietorship',
    subtitle: 'Income',
  },
  "sole_proprietorship/expenses": {
    key: "sole_proprietorship/expenses",
    next: (data) => 'sole_proprietorship/assets',
    previous: (data) => 'sole_proprietorship/income',
    show: (data) => data.sole_proprietorship_profit_and_loss === 'Manually',
    title: 'Sole Proprietorship',
    subtitle: 'Expenses',
  },
  "sole_proprietorship/assets": {
    key: "sole_proprietorship/assets",
    next: (data) => 'sole_proprietorship/vehicles',
    previous: (data) => 'sole_proprietorship/expenses',
    show: (data) => data.sole_proprietorship_profit_and_loss === 'Manually',
    title: 'Sole Proprietorship',
    subtitle: 'Assets',
  },
  "sole_proprietorship/vehicles": {
    key: "sole_proprietorship/vehicles",
    next: (data) => 'sole_proprietorship/home',
    previous: (data) => 'sole_proprietorship/assets',
    show: (data) => data.sole_proprietorship_profit_and_loss === 'Manually',
    title: 'Sole Proprietorship',
    subtitle: 'Vehicles',
  },
  "sole_proprietorship/home": {
    key: "sole_proprietorship/home",
    next: (data) => 'expenses/summary',
    previous: (data) => 'sole_proprietorship/vehicles',
    show: (data) => data.sole_proprietorship_profit_and_loss === 'Manually',
    title: 'Sole Proprietorship',
    subtitle: 'Use of Home',
  },
  "expenses/summary": {
    key: "expenses/summary",
    next: (data) => data.is_expenses_payed === "Yes" ? 'expenses/detail' : 'expenses/deductions',
    previous: (data) => 'income/sources',
    show: (data) => true,
    title: 'Expenses',
    subtitle: 'Summary',
  },
  "expenses/detail": {
    key: "expenses/detail",
    next: (data) => 'expenses/deductions',
    previous: (data) => 'expenses/summary',
    show: (data) => data.is_expenses_payed === "Yes",
    title: 'Expenses',
    subtitle: 'Detail',
  },
  "expenses/deductions": {
    key: "expenses/deductions",
    next: (data) =>  data.income_is_self_employment ? 'sole_proprietorship/general' : 'finish',
    previous: (data) => 'expenses/summary',
    show: (data) => true,
    title: 'Expenses',
    subtitle: 'Deductions',
  },
  "finish": {
    key: "finish",
    next: (data) => 'personal/taxpayer_info',
    previous: (data) => 'expenses/deductions',
    show: (data) => true,
    title: 'Congratulations!',
    subtitle: 'We have received your answers and will be in touch soon',
  },
}

export function TaxOrganizer({ documentId }) {
  const { fetchTaxOrganizerData, fetchTaxOrganizerDataPast, saveTaxOrganizerData, setQuestions, setCurrentQuestion } = useActions();
  const { userDetailInfo } = useContext(UserDetailContext);
  const { workflowData, pastWorkflowData } = useSelector((state) => state.documents);
  const [api] = useApi(true);
  const FIRST_QUESTION_KEY = "personal/taxpayer_info";

  const questions = useSelector((state) => state.taxOrganizer.questions);
  const currentQuestion = useSelector((state) => state.taxOrganizer.currentQuestion);

  useEffect(() => {
    setQuestions(QUESTIONS);
    setCurrentQuestion(FIRST_QUESTION_KEY);
  }, []);

  useEffect(() => {
    fetchTaxOrganizerData(api, userDetailInfo.case_id, documentId);
  }, [documentId]);

  useEffect(() => {
    if (workflowData) {
      if (workflowData.currentQuestion && QUESTIONS[workflowData.currentQuestion]) {
        setCurrentQuestion(workflowData.currentQuestion);
      }

      if (!currentQuestion && !workflowData.currentQuestion) {
        setCurrentQuestion(FIRST_QUESTION_KEY);
      }

      if (workflowData.currentQuestion && !QUESTIONS[workflowData.currentQuestion]) {
        setCurrentQuestion(FIRST_QUESTION_KEY);
      }

      if (workflowData.period && _.isEmpty(pastWorkflowData)) {
        fetchTaxOrganizerDataPast(api, userDetailInfo.case_id, workflowData.period);
      }

      const shownQuestions = _.pickBy(QUESTIONS, (question) => question.show(workflowData));

      const doneQuestions = _.mapValues(shownQuestions, (question) => {
        return { ...question, isDone: workflowData[question.key] === true };
      });

      setQuestions(doneQuestions);
    }
  }, [workflowData]);


  const saveQuestion = (data) => {
    data = data || {};
    const submittedQuestion = QUESTIONS[currentQuestion];
    const nextQuestion = submittedQuestion.next({ ...workflowData, ...data });
    const saveData = { ...workflowData, ...data, currentQuestion: nextQuestion };
    saveData[submittedQuestion.key] = true;

    saveTaxOrganizerData(api, userDetailInfo.case_id, documentId, saveData);
    setCurrentQuestion(nextQuestion);
  }

  const saveLast = (data) => {
    data = data || {};

    const submittedQuestion = QUESTIONS[currentQuestion];
    const nextQuestion = submittedQuestion.next(data);

    const saveData = { ...workflowData, ...data, currentQuestion: nextQuestion };
    saveData[submittedQuestion.key] = true;

    saveTaxOrganizerData(api, userDetailInfo.case_id, documentId, saveData, "uploaded");
    setCurrentQuestion(nextQuestion);
  }

  const goBack = () => {
    const previousQuestion = QUESTIONS[currentQuestion].previous(workflowData);
    workflowData.currentQuestion = previousQuestion;
    saveTaxOrganizerData(api, userDetailInfo.case_id, documentId, workflowData);
    setCurrentQuestion(previousQuestion);
  }

  return (
    <div className="pb-95">
      {questions && (
        <>
          <Header className="mb-0" style={{ textAlign: 'center' }}>
            <h1 className="mb-0">
              Tax Organizer {workflowData?.period}
            </h1>
          </Header>
        </>
      )}

      <div className="container" >
        <Row justify="center">
          <Col xs={24} sm={24} md={24} lg={16} xl={12}>
            <Card>
              {workflowData && currentQuestion && (
                <>
                  {currentQuestion === 'personal/taxpayer_info' && <TaxpayerInfo
                    data={workflowData}
                    previousData={pastWorkflowData}
                    handleSubmit={saveQuestion}
                    handleBack={goBack} />}
                  {currentQuestion === 'personal/filing_status' && <FilingStatus
                    data={workflowData}
                    previousData={pastWorkflowData}
                    handleSubmit={saveQuestion}
                    handleBack={goBack} />}
                  {currentQuestion === 'personal/spouse_info' && <SpouseInfo
                    data={workflowData}
                    previousData={pastWorkflowData}
                    handleSubmit={saveQuestion}
                    handleBack={goBack} />}
                  {currentQuestion === 'personal/dependents' && <Dependents
                    data={workflowData}
                    previousData={pastWorkflowData}
                    handleSubmit={saveQuestion}
                    handleBack={goBack} />}
                  {currentQuestion === 'personal/address' && <Address
                    data={workflowData}
                    previousData={pastWorkflowData}
                    handleSubmit={saveQuestion}
                    handleBack={goBack} />}
                  {currentQuestion === 'personal/health_insurance' && <HealthInsurance
                    data={workflowData}
                    previousData={pastWorkflowData}
                    handleSubmit={saveQuestion}
                    handleBack={goBack} />}
                  {currentQuestion === 'questionnaire/life_events' && <LifeEvents
                    data={workflowData}
                    previousData={pastWorkflowData}
                    handleSubmit={saveQuestion}
                    handleBack={goBack} />}
                  {currentQuestion === 'questionnaire/financial_events' && <FinancialEvents
                    data={workflowData}
                    previousData={pastWorkflowData}
                    handleSubmit={saveQuestion}
                    handleBack={goBack} />}
                  {currentQuestion === 'questionnaire/property' && <Property
                    data={workflowData}
                    previousData={pastWorkflowData}
                    handleSubmit={saveQuestion}
                    handleBack={goBack} />}
                  {currentQuestion === 'questionnaire/other' && <OtherEvents
                    data={workflowData}
                    previousData={pastWorkflowData}
                    handleSubmit={saveQuestion}
                    handleBack={goBack} />}
                  {currentQuestion === 'income/sources' && <IncomeSources
                    data={workflowData}
                    previousData={pastWorkflowData}
                    handleSubmit={saveQuestion}
                    handleBack={goBack} />}
                  {currentQuestion === 'income/details' && <IncomeDetails
                    data={workflowData}
                    previousData={pastWorkflowData}
                    handleSubmit={saveQuestion}
                    handleBack={goBack} />}
                  {currentQuestion === 'expenses/summary' && <ExpensesSummary
                    data={workflowData}
                    previousData={pastWorkflowData}
                    handleSubmit={saveQuestion}
                    handleBack={goBack} />}
                  {currentQuestion === 'expenses/detail' && <ExpensesDetails
                    data={workflowData}
                    previousData={pastWorkflowData}
                    handleSubmit={saveQuestion}
                    handleBack={goBack} />}
                  {currentQuestion === 'expenses/deductions' && <ExpensesDeductions
                    data={workflowData}
                    previousData={pastWorkflowData}
                    handleSubmit={saveLast}
                    handleBack={goBack} />}
                  {currentQuestion === 'sole_proprietorship/general' && <SoleProprietorshipGeneral
                    data={workflowData}
                    previousData={pastWorkflowData}
                    handleSubmit={saveQuestion}
                    handleBack={goBack} />}
                  {currentQuestion === 'sole_proprietorship/specific' && <SoleProprietorshipSpecific
                    data={workflowData}
                    previousData={pastWorkflowData}
                    handleSubmit={saveQuestion}
                    handleBack={goBack} />}
                  {currentQuestion === 'sole_proprietorship/income' && <SoleProprietorshipIncome
                    data={workflowData}
                    previousData={pastWorkflowData}
                    handleSubmit={saveQuestion}
                    handleBack={goBack} />}
                  {currentQuestion === 'sole_proprietorship/expenses' && <SoleProprietorshipExpenses
                    data={workflowData}
                    previousData={pastWorkflowData}
                    handleSubmit={saveQuestion}
                    handleBack={goBack} />}
                  {currentQuestion === 'sole_proprietorship/assets' && <SoleProprietorshipAssets
                    data={workflowData}
                    previousData={pastWorkflowData}
                    handleSubmit={saveQuestion}
                    handleBack={goBack} />}
                  {currentQuestion === 'sole_proprietorship/vehicles' && <SoleProprietorshipVehicles
                    data={workflowData}
                    previousData={pastWorkflowData}
                    handleSubmit={saveQuestion}
                    handleBack={goBack} />}
                  {currentQuestion === 'sole_proprietorship/home' && <SoleProprietorshipHome
                    data={workflowData}
                    previousData={pastWorkflowData}
                    handleSubmit={saveQuestion}
                    handleBack={goBack} />}
                  {currentQuestion === 'finish' && <Finish
                    data={workflowData}
                    previousData={pastWorkflowData}
                    handleSubmit={saveQuestion}
                    handleBack={goBack} />}
                </>
              )}
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  )
}
