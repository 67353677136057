import { CloseCircleFilled, CheckCircleFilled } from '@ant-design/icons';
import styled from 'styled-components';

const error = '#DA1E27';
const success = '#05BA6E';

function TooltipTitle({ errors }) {
    const isErrorIcon = (errorName) => {
        if (errors[errorName]) {
            return <CloseCircleFilled style={{ color: error, marginRight: '6px' }} />
        }

        return <CheckCircleFilled style={{ color: success, marginRight: '6px' }} />
    }
    return (
        <List>
            <ListItem>{isErrorIcon('isLengthError')}At least 8 characters.</ListItem>
            <ListItem>{isErrorIcon('isLetterCasesError')}A mixture of both uppercase and lowercase letters</ListItem>
            <ListItem>{isErrorIcon('isCharactersTypeError')}A mixture of letters and numbers.</ListItem>
            <ListItem>{isErrorIcon('isSpecialSymbolsError')}Inclusion of at least one special character, e.g. , ! @ # ?
                ] <br />Note: do not use {'< or >'} in your password!</ListItem>
        </List>
    );
}

const List = styled.ul`
    padding: 12px;
`
const ListItem = styled.li`
    list-style: none;
    padding: 0;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: 0.01em;

    :not(:last-child) {
      margin-bottom: 4px;
    }
`

export default TooltipTitle;

