import { Tooltip as AntTooltip} from 'antd';

function Tooltip({ children, ...props }) {
    return (
            <AntTooltip {...props}>
                {children}
            </AntTooltip>
    );
}

export default Tooltip;
