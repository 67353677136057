import { Button, Divider, Col, Row } from 'antd';
import { RadioGroup, TextInput, SelectField, TextAreaInput } from 'components/FormFields';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { BOOLEAN_OPTIONS } from 'const';
import { ConfirmationPrompt } from '../ConfirmationPrompt';
import { useState, useEffect } from 'react';


export function SoleProprietorshipSpecific({ data, previousData, handleSubmit, handleBack }) {
  const initialValues = {
    sole_proprietorship_is_payment_to_not_employee: data.sole_proprietorship_is_payment_to_not_employee || previousData.sole_proprietorship_is_payment_to_not_employee || '',
    sole_proprietorship_is_payment_to_not_employee_file_1099: data.sole_proprietorship_is_payment_to_not_employee_file_1099 || previousData.sole_proprietorship_is_payment_to_not_employee_file_1099 || '',
    sole_proprietorship_is_pay_own_healthcare: data.sole_proprietorship_is_pay_own_healthcare || previousData.sole_proprietorship_is_pay_own_healthcare || '',
    sole_proprietorship_is_pay_own_healthcare_amount: data.sole_proprietorship_is_pay_own_healthcare_amount || previousData.sole_proprietorship_is_pay_own_healthcare_amount || '',
    sole_proprietorship_is_contributions_to_retirement: data.sole_proprietorship_is_contributions_to_retirement || previousData.sole_proprietorship_is_contributions_to_retirement || '',
    sole_proprietorship_is_contributions_to_retirement_plan: data.sole_proprietorship_is_contributions_to_retirement_plan || previousData.sole_proprietorship_is_contributions_to_retirement_plan || '',
    sole_proprietorship_is_contributions_to_retirement_amount: data.sole_proprietorship_is_contributions_to_retirement_amount || previousData.sole_proprietorship_is_contributions_to_retirement_amount || '',
    comment_sole_proprietorship_specific: data.comment_sole_proprietorship_specific || previousData.comment_sole_proprietorship_specific || '',
  }

  const validationSchema = Yup.object().shape({
    sole_proprietorship_is_payment_to_not_employee: Yup.string().required('Required'),
    sole_proprietorship_is_pay_own_healthcare: Yup.string().required('Required'),
    sole_proprietorship_is_contributions_to_retirement: Yup.string().required('Required'),
  });

  const [isConfirmation, setIsConfirmation] = useState(true);

  useEffect(() => {
    setIsConfirmation(!data.sole_proprietorship_is_payment_to_not_employee && !!previousData.sole_proprietorship_is_payment_to_not_employee)
  }, [data, previousData]);

  const RETIREMENT_PLANS_OPTIONS = [
    { label: 'SEP', value: 'SEP' },
    { label: 'SIMPLE', value: 'SIMPLE' },
    { label: '401K', value: '401K' },
    { label: 'Keogh', value: 'Keogh' },
    { label: 'Other', value: 'Other' },
  ]

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnMount
      validateOnChange
      enableReinitialize>
      {({ setFieldValue, values, submitForm }) => (
        <Form>
          {!isConfirmation && (
            <>
              <Divider orientation="left" orientationMargin="0" style={{ color: '#6A7581' }}>
                Payments To Contractors
              </Divider>

              <RadioGroup
                required
                options={BOOLEAN_OPTIONS}
                label={"Did you make any payments to contractors or other non-employees?"}
                name="sole_proprietorship_is_payment_to_not_employee"
              />



              {values.sole_proprietorship_is_payment_to_not_employee === 'Yes' && (
                <>
                  <RadioGroup
                    required
                    options={BOOLEAN_OPTIONS}
                    label={"Did you file Form 1099 for these undividuals?"}
                    name="sole_proprietorship_is_payment_to_not_employee_file_1099"
                  />
                </>
              )}

              <Divider orientation="left" orientationMargin="0" style={{ color: '#6A7581' }}>
                Health Insurance
              </Divider>

              <RadioGroup
                required
                options={BOOLEAN_OPTIONS}
                label={"Did you pay for your own health or dental insurance premiums?"}
                name="sole_proprietorship_is_pay_own_healthcare"
              />

              {values.sole_proprietorship_is_pay_own_healthcare === 'Yes' && (
                <TextInput
                  required
                  type="number"
                  prefix="$"
                  label={"Amount paid"}
                  name="sole_proprietorship_is_pay_own_healthcare_amount"
                />
              )}

              <Divider orientation="left" orientationMargin="0" style={{ color: '#6A7581' }}>
                Retirement Contributions
              </Divider>

              <RadioGroup
                required
                options={BOOLEAN_OPTIONS}
                label={" Did you or do you plan to make contributions to a self-employed retirement plan?"}
                name="sole_proprietorship_is_contributions_to_retirement"
              />

              {values.sole_proprietorship_is_contributions_to_retirement === 'Yes' && (
                <>
                  <SelectField
                    required
                    options={RETIREMENT_PLANS_OPTIONS}
                    onChange={(value) => { setFieldValue(`sole_proprietorship_is_contributions_to_retirement_plan`, value) }}
                    label={"Type of plan"}
                    name="sole_proprietorship_is_contributions_to_retirement_plan"
                  />
                  <TextInput
                    required
                    type="number"
                    prefix="$"
                    label={"Amount contributed"}
                    name="sole_proprietorship_is_contributions_to_retirement_amount"
                  />
                </>
              )}
              <div>
                <Button type="secondary" htmlType="button" onClick={handleBack}>
                  Back
                </Button>
                <Button type="primary" htmlType="submit" style={{ float: 'right' }}>
                  Continue
                </Button>
              </div>
              <Row>
                <Col span={24}>
                  <TextAreaInput
                    name="comment_sole_proprietorship_specific"
                    label="Comment (optional)"
                    placeholder="Enter your comment here"
                  />
                </Col>
              </Row>
            </>
          )}
          {isConfirmation && (
            <ConfirmationPrompt
              submitForm={submitForm}
              setIsConfirmation={setIsConfirmation}
            >
              <p>
                Did you make any payments to contractors or other non-employees? <b>{previousData.sole_proprietorship_is_payment_to_not_employee}</b>
              </p>
              {previousData.sole_proprietorship_is_payment_to_not_employee === 'Yes' && (
                <p>
                  Did you file Form 1099 for these undividuals? <b>{previousData.sole_proprietorship_is_payment_to_not_employee_file_1099}</b>
                </p>
              )}
              <p>
                Did you pay for your own health or dental insurance premiums? <b>{previousData.sole_proprietorship_is_pay_own_healthcare}</b>
              </p>
              {previousData.sole_proprietorship_is_pay_own_healthcare === 'Yes' && (
                <p>
                  Amount paid: <b>{previousData.sole_proprietorship_is_pay_own_healthcare_amount}</b>
                </p>
              )}
              <p>
                Did you or do you plan to make contributions to a self-employed retirement plan? <b>{previousData.sole_proprietorship_is_contributions_to_retirement}</b>
              </p>
              {previousData.sole_proprietorship_is_contributions_to_retirement === 'Yes' && (
                <>
                  <p>
                    Type of plan: <b>{previousData.sole_proprietorship_is_contributions_to_retirement_plan}</b>
                  </p>
                  <p>
                    Amount contributed: <b>{previousData.sole_proprietorship_is_contributions_to_retirement_amount}</b>
                  </p>
                </>
              )}
            </ConfirmationPrompt>
          )}
        </Form>
      )}
    </Formik>
  )
}
