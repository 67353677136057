import _ from 'lodash';
import { CheckOutlined, ClockCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';

export function ProgressBar({ questions, setCurrentQuestion, currentQuestion }) {
    // remove key finish from questions dictionary
    questions = _.omit(questions, ['finish']);
    let groupedQuestions = _.groupBy(questions, 'title');

    groupedQuestions = _.mapValues(groupedQuestions, (group) => {
        return { questions: group, isDone: group.every((question) => question.isDone) };
    });

    const goToLastSubPage = (group) => {
        const lastQuestion = _.last(group.questions);
        if (lastQuestion) {
            setCurrentQuestion(lastQuestion.key);
        }
    }

    return (
        <div className="container">
            <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
                {groupedQuestions && Object.keys(groupedQuestions).map((key, index) => {
                    const isCurrentGroup = groupedQuestions[key].questions.some(question => question.key === currentQuestion);
                    return (
                        <div key={index} style={{ marginBottom: '20px' }}>
                            <li style={{ fontSize: 18, marginBottom: '10px', cursor: 'pointer' }}
                                onClick={() => goToLastSubPage(groupedQuestions[key])}>
                                {groupedQuestions[key].isDone ? <CheckOutlined style={{ marginRight: 8, color: 'gray' }} /> :
                                    isCurrentGroup ? <ClockCircleOutlined style={{ marginRight: 8, color: 'gray' }} /> :
                                        <MinusCircleOutlined style={{ marginRight: 8, color: 'gray' }} />}
                                {key}
                            </li>
                            {isCurrentGroup && <ul style={{ listStyleType: 'none', borderLeft: '2px solid lightgray', marginLeft: 7, paddingLeft: '5px' }}>
                                {groupedQuestions[key].questions.map((question, index2) => {
                                    return (
                                        <div key={index2}>
                                            <li style={{
                                                fontSize: '14px',
                                                fontWeight: question.key === currentQuestion ? 'bold' : 'normal',
                                                cursor: 'pointer',
                                                paddingLeft: 14,
                                                marginBottom: '5px',
                                                color: question.key === currentQuestion ? 'blue' : 'black',
                                                transition: 'color 0.3s ease'
                                            }}
                                                onClick={() => setCurrentQuestion(question.key)}
                                                title="Click to view this section">
                                                {question.subtitle}
                                            </li>
                                        </div>
                                    )
                                })}
                            </ul>}
                        </div>
                    )
                })}
            </ul>
        </div>
    )
}
