import { Button, Divider, Row, Col } from 'antd';
import { SelectField, TextAreaInput } from 'components/FormFields';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { ConfirmationPrompt } from '../ConfirmationPrompt';
import { useState, useEffect } from 'react';


export function FilingStatus({ data, previousData, handleSubmit, handleBack }) {
  const initialValues = {
    filing_status: data.filing_status || previousData.filing_status || '',
    comment_personal_filing_status: data.comment_personal_filing_status || previousData.comment_personal_filing_status || '',
  }

  const validationSchema = Yup.object().shape({
    filing_status: Yup.string().required('Required'),
  });

  const OPTIONS = [
    {
      label: 'Single',
      value: 'Single',
    },
    {
      label: 'Married Filing Jointly',
      value: 'Married Filing Jointly',
    },
    {
      label: 'Married Filing Separately',
      value: 'Married Filing Separately',
    },
    {
      label: 'Head of Household',
      value: 'Head of Household',
    },
    {
      label: 'Qualifying Widow(er)',
      value: 'Qualifying Widow(er)',
    },
  ];

  const [isConfirmation, setIsConfirmation] = useState(true);

  useEffect(() => {
    setIsConfirmation(!data.filing_status && !!previousData.filing_status)
  }, [data, previousData]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnMount
      validateOnChange
      enableReinitialize>
      {({ setFieldValue, values, submitForm }) => (
        <Form>
          <Divider orientation="left" orientationMargin="0" style={{ color: '#6A7581' }}>
            Marital Status
          </Divider>

          {!isConfirmation && (
            <>
              <SelectField
                required
                onChange={(value) => setFieldValue("filing_status", value)}
                options={OPTIONS}
                name="filing_status"
                label="Filing Status" />
              <div>
                <Button type="secondary" htmlType="button" onClick={handleBack}>
                  Back
                </Button>
                <Button type="primary" htmlType="submit" style={{ float: 'right' }}>
                  Continue
                </Button>
              </div>
              <Row>
                <Col span={24}>
                  <TextAreaInput
                    name="comment_personal_filing_status"
                    label="Comment (optional)"
                    placeholder="Enter your comment here"
                  />
                </Col>
              </Row>
            </>
          )}
          {isConfirmation && (
            <ConfirmationPrompt
              submitForm={submitForm}
              setIsConfirmation={setIsConfirmation}
            >
              <p>
                Filing Status: <b>{previousData.filing_status}</b>
              </p>

            </ConfirmationPrompt>
          )}
        </Form>
      )}
    </Formik>
  )
}
