import { useState } from 'react';
import { Layout as AntLayout } from 'antd';
import styled from 'styled-components';
import Body from './Body';
import Header from './Header';
import Sidebar from './Sidebar';
import MenuDrawer from './MenuDrawer';

function Layout({ children, publicPage, onLogoClick }) {
    const [showNavigation, toggleNavigation] = useState(false);
    const [sidebarCollapsed, onCollapse] = useState(false);

    if (publicPage) {
        return (
            <LayoutStyled>
                <Header style={{ left: '0' }} onLogoClick={onLogoClick} />
                <Body collapsed>{children}</Body>
            </LayoutStyled>
        );
    }

    return (
        <LayoutStyled>
            <Header
                showTrigger={sidebarCollapsed}
                showMenu={toggleNavigation.bind(null, true)}
                onLogoClick={onLogoClick}
            />
            <MenuDrawer
                menuVisibility={showNavigation}
                hideMenu={toggleNavigation.bind(null, false)}
            />
            <LayoutStyled hasSider>
                <Sidebar onCollapse={onCollapse} />
                <Body collapsed={sidebarCollapsed}>{children}</Body>
            </LayoutStyled>
        </LayoutStyled>
    );
}

const LayoutStyled = styled(AntLayout)`
    // width: 100%;
    // max-width: 600px;
    background-color: ${(props) => props.theme.layoutBg};
`;

export default Layout;
