import { Fragment, useEffect, useState } from 'react';
import { message } from 'antd';
import { Formik } from 'formik';
import Button from 'components/Button';
import styled from 'styled-components';
import { useApi, endpoints } from 'api';
import { PageHeader } from 'components/Header';
import store from 'store';
import Version from 'components/Version';
import { ContentWrap } from 'components/Layout';
import OtpInput from 'react-otp-input';
import { useHistory } from 'react-router-dom';
import paths from 'routes/paths';
import { path } from 'd3';

function OTPConfirm(props) {
    const [otp, setOtp] = useState(null);
    const [isEnabled, setIsEnabled] = useState(false);
    const [coundDownSecond, setCountDownSecond] = useState(0);
    const [coundDownMinute, setCountDownMinute] = useState(0);
    const [seconds, setSeconds] = useState(60);
    const [resend, setResend] = useState(false);
    const case_id = store.get('case_id');
    const target = store.get('target');
    const history = useHistory();
    // eslint-disable-next-line no-unused-vars
    const [initialValues, updateInitialValues] = useState({
        case_id: case_id,
    });
    const [api] = useApi(false);
    const [responseMsg, setResponseMsg] = useState();
    const [inputShow, inputHide] = useState(false);
    const [showMsg, hideMsg] = useState(false);

    const onChangeOtp = (e) => {
        // console.log('radio checked', e.target.value);
        setOtp(e);
    };

    useEffect(() => {
        if (!case_id) {
            inputHide(true);
        }
    }, [case_id]);

    useEffect(() => {
        if (otp && otp.length === 6) {
            setIsEnabled(true);
        }
        else if (!otp || otp.length < 6) {
            setIsEnabled(false);
        }
    });

    useEffect(() => {
        const interval = setInterval(() => {
            if (seconds < 0) {
                setResend(true);
            }
            else {
                setResend(false);
            }
            setCountDownMinute(Math.floor(seconds / 60))
            setCountDownSecond(Math.floor(seconds - (Math.floor(seconds / 60)) * 60))
            setSeconds(seconds - 1)
        }, 1000);

        return () => clearInterval(interval);
    }, [seconds]);

    const sendOtpRequest = async (data) => {
        const postData = { "case_id": case_id, "target": target }
        // eslint-disable-next-line no-undef
        try {
            const req = await api({
                method: 'POST',
                url: endpoints.SEND_OTP,
                data: postData,
            });

            const res = await req.data;
            if (res) {
                setResponseMsg(res.msg);
                hideMsg(true);
                setSeconds(60);
            }
        } catch (error) {
            const { data } = error.response;
            setResponseMsg(data.detail);
            hideMsg(true);
        }
    };

    const sendOTPCheck = async (data) => {
        const postData = { "case_id": case_id, "target": target, "otp": otp };
        // eslint-disable-next-line no-undef
        try {
            const req = await api({
                method: 'POST',
                url: endpoints.OTP_CHECK,
                data: postData,
            });

            const res = await req.data;
            if (res) {
                setResponseMsg(res.msg);
                hideMsg(true);
                message.success('Account activated successfully. Redirecting to Login Page.');
                setTimeout(() => history.push(paths.LOGIN), 5000);
                // history.push("/sign-up/otp-confirm");
            }
        } catch (error) {
            const { data } = error.response;
            message.error(JSON.parse(data.detail).msg);

            setResponseMsg(data.detail);
            hideMsg(true);
        }
    };

    return (
        <Wrapper>
            <section style={{ flex: 1 }}>
                <PageHeader>
                    <Fragment>
                        <h1 className="mb-20">
                            Enter Verification Code
                        </h1>
                        <p>Enter the 6 digit otp code sent to the number you provided. You can resend code after 60 seconds</p>
                    </Fragment>

                </PageHeader>
                <ContentWrap width="490px">
                    <p className="text-center">
                        How do you want to receive OTP?
                    </p>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={sendOTPCheck}
                        // validationSchema={validationRules}
                        enableReinitialize
                        validateOnMount
                    >
                        {({ isValid, handleSubmit, isSubmitting }) => (
                            <form onSubmit={handleSubmit} className="mt-32">
                                <OtpInput
                                    className="text-center"
                                    containerStyle={{
                                        marginBottom: '1.4rem',
                                        justifyContent: 'center',
                                    }}
                                    inputStyle={{
                                        width: "2.5rem",
                                        height: "2.5rem",
                                        margin: "0 .5rem",
                                        fontSize: "rem",
                                        borderRadius: 4,
                                        border: "1px solid rgba(0,0,0,0.3)"
                                    }}
                                    value={otp}
                                    onChange={onChangeOtp}
                                    numInputs={6}
                                    separator={<span>&nbsp;</span>}
                                    shouldAutoFocus={true}
                                    isInputNum={true}
                                />
                                {resend ?
                                    <p className="text-center">OTP is Expired! <a href="#" onClick={sendOtpRequest}>Resend a new OTP</a></p> :
                                    <p className="text-center">Having Trouble? Resend a new OTP in <string>{(coundDownMinute < 10) ? ("0" + coundDownMinute) : coundDownMinute}:{(coundDownSecond < 10) ? ("0" + coundDownSecond) : coundDownSecond}</string></p>
                                }


                                <Button
                                    block
                                    type="primary"
                                    htmlType="submit"
                                    className="mt-16 mb-16"
                                    disabled={!isEnabled}
                                    onClick={handleSubmit}
                                    loading={isSubmitting}
                                >
                                    Verify Code
                                </Button>
                            </form>

                        )}
                    </Formik>
                    <p className="text-center"><a href={paths.SEND_OTP}>Change Delivery Method</a></p>
                </ContentWrap>
            </section>
            <Version />
        </Wrapper>
    );
}

// const validationRules = Yup.object().shape({
//     case_id: Yup.string().required('Please enter case id'),
// });

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 122px);
    form {
        @media (max-width: 767px) {
            padding: 0 32px;
        }
    }
`;

export default OTPConfirm;
