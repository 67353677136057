import { Button, Divider, Row, Col } from 'antd';
import { TextInput, DateInput, TextAreaInput } from 'components/FormFields';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { ConfirmationPrompt } from '../ConfirmationPrompt';
import { useState, useEffect } from 'react';
import _ from 'lodash';

export function ExpensesDetails({ data, previousData, handleSubmit, handleBack }) {
  const initialValues = {
    expenses_adoption_amount: data.expenses_adoption_amount || previousData.expenses_adoption_amount || '',
    expenses_alimony_divorce_date: data.expenses_alimony_divorce_date || previousData.expenses_alimony_divorce_date || '',
    expenses_alimony_amount: data.expenses_alimony_amount || previousData.expenses_alimony_amount || '',
    expenses_alimony_ssn: data.expenses_alimony_ssn || previousData.expenses_alimony_ssn || '',
    expenses_dependent_care_amount: data.expenses_dependent_care_amount || previousData.expenses_dependent_care_amount || '',
    expenses_educator_amount: data.expenses_educator_amount || previousData.expenses_educator_amount || '',
    expenses_higher_education_amount: data.expenses_higher_education_amount || previousData.expenses_higher_education_amount || '',
    expenses_household_worker_amount: data.expenses_household_worker_amount || previousData.expenses_household_worker_amount || '',
    expenses_health_savings_amount: data.expenses_health_savings_amount || previousData.expenses_health_savings_amount || '',
    expenses_retirement_amount: data.expenses_retirement_amount || previousData.expenses_retirement_amount || '',
    expenses_student_loan_amount: data.expenses_student_loan_amount || previousData.expenses_student_loan_amount || '',
    expenses_energy_efficient_amount: data.expenses_energy_efficient_amount || previousData.expenses_energy_efficient_amount || '',
    comment_expenses_details: data.comment_expenses_details || previousData.comment_expenses_details || '',
  }

  const validationObject = {}

  if (data.is_expenses_payed && data.expenses_is_adoption) {
    validationObject.expenses_adoption_amount = Yup.number().required('Required')
  }

  if (data.is_expenses_payed && data.expenses_is_alimony) {
    validationObject.expenses_alimony_divorce_date = Yup.string().required('Required')
    validationObject.expenses_alimony_amount = Yup.number().required('Required')
    validationObject.expenses_alimony_ssn = Yup.string()
      .test('ssn', 'Invalid SSN format. It should be XXX-XX-XXXX', value => {
        if (!value) return true;
        const ssnPattern = /^[0-9]{3}-[0-9]{2}-[0-9]{4}$/;
        return ssnPattern.test(value);
      })
  }

  if (data.is_expenses_payed && data.expenses_is_dependent_care) {
    validationObject.expenses_dependent_care_amount = Yup.number().required('Required')
  }

  if (data.is_expenses_payed && data.expenses_is_educator) {
    validationObject.expenses_educator_amount = Yup.number().required('Required')
  }

  if (data.is_expenses_payed && data.expenses_is_higher_education) {
    validationObject.expenses_higher_education_amount = Yup.number().required('Required')
  }

  if (data.is_expenses_payed && data.expenses_is_household_worker) {
    validationObject.expenses_household_worker_amount = Yup.number().required('Required')
  }
  
  if (data.is_expenses_payed && data.expenses_is_health_savings) {
    validationObject.expenses_health_savings_amount = Yup.number().required('Required')
  }

  if (data.is_expenses_payed && data.expenses_is_retirement) {
    validationObject.expenses_retirement_amount = Yup.number().required('Required')
  }  
  
  if (data.is_expenses_payed && data.expenses_is_student_loan) {
    validationObject.expenses_student_loan_amount = Yup.number().required('Required')
  }

  if (data.is_expenses_payed && data.expenses_is_energy_efficient) {
    validationObject.expenses_energy_efficient_amount = Yup.number().required('Required')
  }

  const validationSchema = Yup.object().shape(validationObject);

  const [isConfirmation, setIsConfirmation] = useState(true);

  useEffect(() => {
    const dataWithInitialKeys = _.pick(data, ['expenses_adoption_amount', 'expenses_alimony_divorce_date', 'expenses_alimony_amount', 'expenses_alimony_ssn', 'expenses_dependent_care_amount', 'expenses_educator_amount', 'expenses_higher_education_amount', 'expenses_household_worker_amount', 'expenses_health_savings_amount', 'expenses_retirement_amount', 'expenses_student_loan', 'expenses_energy_efficient_amount']);

    const previousDataWithInitialKeys = _.pick(previousData, ['expenses_adoption_amount', 'expenses_alimony_divorce_date', 'expenses_alimony_amount', 'expenses_alimony_ssn', 'expenses_dependent_care_amount', 'expenses_educator_amount', 'expenses_higher_education_amount', 'expenses_household_worker_amount', 'expenses_health_savings_amount', 'expenses_retirement_amount', 'expenses_student_loan', 'expenses_energy_efficient_amount']);

    setIsConfirmation(_.keys(dataWithInitialKeys).length === 0 && _.keys(previousDataWithInitialKeys).length > 0)
  }, [data, previousData]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnMount
      validateOnChange
      enableReinitialize>
      {({ setFieldValue, values, submitForm }) => (
        <Form>
          {!isConfirmation && (
            <>
              {data.is_expenses_payed && data.expenses_is_adoption && (
                <>
                  <Divider orientation="left" orientationMargin="0" style={{ color: '#6A7581' }}>
                    Adoption Expenses
                  </Divider>

                  <TextInput
                    required
                    type="number"
                    prefix="$"
                    name="expenses_adoption_amount"
                    label={`Amount paid in ${data.period}`} />
                </>
              )}

              {data.is_expenses_payed && data.expenses_is_alimony && (
                <>
                  <Divider orientation="left" orientationMargin="0" style={{ color: '#6A7581' }}>
                    Alimony Expenses
                  </Divider>

                  <DateInput
                    required
                    onChange={(value, valueStr) => { setFieldValue("expenses_alimony_divorce_date", valueStr) }}
                    value={values.expenses_alimony_divorce_date}
                    name="expenses_alimony_divorce_date"
                    label="Date of original divorce or separation agreement" />

                  <TextInput
                    required
                    type="number"
                    prefix="$"
                    name="expenses_alimony_amount"
                    label={`Amount paid in ${data.period}`} />

                  <TextInput
                    name="expenses_alimony_ssn"
                    onChange={(event) => {
                      const { value } = event.target;
                      const ssn = value.replace(/\D/g, '').slice(0, 9);
                      let formattedSSN = ssn;
                      if (ssn.length > 5) {
                        formattedSSN = `${ssn.slice(0, 3)}-${ssn.slice(3, 5)}-${ssn.slice(5)}`;
                      } else if (ssn.length > 3) {
                        formattedSSN = `${ssn.slice(0, 3)}-${ssn.slice(3)}`;
                      }
                      setFieldValue("expenses_alimony_ssn", formattedSSN);
                    }}

                    label="Recipient's SSN (optional)" />
                </>
              )}

              {data.is_expenses_payed && data.expenses_is_dependent_care && (
                <>
                  <Divider orientation="left" orientationMargin="0" style={{ color: '#6A7581' }}>
                    Child or dependent care expenses
                  </Divider>

                  <TextInput
                    required
                    type="number"
                    prefix="$"
                    name="expenses_dependent_care_amount"
                    label={`Amount paid in ${data.period}`} />
                </>
              )}

              {data.is_expenses_payed && data.expenses_is_educator && (
                <>
                  <Divider orientation="left" orientationMargin="0" style={{ color: '#6A7581' }}>
                    Educator Expenses
                  </Divider>

                  <TextInput
                    required
                    type="number"
                    prefix="$"
                    name="expenses_educator_amount"
                    label={`Amount paid in ${data.period}`} />
                </>
              )}

              {data.is_expenses_payed && data.expenses_is_higher_education && (
                <>
                  <Divider orientation="left" orientationMargin="0" style={{ color: '#6A7581' }}>
                    Higher Education Expenses
                  </Divider>

                  <TextInput
                    required
                    type="number"
                    prefix="$"
                    name="expenses_higher_education_amount"
                    label={`Amount paid in ${data.period}`} />
                </>
              )}

              {data.is_expenses_payed && data.expenses_is_household_worker && (
                <>
                  <Divider orientation="left" orientationMargin="0" style={{ color: '#6A7581' }}>
                    Household Worker Expenses
                  </Divider>

                  <TextInput
                    required
                    type="number"
                    prefix="$"
                    name="expenses_household_worker_amount"
                    label={`Amount paid in ${data.period}`} />
                </>
              )}

              {data.is_expenses_payed && data.expenses_is_health_savings && (
                <>
                  <Divider orientation="left" orientationMargin="0" style={{ color: '#6A7581' }}>
                    Contributions to a health savings account (HSA)
                  </Divider>

                  <TextInput
                    required
                    type="number"
                    prefix="$"
                    name="expenses_health_savings_amount"
                    label={`Amount paid in ${data.period}`} />
                </>
              )}   

              {data.is_expenses_payed && data.expenses_is_retirement && (
                <>
                  <Divider orientation="left" orientationMargin="0" style={{ color: '#6A7581' }}>
                    Contributions to an individual retirement arrangement (IRA)
                  </Divider>

                  <TextInput
                    required
                    type="number"
                    prefix="$"
                    name="expenses_retirement_amount"
                    label={`Amount paid in ${data.period}`} />
                </>
              )}   

              {data.is_expenses_payed && data.expenses_is_student_loan && (
                <>
                  <Divider orientation="left" orientationMargin="0" style={{ color: '#6A7581' }}>
                    Student loan interest
                  </Divider>

                  <TextInput
                    required
                    type="number"
                    prefix="$"
                    name="expenses_student_loan_amount"
                    label={`Amount paid in ${data.period}`} />
                </>
              )}

              {data.is_expenses_payed && data.expenses_is_energy_efficiency && (
                <>
                  <Divider orientation="left" orientationMargin="0" style={{ color: '#6A7581' }}>
                    Energy-efficient home improvements
                  </Divider>

                  <TextInput
                    required
                    type="number"
                    prefix="$"
                    name="expenses_energy_efficient_amount"
                    label={`Amount paid in ${data.period}`} />
                </>
              )}
              <div>
                <Button type="secondary" htmlType="button" onClick={handleBack}>
                  Back
                </Button>
                <Button type="primary" htmlType="submit" style={{ float: 'right' }}>
                  Continue
                </Button>
              </div>
              <Row>
                <Col span={24}>
                  <TextAreaInput
                    name="comment_expenses_details"
                    label="Comment (optional)"
                    placeholder="Enter your comment here"
                  />
                </Col>
              </Row>
            </>
          )}
          {isConfirmation && (
            <ConfirmationPrompt
              submitForm={submitForm}
              setIsConfirmation={setIsConfirmation}
            >
              {data.is_expenses_payed && data.expenses_is_adoption && (
                <p>
                  Adoption Expenses: <b>{previousData.expenses_adoption_amount}</b>
                </p>
              )}

              {data.is_expenses_payed && data.expenses_is_alimony && (
                <>
                  <p>
                    Alimony Expenses: <b>{previousData.expenses_alimony_amount}</b>
                  </p>
                  <p>
                    Date of original divorce or separation agreement: <b>{previousData.expenses_alimony_divorce_date}</b>
                  </p>
                  <p>
                    Recipient&apos;s SSN: <b>{previousData.expenses_alimony_ssn}</b>
                  </p>
                </>
              )}

              {data.is_expenses_payed && data.expenses_is_dependent_care && (
                <p>
                  Child or dependent care expenses: <b>{previousData.expenses_dependent_care_amount}</b>
                </p>
              )}

              {data.is_expenses_payed && data.expenses_is_educator && (
                <p>
                  Educator Expenses: <b>{previousData.expenses_educator_amount}</b>
                </p>
              )}

              {data.is_expenses_payed && data.expenses_is_higher_education && (
                <p>
                  Higher Education Expenses: <b>{previousData.expenses_higher_education_amount}</b>
                </p>
              )}

              {data.is_expenses_payed && data.expenses_is_household_worker && (
                <p>
                  Household Worker Expenses: <b>{previousData.expenses_household_worker_amount}</b>
                </p>
              )}

              {data.is_expenses_payed && data.expenses_is_health_savings && (
                <p>
                  Contributions to a health savings account (HSA): <b>{previousData.expenses_health_savings_amount}</b>
                </p>
              )}
    
              {data.is_expenses_payed && data.expenses_is_retirement && (
                <p>
                    Contributions to an individual retirement arrangement (IRA): <b>{previousData.expenses_retirement_amount}</b>
                </p>
              )}
              
              {data.is_expenses_payed && data.expenses_is_student_loan && (
                <p>
                    Student loan interest: <b>{previousData.expenses_student_loan_amount}</b>
                </p>
              )}

              {data.is_expenses_payed && data.expenses_is_energy_efficiency && (
                <p>
                  Energy-efficient home improvements: <b>{previousData.expenses_energy_efficient_amount}</b>
                </p>
              )}
            </ConfirmationPrompt>
          )}
        </Form>)}
    </Formik>
  )
}
