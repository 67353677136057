import { Fragment, useEffect, useState } from 'react';
import { Formik } from 'formik';
import { TextInput } from 'components/FormFields';
import Button from 'components/Button';
import styled from 'styled-components';
import { useApi, endpoints } from 'api';
import { PageHeader } from 'components/Header';
import store from 'store';
import Version from 'components/Version';
import { ContentWrap } from 'components/Layout';
import Message from 'components/Message';
import * as Yup from 'yup';
import { FormattedText } from 'utilities';

function EmailResend(props) {
    const case_id = store.get('case_id');
    const params = store.get('key');
    const token = store.get('invalid');
    // eslint-disable-next-line no-unused-vars
    const [initialValues, updateInitialValues] = useState({
        case_id: case_id,
    });
    const [api] = useApi(false);
    const [responseMsg, setResponseMsg] = useState();
    const [inputShow, inputHide] = useState(false);
    const [showMsg, hideMsg] = useState(false);

    useEffect(() => {
        if (!case_id) {
            inputHide(true);
        }
    }, [case_id]);

    const resendEmail = async (data) => {
        // eslint-disable-next-line no-undef
        data['redirect_url'] = `${process.env['REACT_APP_EMAIL_REDIRECT_URL']}`;
        // eslint-disable-next-line no-undef
        data['email_app_name'] = `${process.env['REACT_APP_EMAIL_APP_NAME']}`;
        try {
            const req = await api({
                method: 'POST',
                url: endpoints.RESEND_EMAIL,
                data,
            });

            const res = await req.data;
            if (res) {
                setResponseMsg(res.msg);
                hideMsg(true);
            }
        } catch (error) {
            const { data } = error.response;
            setResponseMsg(data.detail);
            hideMsg(true);
        }
    };

    return (
        <Wrapper>
            <section style={{ flex: 1 }}>
                <PageHeader>
                    {params !== 'only_resend' && token !== 'invalid_token' ? (
                        <Fragment>
                            <h1 className="mb-20">
                                {"We've"} sent an activation link to your email
                                address!
                            </h1>

                            <p className="mb-0">
                                Thanks for signing up! Please check your email
                                and click the link we sent to verify your email
                                address and activate your{' '}
                                <FormattedText
                                    id="app.brand"
                                    defaultValue="TaxRise"
                                />{' '}
                                client portal account!
                            </p>
                        </Fragment>
                    ) : (
                        <Fragment>
                            {token === 'invalid_token' ? (
                                <Fragment>
                                    <h1>Your activation link expired</h1>
                                    <p className="mb-20">
                                        {"Didn't "} get an email? Use Resend
                                        Activation Email button below to re-send
                                        your verification email
                                    </p>
                                </Fragment>
                            ) : (
                                <h1 className="mb-20">
                                    {"Didn't "} get an email? Use Resend
                                    Activation Email button below to re-send
                                    your verification email
                                </h1>
                            )}
                        </Fragment>
                    )}
                </PageHeader>
                <ContentWrap width="490px">
                    <Formik
                        initialValues={initialValues}
                        onSubmit={resendEmail}
                        validationSchema={validationRules}
                        enableReinitialize
                        validateOnMount
                    >
                        {({ isValid, handleSubmit, isSubmitting }) => (
                            <form onSubmit={handleSubmit} className="mt-32">
                                {showMsg === true && (
                                    <Message message={responseMsg} />
                                )}
                                {inputShow === true && (
                                    <TextInput name="case_id" label="Case ID" />
                                )}
                                {params !== 'only_resend' &&
                                    token !== 'invalid_token' &&
                                    "Didn't get an email? Use the button below to resend your verification email"}
                                <Button
                                    block
                                    type="primary"
                                    htmlType="submit"
                                    className="mt-16 mb-16"
                                    disabled={!isValid}
                                    onClick={handleSubmit}
                                    loading={isSubmitting}
                                >
                                    Resend Activation Email
                                </Button>
                            </form>
                        )}
                    </Formik>
                </ContentWrap>
            </section>
            <Version />
        </Wrapper>
    );
}

const validationRules = Yup.object().shape({
    case_id: Yup.string().required('Please enter case id'),
});

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 122px);
    form {
        @media (max-width: 767px) {
            padding: 0 32px;
        }
    }
`;

export default EmailResend;
