import { Fragment, useState } from 'react';
import { Formik } from 'formik';
import { PasswordInput } from 'components/FormFields';
import Button from 'components/Button';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import * as Yup from 'yup';
import { endpoints, useApi } from 'api';
import { PageHeader } from 'components/Header';
import Version from 'components/Version';
import { ContentWrap } from 'components/Layout';
import Tooltip, { TooltipTitle } from 'components/Tooltip';
import ResetSuccess from './ResetSuccess';
import {
    CHARACTERS_TYPE_PATTERN,
    FORBIDDEN_SPECIAL_SYMBOLS_PATTERN,
    LETTER_CASES_PATTERN,
    SPECIAL_SYMBOLS_PATTERN
} from 'const';
import 'url-search-params-polyfill';

const initialValues = {
    new_password: '',
    token: '',
    retype_password: '',
};

function PasswordReset(props) {
    const [showSuccess, hideSuccess] = useState(false);
    const [passwordErrors, setPasswordErrors] = useState({
        isLengthError: true,
        isLetterCasesError: true,
        isCharactersTypeError: true,
        isSpecialSymbolsError: true,
    });
    const { search } = useLocation();
    const urlParams = new URLSearchParams(search);
    const token = urlParams.get('token');
    const [api] = useApi(false);

    const passwordSubmit = async (data) => {
        const { new_password } = data;
        try {
            const params = {
                new_password: new_password,
                token: token,
            };
            const req = await api.post(endpoints.PASSWORD_RESET, params);

            const res = await req.data;
            if (res) {
                hideSuccess(true);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handlePassword = (value) => {
        setPasswordErrors({
            isLengthError: value.length < 8,
            isLetterCasesError: !LETTER_CASES_PATTERN.test(value),
            isCharactersTypeError: !CHARACTERS_TYPE_PATTERN.test(value),
            isSpecialSymbolsError: !SPECIAL_SYMBOLS_PATTERN.test(value) || !FORBIDDEN_SPECIAL_SYMBOLS_PATTERN.test(value),
        })
    }

    return (
        <Wrapper>
            <section style={{ flex: 1 }}>
                {showSuccess === false ? (
                    <Fragment>
                        <PageHeader>
                            <h1 className="mb-8">Reset your password.</h1>

                            <p className="mb-0">
                                Create a new password for your client portal
                                account. Make sure your password is secure and
                                memorable!
                            </p>
                        </PageHeader>
                        <ContentWrap width="490px">
                            <Formik
                                initialValues={initialValues}
                                onSubmit={passwordSubmit}
                                validationSchema={validationRules}
                                validateOnMount
                            >
                                {({ isValid, handleSubmit, isSubmitting, handleBlur}) => (
                                    <form onSubmit={handleSubmit}>
                                        <TooltipDesign>
                                            <Tooltip
                                                placement="bottomLeft"
                                                title={<TooltipTitle errors={passwordErrors} />}
                                                trigger={['focus', 'click']}
                                                getPopupContainer={(
                                                    triggerNode
                                                ) => triggerNode}
                                            >
                                                <PasswordInput
                                                    name="new_password"
                                                    label="Password"
                                                    onBlur={handleBlur}
                                                    onChangeField={handlePassword}
                                                />
                                            </Tooltip>
                                        </TooltipDesign>
                                        <PasswordInput
                                            name="retype_password"
                                            label="Re-Type Password"
                                        />

                                        <Button
                                            block
                                            type="primary"
                                            htmlType="submit"
                                            className="mt-16"
                                            disabled={!isValid}
                                            onClick={handleSubmit}
                                            loading={isSubmitting}
                                        >
                                            Submit
                                        </Button>
                                    </form>
                                )}
                            </Formik>
                        </ContentWrap>
                    </Fragment>
                ) : (
                    <ResetSuccess />
                )}
            </section>
            <Version />
        </Wrapper>
    );
}

const validationRules = Yup.object().shape({
    new_password: Yup.string()
    .required('No password provided.')
    .min(8, 'Password is too short - should be 8 chars minimum.')
    .matches(LETTER_CASES_PATTERN, 'A mixture of both uppercase and lowercase letters')
    .matches(CHARACTERS_TYPE_PATTERN, 'A mixture of letters and numbers')
    .matches(SPECIAL_SYMBOLS_PATTERN, 'Inclusion of at least one special character, e.g.,!@#]')
    .matches(FORBIDDEN_SPECIAL_SYMBOLS_PATTERN, 'Do not use < or > in your password!'),
    retype_password: Yup.string()
        .required('No password provided.')
        .min(8, 'Password is too short - should be 8 chars minimum.')
        .oneOf([Yup.ref('new_password'), null], 'Password must match'),
});

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 122px);
`;

const TooltipDesign = styled.div`
    .ant-tooltip-inner {
        background: #393939;
        border-radius: 4px;
        height: auto;
        width: 490px;
        padding: 0;
    }
`;

export default PasswordReset;
