const initialState = {
  questions: null,
  currentQuestion: null,
};

export const taxOrganizerReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_QUESTIONS':
      return {
        ...state,
        questions: action.payload,
      };
    case 'SET_CURRENT_QUESTION':
      return {
        ...state,
        currentQuestion: action.payload,
      };
    default:
      return state;
  }
}