import { RadioGroup, TextAreaInput, SelectField } from 'components/FormFields';

function TaxOrgAddtlQuestion({ id, question, type, options, onChange }) {
    const optionsObjects =[]

    if (type === "select" && options) {
        for (const opt of options) {
            optionsObjects.push({label: opt, value: opt})
        }
    }
    return (
        <>
            {type === "radio" && <RadioGroup
                required
                options={options}
                label={question}
                name={id}
                onChange={(e) => onChange(e.target.value)}
                />}
            {type === "text" && <TextAreaInput
                required
                label={question}
                name={id}
                placeholder="Enter your answer here"
                onChange={(e) => onChange(e.target.value)}
                />}
            {type === "select" && <SelectField
                required
                options={optionsObjects}
                label={question}
                name={id}
                onChange={(val) => onChange(val)}
                />}
        </>
    );
}

export default TaxOrgAddtlQuestion;
