import { Button, Divider, Row, Col } from 'antd';
import { SelectField, RadioGroup, TextAreaInput, TextInput } from 'components/FormFields';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { ConfirmationPrompt } from '../ConfirmationPrompt';

export function HealthInsurance({ data, previousData, handleSubmit, handleBack }) {
  const initialValues = {
    has_health_insurance: data.has_health_insurance || previousData.has_health_insurance || '',
    health_insurance_type: data.health_insurance_type || previousData.health_insurance_type || '',
    health_insurance_is_whole_year: data.health_insurance_is_whole_year || previousData.health_insurance_is_whole_year || '',
    health_insurance_months: data.health_insurance_months || previousData.health_insurance_months || '',
    health_insurance_type_other: data.health_insurance_type_other || previousData.health_insurance_type_other || '',

    comment_personal_health_insurance: data.comment_personal_health_insurance || previousData.comment_personal_health_insurance || '',
  }

  const validationSchema = Yup.object().shape({
    has_health_insurance: Yup.string().required('Required'),
    health_insurance_type: Yup.string().when('has_health_insurance', {
      is: 'Yes',
      then: Yup.string().required('Required'),
    }),
    health_insurance_is_whole_year: Yup.string().when('has_health_insurance', {
      is: 'Yes',
      then: Yup.string().required('Required'),
    }),
  });

  const [isConfirmation, setIsConfirmation] = useState(true);

  useEffect(() => {
    setIsConfirmation(!data.has_health_insurance && !!previousData.has_health_insurance)
  }, [data, previousData]);

  const OPTIONS = [
    {
      "label": "Employer",
      "value": "Employer"
    },
    {
      "label": "Marketplace",
      "value": "Marketplace"
    },
    {
      "label": "Medicare",
      "value": "Medicare"
    },
    {
      "label": "Medicaid",
      "value": "Medicaid"
    },
    {
      "label": "Obamacare",
      "value": "Obamacare"
    },
    {
      "label": "Other",
      "value": "Other"
    }
  ];

  const BOOLEAN_OPTIONS = [
    {
      label: 'Yes',
      value: 'Yes',
    },
    {
      label: 'No',
      value: 'No',
    },
  ];

  return <Formik
    initialValues={initialValues}
    validationSchema={validationSchema}
    onSubmit={handleSubmit}
    validateOnMount
    validateOnChange
    enableReinitialize>
    {({ setFieldValue, values, submitForm }) => (
      <Form>
        {!isConfirmation && (
          <>
            <Divider orientation="left" orientationMargin="0" style={{ color: '#6A7581' }}>
              Health Insurance
            </Divider>

            <RadioGroup
              required
              name="has_health_insurance"
              label={`Do you have health insurance for this year?`}
              options={BOOLEAN_OPTIONS}
              onChange={(e) => {
                setFieldValue("has_health_insurance", e.target.value)
                if (e.target.value === "No") {
                  setFieldValue("health_insurance_type", '')
                }
              }}
            />

            {values.has_health_insurance === "Yes" && (
              <>
                <SelectField onChange={(value) => setFieldValue("health_insurance_type", value)}
                  value={values.health_insurance_type}
                  options={OPTIONS}
                  required
                  name="health_insurance_type"
                  label="What is your health insurance type?" />

                {values.health_insurance_type === "Other" && (
                  <TextInput
                    required
                    name="health_insurance_type_other"
                    label="What is your health insurance type?"
                    placeholder="Please put your health insurance type here"
                  />
                )}

                {values.health_insurance_type !== '' && (
                  <>
                    <RadioGroup
                      required
                      name="health_insurance_is_whole_year"
                      label={`Do you have health insurance for the whole year?`}
                      options={BOOLEAN_OPTIONS}
                    />
                    {values.health_insurance_is_whole_year === "No" && (
                      <>
                        <TextInput
                          required
                          name="health_insurance_months"
                          label="What months did you have health insurance?"
                          placeholder="Please put months covered here"
                        />
                      </>
                    )}
                  </>
                )}
              </>
            )}

            <div>
              <Button type="secondary" htmlType="button" onClick={handleBack}>
                Back
              </Button>
              <Button type="primary" htmlType="submit" style={{ float: 'right' }}>
                Continue
              </Button>
            </div>
            <Row>
              <Col span={24}>
                <TextAreaInput
                  name="comment_personal_health_insurance"
                  label="Comment (optional)"
                  placeholder="Enter your comment here"
                />
              </Col>
            </Row>
          </>
        )}

        {isConfirmation &&
          <ConfirmationPrompt
            submitForm={submitForm}
            setIsConfirmation={setIsConfirmation}>
            <Row>
              <Col span={24} >
                <p>
                  Do you have health insurance for this year?: <b>{previousData.has_health_insurance}</b>
                </p>
                {previousData.has_health_insurance === "Yes" && (
                  <>
                    <p>
                      What is your health insurance type?: <b>{previousData.health_insurance_type}</b>
                    </p>
                    <p>
                      Do you have health insurance for the whole year?: <b>{previousData.health_insurance_is_whole_year}</b>
                    </p>

                    {previousData.health_insurance_is_whole_year === "No" && (
                      <>
                        <p>
                          What months did you have health insurance?: <b>{previousData.health_insurance_months}</b>
                        </p>
                      </>
                    )}
                  </>
                )}
              </Col>
            </Row>
          </ConfirmationPrompt>}
      </Form>
    )}
  </Formik>
}
