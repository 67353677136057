/* eslint-disable no-undef */
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from 'routes/Routes';
import ScrollToTop from 'utilities/ScrollToTop';
import { ThemeProvider } from 'styled-components';
import GlobalStyles from 'styles/GlobalStyles';
import themeConfig from 'styles/styleConfig';
import { FeatureProvider, LoaderProvider, UserDetailProvider, ToDoProvider } from 'contexts';
import * as Sentry from '@sentry/react';
import { Provider } from 'react-redux';
import { ErrorFallback } from 'utilities';
import { hotjar } from 'react-hotjar';
import { IntlProvider } from 'react-intl';
import { purgoTexts, taxriseTexts } from 'locale';
import { useMemo } from 'react';
import { store } from 'stores/store';

hotjar.initialize(
    `${process.env['REACT_APP_HOTJAR_ID']}`,
    `${process.env['REACT_APP_HOTJAR_SV']}`
);

// Mocking language codes with brand name to solve react-intl error
const brandTexts = {
    de: purgoTexts,
    en: taxriseTexts,
};

const appId = process.env['REACT_APP_PROJECT_ID'];

function App() {
    const brand = useMemo(() => {
        switch (appId) {
            case 'purgo':
                return 'de';
            default:
                return 'en';
        }
    }, []);

    return (
        <Provider store={store}>
            <Sentry.ErrorBoundary fallback={ErrorFallback}>
                <ThemeProvider theme={themeConfig}>
                    <LoaderProvider>
                        <Router>
                            <GlobalStyles />
                            <UserDetailProvider>
                                <FeatureProvider>
                                    <ToDoProvider>
                                        <ScrollToTop />
                                        <IntlProvider
                                            locale={brand}
                                            messages={brandTexts[brand]}
                                        >
                                            <Routes />
                                        </IntlProvider>
                                    </ToDoProvider>
                                </FeatureProvider>
                            </UserDetailProvider>
                        </Router>
                    </LoaderProvider>
                </ThemeProvider>
            </Sentry.ErrorBoundary>
        </Provider>
    );
}
export default App;
