import { Button } from 'antd';
import { TextInput, DateInput, TextAreaInput } from 'components/FormFields';
import { Formik, FieldArray, Form } from 'formik';
import { Col, Row, Divider } from 'antd';
import { SelectField } from 'components/FormFields';
import React from 'react';
import * as Yup from 'yup';
import { BOOLEAN_OPTIONS } from 'const';
import { ConfirmationPrompt } from '../ConfirmationPrompt';
import { useState, useEffect } from 'react';

export const Dependents = ({ data, previousData, handleSubmit, handleBack }) => {
  const initialValues = {
    dependents: data.dependents || previousData.dependents || [],
    comment_personal_dependents: data.comment_personal_dependents || previousData.comment_personal_dependents || '',
  };

  const validationSchema = Yup.object().shape({
    dependents: Yup.array(
      Yup.object({
        first_name: Yup.string().required('Required'),
        last_name: Yup.string().required('Required'),
        ssn: Yup.string()
          .required('Required')
          .test('ssn', 'Invalid SSN format. It should be XXX-XX-XXXX', value => {
            const ssnPattern = /^[0-9]{3}-[0-9]{2}-[0-9]{4}$/;
            return ssnPattern.test(value);
          }),
        date_of_birth: Yup.string().required('Required'),
        relationship: Yup.string().required('Required'),
        months_living_with: Yup.string().required('Required'),
        has_income: Yup.string().required('Required'),
      })
    )
  })

  const OPTIONS = [
    { label: 'Son', value: 'Son' },
    { label: 'Daughter', value: 'Daughter' },
    { label: 'Stepchild', value: 'Stepchild' },
    { label: 'Foster child', value: 'Foster child' },
    { label: 'Grandchild', value: 'Grandchild' },
    { label: 'Brother', value: 'Brother' },
    { label: 'Sister', value: 'Sister' },
    { label: 'Half brother', value: 'Half brother' },
    { label: 'Half sister', value: 'Half sister' },
    { label: 'Niece', value: 'Niece' },
    { label: 'Nephew', value: 'Nephew' },
    { label: 'Parent', value: 'Parent' },
    { label: 'Grandparent', value: 'Grandparent' },
    { label: 'Aunt', value: 'Aunt' },
    { label: 'Uncle', value: 'Uncle' },
    { label: 'Stepbrother', value: 'Stepbrother' },
    { label: 'Stepsister', value: 'Stepsister' },
    { label: 'Other', value: 'Other' },
    { label: 'None', value: 'None' },
  ];

  const [isConfirmation, setIsConfirmation] = useState(true);

  useEffect(() => {
    setIsConfirmation(!data.dependents && !!previousData.dependents)
  }, [data, previousData]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnMount
      validateOnChange
      enableReinitialize>
      {({ setFieldValue, values, submitForm }) => (
        <Form>
          {!isConfirmation && (
            <>
              <Divider orientation="left" orientationMargin="0" style={{ color: '#6A7581' }}>
                Dependents
              </Divider>
              <FieldArray name="dependents">
                {({ insert, remove, push }) => (
                  <div>
                    {values.dependents.length > 0 &&
                      values.dependents.map((friend, index) => (
                        <Row key={index}>
                          <Col span={12} style={{ padding: 6 }}>
                            <TextInput
                              required
                              name={`dependents.${index}.first_name`}
                              label="First Name"
                            />
                          </Col>
                          <Col span={12} style={{ padding: 6 }}>
                            <TextInput
                              required
                              name={`dependents.${index}.last_name`}
                              label="Last Name"
                            /></Col>
                          <Col span={12} style={{ padding: 6 }}>
                            <TextInput
                              required
                              name={`dependents.${index}.ssn`}
                              label="SSN"
                              onChange={(event) => {
                                const { value } = event.target;
                                const ssn = value.replace(/\D/g, '').slice(0, 9);
                                let formattedSSN = ssn;
                                if (ssn.length > 5) {
                                  formattedSSN = `${ssn.slice(0, 3)}-${ssn.slice(3, 5)}-${ssn.slice(5)}`;
                                } else if (ssn.length > 3) {
                                  formattedSSN = `${ssn.slice(0, 3)}-${ssn.slice(3)}`;
                                }
                                setFieldValue(`dependents.${index}.ssn`, formattedSSN);
                              }}
                            /></Col>
                          <Col span={12} style={{ padding: 6 }}>
                            <DateInput
                              required
                              name={`dependents.${index}.date_of_birth`}
                              value={values.dependents[index].date_of_birth}
                              onChange={(value, valueStr) => { setFieldValue(`dependents.${index}.date_of_birth`, valueStr) }}
                              label="Date of Birth"
                            /></Col>
                          <Col span={12} style={{ padding: 6 }}>
                            <SelectField
                              required
                              value={values.dependents[index].relationship}
                              name={`dependents.${index}.relationship`}
                              onChange={(value) => { setFieldValue(`dependents.${index}.relationship`, value) }}
                              options={OPTIONS}
                              label="Relationship"
                            /></Col>
                          <Col span={12} style={{ padding: 6 }}>
                            <TextInput
                              name={`dependents.${index}.months_living_with`}
                              label="Months Living With You"
                            /></Col>
                          <Col span={12} style={{ padding: 6 }}>
                            <SelectField
                              required
                              value={values.dependents[index].has_income}
                              name={`dependents.${index}.has_income`}
                              onChange={(value) => { setFieldValue(`dependents.${index}.has_income`, value) }}
                              options={BOOLEAN_OPTIONS}
                              label="Has Income?"
                            /></Col>
                          {values.dependents[index].has_income === "Yes" && (
                            <Col span={12} style={{ padding: 6 }}>
                              <TextInput
                                required
                                type="number"
                                prefix="$"
                                name={`dependents.${index}.income`}
                                label="Income"
                              /></Col>
                          )}

                          <Button danger htmlType="button" style={{ width: '100%' }}
                            onClick={() => remove(index)} >
                            Remove Dependent
                          </Button>
                          <Divider plain></Divider>
                        </Row>
                      ))}
                    <Button type="secondary" htmlType="button" style={{ width: '100%' }}
                      onClick={() => push({ first_name: '', last_name: '', ssn: '', date_of_birth: '', relationship: '' })} >
                      Add Dependent
                    </Button>
                    <Divider plain></Divider>
                    <div>
                      <Button type="secondary" htmlType="button" onClick={handleBack}>
                        Back
                      </Button>
                      <Button type="primary" htmlType="submit" style={{ float: 'right' }}>
                        Continue
                      </Button>
                    </div>
                    <Row>
                      <Col span={24}>
                        <TextAreaInput
                          name="comment_personal_dependents"
                          label="Comment (optional)"
                          placeholder="Enter your comment here"
                        />
                      </Col>
                    </Row>
                  </div>
                )}
              </FieldArray>
            </>
          )}
          {isConfirmation && (
            <ConfirmationPrompt
              submitForm={submitForm}
              setIsConfirmation={setIsConfirmation}
            >
              <Divider orientation="left" orientationMargin="0" style={{ color: '#6A7581' }}>
                Dependents
              </Divider>
              {previousData.dependents?.map((dependent, index) => {
                return (
                  <div key={index}>
                    <Divider orientation="left" orientationMargin="0" style={{ color: '#6A7581' }}>
                    </Divider>
                    <p>First Name: {dependent.first_name}</p>
                    <p>Last Name: {dependent.last_name}</p>
                    <p>SSN: {dependent.ssn}</p>
                    <p>Date of Birth: {dependent.date_of_birth}</p>
                    <p>Relationship: {dependent.relationship}</p>
                    <p>Months Living With You: {dependent.months_living_with}</p>
                    <p>Has Income: {dependent.has_income}</p>
                    {dependent.has_income === "Yes" && (
                      <p>Income: {dependent.income}</p>
                    )}
                  </div>
                )
              })}
            </ConfirmationPrompt>
          )}
        </Form>
      )}
    </Formik>
  )
};
