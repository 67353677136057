import { Layout } from 'antd';
import styled from 'styled-components';
import Menu from './Menu';

const { Sider: AntSider } = Layout;

function Sidebar({ onCollapse, hideMenu }) {
    return (
        <Sider
            width="240"
            theme="light"
            breakpoint="lg"
            collapsedWidth="0"
            trigger={null}
            onCollapse={(collapsed) => onCollapse(collapsed)}
        >
            <Menu hideMenu={hideMenu} />
        </Sider>
    );
}

const Sider = styled(AntSider)`
    border-right: 1px solid #e0e0e0;
    overflow: auto;
    height: 100vh;
    position: fixed;
    left: 0;

    .ant-layout-sider-children {
        display: flex;
        flex-direction: column;
        align-items: baseline;
    }
`;

export default Sidebar;
