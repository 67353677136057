import { notification } from 'antd';
import { endpoints, useApi } from 'api';
import { FeatureContext, UserDetailContext } from 'contexts';
import { useContext } from 'react';
import { useHistory } from 'react-router';
import paths from 'routes/paths';

// eslint-disable-next-line no-undef
const authServiceEndpoint = process.env.REACT_APP_AUTH_SERVICE_ENDPOINT;
// eslint-disable-next-line no-undef
const verifilinkUrl = process.env.REACT_APP_VERIFILINK_URL;

export default function useVerifilinkRedirect() {
    const [api] = useApi();
    const history = useHistory();
    const { updateFeatureStatus } = useContext(FeatureContext);
    const { userDetailInfo } = useContext(UserDetailContext);
    const { case_id } = userDetailInfo;

    const redirectToVerifilink = async () => {
        try {
            const req = await api({
                method: 'POST',
                url: `${authServiceEndpoint}${endpoints.SHORT_TOKEN}`,
            });

            const res = await req.data;

            if (res['short_token']) {
                window.open(
                    `${verifilinkUrl}externalhandover/${res.short_token}`,
                    '_self'
                );
            }
        } catch (error) {
            console.log(error);
        }
    };

    const redirectToSummary = () => {
        history.push(paths.VERIFILINK_SUMMARY);
    };

    const checkStatus = async () => {
        if (!case_id) {
            notification.warn({ message: 'Please login again!' });
            return;
        }

        try {
            const req = await api({
                url: endpoints.FEATURE_STATUS,
                params: {
                    'case-id': case_id,
                },
            });

            const res = await req.data;

            const { verifilink, transcript, vlink_complete, vlink_ever_completed, show433 } = res;

            updateFeatureStatus({
                verifilink,
                transcript,
                vlink_complete,
                vlink_ever_completed,
                show433,
            });

            vlink_ever_completed ? redirectToSummary() : redirectToVerifilink();
        } catch (error) {
            console.log(error.response);
        }
    };

    return [checkStatus, redirectToVerifilink, redirectToSummary];
}
