export const api_ver = 'api/v1';
const SALESFORCE_CLIENT_CHECK = `/auth/${api_ver}/cses`;
const SIGN_UP = `/auth/${api_ver}/users/open`;
const RESEND_EMAIL = `/auth/${api_ver}/email-verify/resend`;
const EMAIL_VERIFICATION = `/auth/${api_ver}/verify-email/verify-token`;
const GET_OTP_SOURCE = `/auth/${api_ver}/users/get-otp-source`;
const SEND_OTP = `/auth/${api_ver}/send-otp`;
const OTP_CHECK = `/auth/${api_ver}/otp-check`;
const ACCESS_TOKEN = `/auth/${api_ver}/login/access-token`;
const GET_IN_TOUCH = `/touch/v1/get-in-touch/`;
const FAQ = `/faq/v1/faq/`;
const FORGOT_PASSWORD_INIT = `/auth/${api_ver}/password-recovery`;
const PASSWORD_RESET = `/auth/${api_ver}/reset-password/`;
const TRANSCRIPT = `/auth/${api_ver}/devs/temp/transcripts/`;
const PAYMENT_DASHBOARD = `/bill/billing/dashboard/`;
const PAYMENT_HISTORY = `/bill/billing/history/`;
const MAKE_PAYMENT = `/bill/billing/make/`;
const ADD_CREDIT_CARD = '/pay/fast-pay/card/insert/';
const DOCUMENTS_UPLOAD = '/doc/v1/upload/';
const GET_SETTLEMENT_OFFICER = `/todo/${api_ver}/settlement-officer`;
const TO_DO_STATUS = `/todo/${api_ver}/todo-status`;
const CM_CALENDLY = '/touch/v1/calendly/';
const SHORT_TOKEN = `${api_ver}/login/short-token`;
const TOKEN_HARDCODED = `/auth/${api_ver}/login/access-token/hardcoded`;
const FEATURE_STATUS = `/todo/${api_ver}/feature-status`;
// eslint-disable-next-line no-undef
const SUMMARY_VERIFILINK = `${process.env.REACT_APP_VL_ENDPOINT}final-output-process/api/v1/summary/`;
const DOWNLOAD_433 = '/433a/pdf-record';
const INVALIDATE_TOKEN = `${process.env.REACT_APP_AUTH_SERVICE_ENDPOINT}${api_ver}/logout`;

const CLIENTS = `${process.env.REACT_APP_AUTH_SERVICE_ENDPOINT}${api_ver}/users/`;
const PLAID_PERIODIC = `/plaid/periodic-amount/`;
const PLAID_BANKS = `/plaid/banks/`;
const PLAID_TRANSACTIONS = `/plaid/transactions/`;

export default {
    SALESFORCE_CLIENT_CHECK,
    SIGN_UP,
    RESEND_EMAIL,
    EMAIL_VERIFICATION,
    ACCESS_TOKEN,
    GET_IN_TOUCH,
    FAQ,
    FORGOT_PASSWORD_INIT,
    PASSWORD_RESET,
    TRANSCRIPT,
    PAYMENT_DASHBOARD,
    PAYMENT_HISTORY,
    MAKE_PAYMENT,
    ADD_CREDIT_CARD,
    DOCUMENTS_UPLOAD,
    TO_DO_STATUS,
    CM_CALENDLY,
    SHORT_TOKEN,
    TOKEN_HARDCODED,
    FEATURE_STATUS,
    SUMMARY_VERIFILINK,
    DOWNLOAD_433,
    INVALIDATE_TOKEN,
    CLIENTS,
    PLAID_PERIODIC,
    PLAID_BANKS,
    PLAID_TRANSACTIONS,
    GET_SETTLEMENT_OFFICER,
    GET_OTP_SOURCE,
    SEND_OTP,
    OTP_CHECK,
};
