import { amexLogo, discoverLogo, mcLogo, visaLogo } from 'assets/img';
import STATE_OPTIONS from './state_options';
export const CONTACT_LIST = [
    {
        label: 'By Email',
        value: 'By Email',
    },
    {
        label: 'By Phone Call',
        value: 'By Phone Call',
    },
    {
        label: 'By Text Message',
        value: 'By Text Message',
    },
];

export const PAYMENT_SCHEDULE_MESSAGE = 'Your payment is scheduled for today.';
export const ACTION_COMPLETE_MESSAGE =
    'Complete all of the tasks in your to-do list before accessing the rest of your actions!';
export const TODO_ITEM_FOOTER_TEXT =
    'Quickly create a full financial picture of yourself and speed up your case by completing our questionnaire.';

export const ACCOUNT_TYPE_OPTIONS = [
    { label: 'Checking', value: 'checking' },
    { label: 'Savings', value: 'savings' },
];

export const OTHER_DOCUMENTS = 1;

export const OTHER_LIST = [
    {
        title: 'Copy of Tax Returns',
        id: 'copy_of_Tax_returns',
        status_title: 'In Review',
        status: 'review',
        message: 'Your files have been uploaded successfully!',
    },
];

export const DOCUMENT_ITEMS = [
    {
        title: 'Expense Documents',
        id: '1',
        name: 'expense_documents',
        items: [
            {
                title: 'Mortgage Statement',
                id: 'mortgage',
                status_title: 'In Review',
                status: 'review',
                message: 'Your files have been uploaded successfully!',
            },
            {
                title: 'Rent Statement',
                id: 'rent',
                status_title: 'Accepted',
                status: 'accepted',
                message: '',
            },
            {
                title: 'Court Ordered Statement',
                id: 'court',
                status_title: 'Resubmit',
                status: 'resubmit',
                message:
                    'Your July and August mortgage statements were blurry, please re-take pictures of these statements and upload them again, thank you!',
            },
            {
                title: 'Electric Bill',
                id: 'electric_bill',
                status_title: 'In Review',
                status: '',
                message: '',
            },
            {
                title: 'Water Bill',
                id: 'water_bill',
                status_title: 'In Review',
                status: 'review',
                message: '',
            },
            {
                title: 'Gas Bill',
                id: 'gas_bill',
                status_title: 'In Review',
                status: 'review',
                message: '',
            },
            {
                title: 'Cable Bill',
                id: 'cable_bill',
                status_title: 'In Review',
                status: 'review',
                message: '',
            },
            {
                title: 'Phone Bill',
                id: 'phone_bill',
                status_title: 'In Review',
                status: 'review',
                message: '',
            },
        ],
    },
    {
        title: 'Income Documents',
        id: '2',
        name: 'income_documents',
        items: [
            {
                title: 'Profit & Loss Statement',
                id: 'profit_loss',
                status_title: 'In Review',
                status: 'review',
                message: '',
            },
            {
                title: 'Unemployment Verification',
                id: 'unemployment',
                status_title: 'In Review',
                status: 'review',
                message: '',
            },
            {
                title: 'Pension Statement',
                id: 'pension',
                status_title: 'In Review',
                status: 'review',
                message: '',
            },
            {
                title: 'Pay Stubs',
                id: 'pay_stubs',
                status_title: 'In Review',
                status: 'review',
                message: '',
            },
            {
                title: 'SSI Benefit Letter',
                id: 'ssi_benefit',
                status_title: 'In Review',
                status: 'review',
                message: '',
            },
        ],
    },
    {
        title: 'Bank Statements',
        id: '3',
        name: 'bank_statements',
        items: [
            {
                title: 'Personal',
                id: 'personal',
                status_title: 'In Review',
                status: 'review',
                message: '',
            },
            {
                title: 'Business',
                id: 'business',
                status_title: 'In Review',
                status: 'review',
                message: '',
            },
            {
                title: 'spouse',
                id: 'pension',
                status_title: 'In Review',
                status: 'review',
                message: '',
            },
            {
                title: 'Investment Accounts',
                id: 'investment',
                status_title: 'In Review',
                status: 'review',
                message: '',
            },
        ],
    },
    {
        title: 'Health Care Documents',
        id: '4',
        name: 'health_care_documents',
        items: [
            {
                title: 'Medical Bills',
                id: 'medical_bills',
                status_title: 'In Review',
                status: 'review',
                message: '',
            },
            {
                title: 'Doctors Letter',
                id: 'doctors_letter',
                status_title: 'In Review',
                status: 'review',
                message: '',
            },
            {
                title: 'Health Care Statement',
                id: 'health_care_statement',
                status_title: 'In Review',
                status: 'review',
                message: '',
            },
            {
                title: 'Prescriptions',
                id: 'prescriptions',
                status_title: 'In Review',
                status: 'review',
                message: '',
            },
            {
                title: 'Copays',
                id: 'copays',
                status_title: 'In Review',
                status: 'review',
                message: '',
            },
        ],
    },
];

export const CHART_COLORS = [
    '#5DB2DA',
    '#FF925A',
    '#F3BD28',
    '#e74c3c',
    '#2980b9',
    '#95a5a6',
    '#d35400',
    '#16a085',
    '#f39c12',
    '#8e44ad',
];

/* AMEX = '3'
MASTERCARD = '2'
DISCOVER = '4'
VISA = '1' */
export const CARD_LOGO = {
    1: visaLogo,
    2: mcLogo,
    3: amexLogo,
    4: discoverLogo,
};
export const EMAIL_REGEX = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const LETTER_CASES_PATTERN = /(?=.*[a-z])(?=.*[A-Z])/;
export const CHARACTERS_TYPE_PATTERN = /(?=.*\d)(?=.*[a-zA-Z])/;
export const SPECIAL_SYMBOLS_PATTERN = /(?=.*\W)/;
export const FORBIDDEN_SPECIAL_SYMBOLS_PATTERN = /^[^<>]*$/;

export const TODO_TASKS = [
    {
        id: 1,
        title: 'Schedule Review & Advise Appointment',
        value: 'schedule_review_and_advise_appointment',
        details:
            'Phase I is complete. Review your investigation results and discuss our resolution strategy with your Tax Resolution Officer. ',
        status_map: [418, 427],
    },
    {
        id: 2,
        title: 'Schedule Phase II Welcome Appointment',
        value: 'schedule_phase_ii_welcome_appointment',
        details:
            'Schedule your 15-minute welcome call with your case manager to discuss the next steps of your resolution. ',
        status_map: [387],
    },
    {
        id: 3,
        title: 'Complete Verifilink',
        value: 'complete_verifilink',
        details:
            'Complete your financial profile with Verifilink to move forward with your resolution.',
        status_map: [313],
    },
    {
        id: 4,
        title: 'Upload Required Documents',
        value: 'upload_required_documents',
        details:
            'One or more documents are missing from your file. Upload the requested documents to move forward with your resolution.',
        status_map: [],
        is_verifilink_complete: true,
    },
    {
        id: 5,
        title: 'Schedule Document Review',
        value: 'schedule_document_review',
        details:
            'Your documents are ready to be reviewed with your Case Manager. Schedule your one-on-one appointment to move forward with your resolution.',
        status_map: [320],
    },
    {
        id: 6,
        title: 'Sign & Return Resolution Documents',
        value: 'sign_and_return_resolution_documents',
        details:
            'Your resolution documents are prepared and require your signature.',
        status_map: [314],
    },
    /* {
        id: 7,
        title: 'Rate the Process',
        value: 'rate_the_process',
        details:
            'Let us know how we did. Rate your experience resolving your tax issue with TaxRise.',
        status_map: [],
    }, */
];

export const STATUS_SINGLE = 'single';
export const STATUS_MARRIED = 'married_filing_jointly';
export const STATUS_MARRIED_SEPARATELY = 'married_filing_seperately';
export const STATUS_HEAD_OF_HOUSEHOLD = 'head_of_household';
export const STATUS_WIDOW_WITH_DEPENDENT = 'qualifying_widow_with_dependent';

export const FILING_STATUS_LABEL = {
    single: 'Single',
    married_filing_jointly: 'Married Filing Jointly',
    married_filing_seperately: 'Married Filing Seperately',
    head_of_household: 'Head of Household',
    qualifying_widow_with_dependent: 'Qualifying Widow With Dependent',
};

// Don't put space after comma ","!
export const ACCEPTED_FILE_TYPES =
    'image/jpg,image/png,image/jpeg,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

export const ACCEPTED_TYPES_ARR = ACCEPTED_FILE_TYPES.split(',');

export { STATE_OPTIONS };
export const DATE_FORMAT = 'MM-DD-YYYY';
export const COLORS = [
    '#FF6633',
    '#FFB399',
    '#FF33FF',
    '#00B3E6',
    '#E6B333',
    '#3366E6',
    '#999966',
    '#B34D4D',
    '#80B300',
    '#809900',
    '#E6B3B3',
    '#6680B3',
    '#66991A',
    '#FF99E6',
    '#FF1A66',
    '#E6331A',
    '#33FFCC',
    '#66994D',
    '#B366CC',
    '#4D8000',
    '#B33300',
    '#CC80CC',
    '#66664D',
    '#991AFF',
    '#E666FF',
    '#4DB3FF',
    '#1AB399',
    '#E666B3',
    '#33991A',
    '#CC9999',
    '#B3B31A',
    '#00E680',
    '#4D8066',
    '#809980',
    '#1AFF33',
    '#999933',
    '#FF3380',
    '#CCCC00',
    '#4D80CC',
    '#9900B3',
    '#E64D66',
    '#4DB380',
    '#FF4D4D',
    '#99E6E6',
    '#6666FF',
];

  export const BOOLEAN_OPTIONS = [
    {
      label: 'Yes',
      value: 'Yes',
    },
    {
      label: 'No',
      value: 'No',
    },
  ];
