import { Button, Divider, Row, Col } from 'antd';
import { TextInput, RadioGroup, TextAreaInput } from 'components/FormFields';
import { Formik, Form } from 'formik';
import { useEffect, useState } from 'react';
import { ConfirmationPrompt } from '../ConfirmationPrompt';

export function SoleProprietorshipIncome({ data, previousData, handleSubmit, handleBack }) {
  const initialValues = {
    sole_proprietorship_profit_and_loss: data.sole_proprietorship_profit_and_loss || previousData.sole_proprietorship_profit_and_loss || '',
    sole_propietorship_income_reported: data.sole_propietorship_income_reported || previousData.sole_propietorship_income_reported || '',
    sole_propietorship_income_not_reported: data.sole_propietorship_income_not_reported || previousData.sole_propietorship_income_not_reported || '',
    sole_propietorship_income_returns_and_allowences: data.sole_propietorship_income_returns_and_allowences || previousData.sole_propietorship_income_returns_and_allowences || '',
    sole_propietorship_income_other: data.sole_propietorship_income_other || previousData.sole_propietorship_income_other || '',
    comment_sole_proprietorship_income: data.comment_sole_proprietorship_income || previousData.comment_sole_proprietorship_income || '',
  }

  const [isConfirmation, setIsConfirmation] = useState(true);

  useEffect(() => {
    setIsConfirmation(!data.sole_propietorship_income_reported && !!previousData.sole_propietorship_income_reported)
  }, [data, previousData]);

  const PROFIT_AND_LOSS_OPTIONS = [
    {
      label: 'Upload',
      value: 'Upload',
    },
    {
      label: 'Fill Manually',
      value: 'Manually',
    },
  ];


  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validateOnMount
      validateOnChange
      enableReinitialize>
      {({ setFieldValue, values, submitForm }) => (
        <Form>
          {!isConfirmation && (
            <>
              <Divider orientation="left" orientationMargin="0" style={{ color: '#6A7581' }}>
                Business Income
              </Divider>

              <RadioGroup
                required
                options={PROFIT_AND_LOSS_OPTIONS}
                label={"Do you want to upload your Profit and Loss statement or you want to do it manually?"}
                name="sole_proprietorship_profit_and_loss"
              />

              {values.sole_proprietorship_profit_and_loss === 'Manually' && (
                <>
                  <TextInput
                    name="sole_propietorship_income_reported"
                    label="Income Reported on 1099"
                    required
                    type="number"
                    prefix="$"
                  />

                  <TextInput
                    name="sole_propietorship_income_not_reported"
                    label="Income Not Reported on 1099"
                    required
                    type="number"
                    prefix="$"
                  />

                  <TextInput
                    name="sole_propietorship_income_returns_and_allowences"
                    label="Returns and Allowances"
                    required
                    type="number"
                    prefix="$"
                  />

                  <TextInput
                    name="sole_propietorship_income_other"
                    label="Other Income"
                    required
                    type="number"
                    prefix="$"
                  />
                </>
              )}

              <div>
                <Button type="secondary" htmlType="button" onClick={handleBack}>
                  Back
                </Button>
                <Button type="primary" htmlType="submit" style={{ float: 'right' }}>
                  Continue
                </Button>
              </div>
              <Row>
                <Col span={24}>
                  <TextAreaInput
                    name="comment_sole_proprietorship_income"
                    label="Comment (optional)"
                    placeholder="Enter your comment here"
                  />
                </Col>
              </Row>
            </>
          )}

          {isConfirmation &&
            <ConfirmationPrompt
              submitForm={submitForm}
              setIsConfirmation={setIsConfirmation}>
              <p>
                Income Reported on 1099: <b>{previousData.sole_propietorship_income_reported}</b>
              </p>
              <p>
                Income Not Reported on 1099: <b>{previousData.sole_propietorship_income_not_reported}</b>
              </p>
              <p>
                Returns and Allowances: <b>{previousData.sole_propietorship_income_returns_and_allowences}</b>
              </p>
              <p>
                Other Income: <b>{previousData.sole_propietorship_income_other}</b>
              </p>
            </ConfirmationPrompt>}
        </Form>
      )}
    </Formik>
  )
}
