import { Button, Row, Col } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';
import { useHistory } from "react-router-dom";

export function Finish({ data, previousData, handleSubmit, handleBack }) {
  const history = useHistory();

  return (
    <Row style={{marginTop: 32}}>
      <Col span={24} style={{textAlign: 'center'}}>
        <CheckCircleFilled color='green' style={{ fontSize: 64, color: 'green', marginBottom: 10 }} />
        <h1>You&apos;ve successfully completed your Tax Organizer!</h1>
        <p>You will ne automatically redirected to your client portal dashboard in 5 seconds. If you are not redirected, please <a href='/dashboard'>click here</a></p>

        <Button type='primary' onClick={() => history.push('/dashboard')} style={{width: '100%'}}>Back To Dashboard</Button>
      </Col>
    </Row>
  )
}
