export const fetchDocuments = (api, case_id) => {
  return async (dispatch) => {

    try {
      dispatch({ type: 'FETCH_DOCUMENTS' });

      const { data } = await api({
        url: `/doc/v1/cases/${case_id}/requests/`,
      });

      dispatch({
        type: 'FETCH_DOCUMENTS_SUCCESS',
        payload: data,
      });

    } catch (error) {
      dispatch({
        type: 'FETCH_DOCUMENTS_ERROR',
        payload: error?.message || error,
      });
    }
  };
};

export const fetchTaxOrganizerData = (api, case_id, document_id) => {
  return async (dispatch) => {

    try {
      dispatch({ type: 'FETCH_DOCUMENT_WORKFLOW_DATA' });

      const { data } = await api({
        url: `/doc/v1/cases/${case_id}/requests/${document_id}`,
      });

      // Current Additional Qs that need to be asked
      const {additional_questions} = data

      // If Client hasn't answered any Additional Qs before,
      // Tax Org will ask the Current Additional Qs
      if (additional_questions && !data.data) {
        data.data = {additional_questions}
      } else if (additional_questions && data.data.additional_questions) {
        // If Client has answered Additional Qs before,
        // Add in any Qs that may have been created since then
        for (const currentlyCreatedQ of additional_questions) {
          let hasClientAnsweredQ = false
          for (const alreadyAnsweredQ of data.data.additional_questions) {
            if (currentlyCreatedQ.id === alreadyAnsweredQ.id) {
              hasClientAnsweredQ = true
            } 
          }
          if (!hasClientAnsweredQ) {
            data.data.additional_questions.push(currentlyCreatedQ)
          }
        }
      } else {
        data.data = {additional_questions}
      }

      dispatch({
        type: 'FETCH_DOCUMENT_WORKFLOW_DATA_SUCCESS',
        payload: data,
      });

    } catch (error) {
      dispatch({
        type: 'FETCH_DOCUMENT_WORKFLOW_DATA_ERROR',
        payload: error?.message || error,
      });
    }
  };
};

export const getSignatureData = (api, case_id, document_id) => {
  return async (dispatch) => {

    try {
      dispatch({ type: 'DOCUMENT_SIGNATURE_STARTED' });

      const { data } = await api({
        url: `/doc/v1/cases/${case_id}/requests/${document_id}/signature`,
      });

      dispatch({
        type: 'DOCUMENT_SIGNATURE_SUCCEED',
        payload: { ...data, document_id, case_id },
      });

    } catch (error) {
      dispatch({
        type: 'DOCUMENT_SIGNATURE_ERROR',
        payload: error?.message || error,
      });
    }
  };
};

export const fetchTaxOrganizerDataPast = (api, case_id, current_year) => {
  return async (dispatch) => {

    const TAX_ORGANIZER_DOCUMENT_TYPE_ID = 62

    try {
      dispatch({ type: 'FETCH_DOCUMENT_WORKFLOW_DATA_PAST' });

      const { data } = await api({
        url: `/doc/v1/cases/${case_id}/requests/?document_type_id=${TAX_ORGANIZER_DOCUMENT_TYPE_ID}`,
      });

      let pastOrganizer = null

      let pastOrganizers = data.filter(organizer => organizer.period < current_year)
      pastOrganizers = pastOrganizers.sort((a, b) => b.period - a.period)

      if (pastOrganizers.length > 0) {
        pastOrganizer = pastOrganizers[0]
      }

      dispatch({
        type: 'FETCH_DOCUMENT_WORKFLOW_DATA_PAST_SUCCESS',
        payload: pastOrganizer,
      });

    } catch (error) {
      dispatch({
        type: 'FETCH_DOCUMENT_WORKFLOW_DATA_PAST_ERROR',
        payload: error?.message || error,
      });
    }
  };
};

export const saveTaxOrganizerData = (api, case_id, document_id, data, status) => {
  return async (dispatch) => {
    try {
      dispatch({ type: 'SAVE_DOCUMENT_WORKFLOW_DATA', payload: data });

      const { response } = await api({
        method: 'put',
        url: `/doc/v1/cases/${case_id}/requests/${document_id}/data`,
        data: { data, status }
      });

      dispatch({
        type: 'SAVE_DOCUMENT_WORKFLOW_DATA_SUCCESS',
        payload: response.data,
      });

    } catch (error) {
      dispatch({
        type: 'SAVE_DOCUMENT_WORKFLOW_DATA_ERROR',
        payload: error?.message || error,
      });
    }
  };
};

export const updateDocumentRequestStatus = (api, case_id, document_id, status) => {
  return async (dispatch) => {
    try {
      await api({
        method: 'put',
        url: `/doc/v1/cases/${case_id}/requests/${document_id}/data`,
        data: { data: {}, status }
      });
    } catch (error) {
      console.log(error)
    }
  };
};

export const documentSignatureModalVisible = (modalVisible) => {
  return async (dispatch) => {
    dispatch({
      type: 'MODAL_VISIBLE_UPDATE',
      payload: { modalVisible },
    });
  };
};

export const uploadDocument = (api, case_id, request_id, file) => {
  return async (dispatch) => {
    const fileData = new FormData()

    fileData.append('file', file)

    try {
      dispatch({ type: 'FETCH_DOCUMENTS' });

      const res = await api({
        method: 'post',
        url: `/doc/v1/cases/${case_id}/requests/${request_id}/`,
        data: fileData
      });

      if (res.status === 200) {
        dispatch(fetchDocuments(api, case_id))
      }

    } catch (error) {
      dispatch({
        type: 'FETCH_DOCUMENTS_ERROR',
        payload: error?.message || error,
      });
    }
  };
};