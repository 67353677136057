import { endpoints, useApi } from 'api';
import { cleanObj } from 'library';
import { useEffect, useState } from 'react';

const useFetchTransactions = (params) => {
    const [transactions, setTransactions] = useState([]);
    const [loading, toggleLoading] = useState(false);
    const [apiParam, setApiParam] = useState(params);

    const [api] = useApi();

    useEffect(() => {
        const fetchTransactions = async () => {
            try {
                toggleLoading(true);
                const req = await api({
                    url: `${endpoints.PLAID_TRANSACTIONS}`,
                    params: apiParam,
                });

                const res = await req.data;

                if (Array.isArray(res) && res.length) {
                    setTransactions(res);
                }
                toggleLoading(false);
            } catch (error) {
                console.log({ error });
                toggleLoading(false);
            }
        };

        fetchTransactions();
    }, [api, apiParam]);

    useEffect(() => {
        setApiParam((exParam) => {
            const newParams = cleanObj(params);

            if (JSON.stringify(exParam) !== JSON.stringify(newParams)) {
                return newParams;
            } else {
                return exParam;
            }
        });
    }, [params]);

    return [transactions, loading];
};

export default useFetchTransactions;
