import { Button, Divider, Row, Col } from 'antd';
import { CheckboxField, TextAreaInput } from 'components/FormFields';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { ConfirmationPrompt } from '../ConfirmationPrompt';
import { useState, useEffect } from 'react';
import _ from 'lodash';

export function IncomeSources({ data, previousData, handleSubmit, handleBack }) {

  const initialValues = {
    income_is_employment: data.income_is_employment || previousData.income_is_employment || '',
    income_is_retirement_distribution: data.income_is_retirement_distribution || previousData.income_is_retirement_distribution || '',
    income_is_social_security: data.income_is_social_security || previousData.income_is_social_security || '',
    income_is_self_employment: data.income_is_self_employment || previousData.income_is_self_employment || '',
    income_is_state_or_local_tax_refund: data.income_is_state_or_local_tax_refund || previousData.income_is_state_or_local_tax_refund || '',
    income_is_unemployment: data.income_is_unemployment || previousData.income_is_unemployment || '',
    income_is_interest: data.income_is_interest || previousData.income_is_interest || '',
    income_is_dividends: data.income_is_dividends || previousData.income_is_dividends || '',
    income_is_sold_stock_or_investments: data.income_is_sold_stock_or_investments || previousData.income_is_sold_stock_or_investments || '',
    income_is_canceled_debt: data.income_is_canceled_debt || previousData.income_is_canceled_debt || '',
    income_is_alimony: data.income_is_alimony || previousData.income_is_alimony || '',
    income_is_rents: data.income_is_rents || previousData.income_is_rents || '',
    income_is_royalties: data.income_is_royalties || previousData.income_is_royalties || '',
    income_is_esa_or_529_distribution: data.income_is_esa_or_529_distribution || previousData.income_is_esa_or_529_distribution || '',
    income_is_hsa_or_msa_distribution: data.income_is_hsa_or_msa_distribution || previousData.income_is_hsa_or_msa_distribution || '',
    income_is_partnership: data.income_is_partnership || previousData.income_is_partnership || '',
    income_is_s_corporation: data.income_is_s_corporation || previousData.income_is_s_corporation || '',
    income_is_estate_or_trust: data.income_is_estate_or_trust || previousData.income_is_estate_or_trust || '',
    income_is_gambling: data.income_is_gambling || previousData.income_is_gambling || '',
    income_is_other_income: data.income_is_other_income || previousData.income_is_other_income || '',
    income_is_farming: data.income_is_farming || previousData.income_is_farming || '',
    comment_income_sources: data.comment_income_sources || previousData.comment_income_sources || '',
  }

  const validationSchema = Yup.object().shape({});

  const [isConfirmation, setIsConfirmation] = useState(true);

  useEffect(() => {
    const dataWithInitialKeys = _.pickBy(data, (value, key) => {
      return key.includes('income_is_') && value === true;
    });

    const previousDataWithInitialKeys = _.pickBy(previousData, (value, key) => {
      return key.includes('income_is_') && value === true;
    });

    setIsConfirmation(_.keys(dataWithInitialKeys).length === 0 && _.keys(previousDataWithInitialKeys).length > 0)
  }, [data, previousData]);

  // TODO: make an array of objects to render the checkboxes
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnMount
      validateOnChange
      enableReinitialize>
      {({ setFieldValue, values, submitForm }) => (
        <Form>
          {!isConfirmation && (
            <>
              <Divider orientation="left" orientationMargin="0" style={{ color: '#6A7581' }}>
                Specify your Income Sources
              </Divider>
              <Row>
                <Col span={12}>
                  <CheckboxField
                    onChange={(value) => { setFieldValue("income_is_employment", value.target.checked == true) }}
                    value={values.income_is_employment === true}
                    name="income_is_employment">
                    Employment (W-2)
                  </CheckboxField>

                  <CheckboxField
                    onChange={(value) => { setFieldValue("income_is_retirement_distribution", value.target.checked == true) }}
                    value={values.income_is_retirement_distribution === true}
                    name="income_is_retirement_distribution">
                    Retirement distribution (1099-R)
                  </CheckboxField>

                  <CheckboxField
                    onChange={(value) => { setFieldValue("income_is_social_security", value.target.checked == true) }}
                    value={values.income_is_social_security === true}
                    name="income_is_social_security">
                    Social Security (SSA-1099)
                  </CheckboxField>

                  <CheckboxField
                    onChange={(value) => { setFieldValue("income_is_self_employment", value.target.checked == true) }}
                    value={values.income_is_self_employment === true}
                    name="income_is_self_employment">
                    Self-employment (1099-NEC or 1099-K)
                  </CheckboxField>

                  <CheckboxField
                    onChange={(value) => { setFieldValue("income_is_state_or_local_tax_refund", value.target.checked == true) }}
                    value={values.income_is_state_or_local_tax_refund === true}
                    name="income_is_state_or_local_tax_refund">
                    State or local tax refund (1099-G)
                  </CheckboxField>

                  <CheckboxField
                    onChange={(value) => { setFieldValue("income_is_unemployment", value.target.checked == true) }}
                    value={values.income_is_unemployment === true}
                    name="income_is_unemployment">
                    Unemployment (1099-G)
                  </CheckboxField>

                  <CheckboxField
                    onChange={(value) => { setFieldValue("income_is_interest", value.target.checked == true) }}
                    value={values.income_is_interest === true}
                    name="income_is_interest">
                    Interest (1099-INT)
                  </CheckboxField>

                  <CheckboxField
                    onChange={(value) => { setFieldValue("income_is_dividends", value.target.checked == true) }}
                    value={values.income_is_dividends === true}
                    name="income_is_dividends">
                    Dividends (1099-DIV)
                  </CheckboxField>

                  <CheckboxField
                    onChange={(value) => { setFieldValue("income_is_sold_stock_or_investments", value.target.checked == true) }}
                    value={values.income_is_sold_stock_or_investments === true}
                    name="income_is_sold_stock_or_investments">
                    Sold stock or investments (1099-B)
                  </CheckboxField>

                  <CheckboxField
                    onChange={(value) => { setFieldValue("income_is_canceled_debt", value.target.checked == true) }}
                    value={values.income_is_canceled_debt === true}
                    name="income_is_canceled_debt">
                    Canceled debt (1099-C)
                  </CheckboxField>

                  <CheckboxField
                    onChange={(value) => { setFieldValue("income_is_alimony", value.target.checked == true) }}
                    value={values.income_is_alimony === true}
                    name="income_is_alimony">
                    Alimony Income
                  </CheckboxField>
                </Col>
                <Col span={12}>
                  <CheckboxField
                    onChange={(value) => { setFieldValue("income_is_rents", value.target.checked == true) }}
                    value={values.income_is_rents === true}
                    name="income_is_rents">
                    Rents (1099-MISC)
                  </CheckboxField>

                  <CheckboxField
                    onChange={(value) => { setFieldValue("income_is_royalties", value.target.checked == true) }}
                    value={values.income_is_royalties === true}
                    name="income_is_royalties">
                    Royalties (1099-MISC)
                  </CheckboxField>

                  <CheckboxField
                    onChange={(value) => { setFieldValue("income_is_esa_or_529_distribution", value.target.checked == true) }}
                    value={values.income_is_esa_or_529_distribution === true}
                    name="income_is_esa_or_529_distribution">
                    ESA or 529 distribution (1099-Q)
                  </CheckboxField>

                  <CheckboxField
                    onChange={(value) => { setFieldValue("income_is_hsa_or_msa_distribution", value.target.checked == true) }}
                    value={values.income_is_hsa_or_msa_distribution === true}
                    name="income_is_hsa_or_msa_distribution">
                    HSA or MSA distribution (1099-SA)
                  </CheckboxField>

                  <CheckboxField
                    onChange={(value) => { setFieldValue("income_is_partnership", value.target.checked == true) }}
                    value={values.income_is_partnership === true}
                    name="income_is_partnership">
                    Partnership (Schedule K-1)
                  </CheckboxField>

                  <CheckboxField
                    onChange={(value) => { setFieldValue("income_is_s_corporation", value.target.checked == true) }}
                    value={values.income_is_s_corporation === true}
                    name="income_is_s_corporation">
                    S-corporation (Schedule K-1)
                  </CheckboxField>

                  <CheckboxField
                    onChange={(value) => { setFieldValue("income_is_estate_or_trust", value.target.checked == true) }}
                    value={values.income_is_estate_or_trust === true}
                    name="income_is_estate_or_trust">
                    Estate or trust (Schedule K-1)
                  </CheckboxField>

                  <CheckboxField
                    onChange={(value) => { setFieldValue("income_is_gambling", value.target.checked == true) }}
                    value={values.income_is_gambling === true}
                    name="income_is_gambling">
                    Gambling (W-2G)
                  </CheckboxField>

                  <CheckboxField
                    onChange={(value) => { setFieldValue("income_is_other_income", value.target.checked == true) }}
                    value={values.income_is_other_income === true}
                    name="income_is_other_income">
                    Other income (1099-MISC)
                  </CheckboxField>

                  <CheckboxField
                    onChange={(value) => { setFieldValue("income_is_farming", value.target.checked == true) }}
                    value={values.income_is_farming === true}
                    name="income_is_farming">
                    Farming
                  </CheckboxField>
                </Col>
              </Row>
              <div>
                <Button type="secondary" htmlType="button" onClick={handleBack}>
                  Back
                </Button>
                <Button type="primary" htmlType="submit" style={{ float: 'right' }}>
                  Continue
                </Button>
              </div>
              <Row>
                <Col span={24}>
                  <TextAreaInput
                    name="comment_income_sources"
                    label="Comment (optional)"
                    placeholder="Enter your comment here"
                  />
                </Col>
              </Row>
            </>
          )}
          {isConfirmation && (
            <ConfirmationPrompt
              submitForm={submitForm}
              setIsConfirmation={setIsConfirmation}
            >
              <h3>
                Your income sources:
              </h3>
              <ul>
                {previousData.income_is_employment && (
                <li>
                  Employment (W-2)
                </li>
                )}
                {previousData.income_is_self_employment && (
                <li>
                  Self-employment (1099-NEC or 1099-K)
                </li>
                )}
                {previousData.income_is_state_or_local_tax_refund && (
                <li>
                  State or local tax refund (1099-G)
                </li>
                )}
                {previousData.income_is_unemployment && (
                <li>
                  Unemployment (1099-G)
                </li>
                )}
                {previousData.income_is_interest && (
                <li>
                  Interest (1099-INT)
                </li>
                )}
                {previousData.income_is_dividends && (
                <li>
                  Dividends (1099-DIV)
                </li>
                )}
                {previousData.income_is_sold_stock_or_investments && (
                <li>
                  Sold stock or investments (1099-B)
                </li>
                )}
                {previousData.income_is_canceled_debt && (
                  <li>
                    Canceled debt (1099-C)
                  </li>
                )}
                {previousData.income_is_alimony && (
                  <li>
                    Canceled debt (1099-C)
                  </li>
                )}
                {previousData.income_is_rents && (
                <li>
                  Rents (1099-MISC)
                </li>
                )}
                {previousData.income_is_royalties && (
                <li>
                  Royalties (1099-MISC)
                </li>
                )}
                {previousData.income_is_esa_or_529_distribution && (
                <li>
                  ESA or 529 distribution (1099-Q)
                </li>
                )}
                {previousData.income_is_hsa_or_msa_distribution && (
                <li>
                  HSA or MSA distribution (1099-SA)
                </li>
                )}
                {previousData.income_is_partnership && (
                <li>
                  Partnership (Schedule K-1)
                </li>
                )}
                {previousData.income_is_s_corporation && (
                <li>

                  S-corporation (Schedule K-1)
                </li>
                )}
                {previousData.income_is_estate_or_trust && (
                <li>
                  Estate or trust (Schedule K-1)
                </li>
                )}
                {previousData.income_is_gambling && (
                <li>
                  Gambling (W-2G)
                </li>
                )}
                {previousData.income_is_other_income && (
                <li>
                  Other income (1099-MISC)
                </li>
                )}
                {previousData.income_is_farming && (
                <li>
                  Farming
                </li>
                )}
              </ul>
            </ConfirmationPrompt>
          )}
        </Form>
      )}
    </Formik>
  )
}
