import { useContext, useMemo, useState } from 'react';
import { Button, Tooltip, Typography } from 'antd';
import {
  signOutIcon,
} from 'assets/img';
import MenuItem from 'components/MenuItem';
import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import paths from 'routes/paths';
import styled from 'styled-components';
import { useLocation, useHistory } from 'react-router-dom';
import store from 'store';
import { FeatureContext, ToDoContext, UserDetailContext } from 'contexts';
import * as Sentry from '@sentry/react';
import { useVerifilinkRedirect } from 'hooks';
import { useApi, endpoints } from 'api';
import { InfoCircleFilled } from '@ant-design/icons';
import { ProgressBar } from '../../pages/Workflows/TaxOrganizer/ProgressBar';
import { useActions } from 'hooks/useAction';

const { Text } = Typography;

const isActive = (urlPath, routePath) => {
  if (urlPath === '/' || urlPath === '' || !urlPath) return false;

  return urlPath.includes(routePath);
};

function Menu({ hideMenu }) {
  const history = useHistory();
  const { userDetailInfo } = useContext(UserDetailContext);
  const { featureStatus } = useContext(FeatureContext);
  const [api] = useApi();


  const questions = useSelector((state) => state.taxOrganizer.questions);
  const currentQuestion = useSelector((state) => state.taxOrganizer.currentQuestion);
  const { setCurrentQuestion } = useActions();


  const clientDetails = (
    <Profile>
      <h3>{`${userDetailInfo.first_name || ''} ${userDetailInfo.last_name || ''
        }`}</h3>
      <h4>
        Case:{' '}
        <span className="color-brand">
          {userDetailInfo.case_id || ''}
        </span>
      </h4>
    </Profile>
  );


  const caseStatusDetails = (
    <CaseStatus>
      <h5 className="status-title">Case Status</h5>
      <div>
        <Text
          className="status-summary"
          type={featureStatus.status_severity}
        >
          {featureStatus.status_summary}
        </Text>
        <Tooltip placement="right" title={featureStatus.status_description}>
          <InfoIcon />
        </Tooltip>
      </div>
      <Button
        type="link"
        onClick={() => history.push(paths.CASE_STATUS)}
        className="button"
      >
        More Information →
      </Button>
    </CaseStatus>
  );

  const doClearStore = () => {
    // Clear localStorage
    store.clearAll();

    // Remove Sentry user scope
    Sentry.configureScope((scope) => scope.setUser(null));

    // Redirect to Login screen
    history.push(paths.LOGIN);

    // Hide mobile menu drawer
    if (hideMenu) hideMenu();
  }


  const signOutUser = () => {
    const logout = async () => {
      try {
        const req = await api({
          url: endpoints.INVALIDATE_TOKEN,
          method: 'POST'
        });
        const res = await req.data;
        if (res) {
          doClearStore();
        }
      } catch (error) {
        console.log({ error });
        doClearStore();
      }
    };
    logout();
  };


  return (
    <Fragment>
      {clientDetails}
      {caseStatusDetails}
      <MenuWrapper>
        <ProgressBar questions={questions} setCurrentQuestion={setCurrentQuestion} currentQuestion={currentQuestion} />
      </MenuWrapper>

      <SignOutButton onClick={signOutUser}>
        <img src={signOutIcon} alt="" />
        <span>Sign out</span>
      </SignOutButton>
    </Fragment>
  );
}

const Profile = styled.div`
    padding: 22px 30px 31px;
    width: 100%;
    border-bottom: 1px solid #e0e0e0;

    h3 {
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 27px;
        color: #0f212e;
        margin: 0;
    }
    h4 {
        margin: 0;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #0f212e;
    }
`;

const CaseStatus = styled.div`
    padding: 18px 30px;
    width: 100%;
    border-bottom: 1px solid #e0e0e0;
  
  .status-title {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    margin: 0;
    color: #0F212E;
  }
  
  .status-summary {
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 14px;
  }
  
  .button {
    padding: 0;

    & > span {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      text-decoration-line: underline;
      color: #0F212E;
    }
  }
`;

const InfoIcon = styled(InfoCircleFilled)`
  color: #6A7581;
  margin: 8px;
  font-size: 12px;
`;

const MenuWrapper = styled.div`
    flex: 1;
    padding: 40px 24px;

    ul {
        padding: 0;
        list-style: none;
    }
`;

const SignOutButton = styled.p`
    img {
        width: 20px;
        margin-right: 10px;
    }

    background-color: transparent;
    border: 0;
    color: #333333;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    margin-left: 24px;
    margin-bottom: 40px;
    cursor: pointer;
    padding: 0;

    &:hover {
        color: ${({ theme }) => theme.primaryColor};
    }
`;

export default Menu;
