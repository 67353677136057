import { endpoints, useApi } from 'api';
import { useEffect, useState } from 'react';

const useFetchBanks = () => {
    const [banksList, updateBanksList] = useState([]);
    const [loading, toggleLoading] = useState(false);

    const [api] = useApi();

    useEffect(() => {
        const fetchTransactions = async () => {
            try {
                toggleLoading(true);
                const req = await api({
                    url: endpoints.PLAID_BANKS,
                });

                const res = await req.data;

                if (Array.isArray(res) && res.length) {
                    updateBanksList(res);
                }
                toggleLoading(false);
            } catch (error) {
                console.log({ error });
                toggleLoading(false);
            }
        };

        fetchTransactions();
    }, [api]);

    return [banksList, loading];
};

export default useFetchBanks;
