export const setQuestions = (questions) => ({
  type: 'SET_QUESTIONS',
  payload: questions,
});

export const setCurrentQuestion = (questionKey) => ({
  type: 'SET_CURRENT_QUESTION',
  payload: questionKey,
});

