import { Button, Divider, Row, Col } from 'antd';
import { TextInput, DateInput, TextAreaInput, RadioGroup } from 'components/FormFields';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { ConfirmationPrompt } from '../ConfirmationPrompt';
import { useState, useEffect } from 'react';


export function SpouseInfo({ data, previousData, handleSubmit, handleBack }) {
  const initialValues = {
    spouse_first_name: data.spouse_first_name || previousData.spouse_first_name || '',
    spouse_last_name: data.spouse_last_name || previousData.spouse_last_name || '',
    spouse_ssn: data.spouse_ssn || previousData.spouse_ssn || '',
    spouse_date_of_birth: data.spouse_date_of_birth || previousData.spouse_date_of_birth || '',
    spouse_date_of_divorce: data.spouse_date_of_divorce || previousData.spouse_date_of_divorce || '',
    spouse_date_of_marriage: data.spouse_date_of_marriage || previousData.spouse_date_of_marriage || '',
    spouse_date_of_death: data.spouse_date_of_death || previousData.spouse_date_of_death || '',
    comment_personal_spouse_info: data.comment_personal_spouse_info || previousData.comment_personal_spouse_info || '',
    spouse_deduction_type: data.spouse_deduction_type || previousData.spouse_deduction_type || 'Standard',

  }

  const validationObject = {
    spouse_first_name: Yup.string().required('Required'),
    spouse_last_name: Yup.string().required('Required'),
    spouse_ssn: Yup.string()
      .required('Required')
      .test('ssn', 'Invalid SSN format. It should be XXX-XX-XXXX', value => {
        const ssnPattern = /^[0-9]{3}-[0-9]{2}-[0-9]{4}$/;
        return ssnPattern.test(value);
      }),
  }

  const MARRIEGA_DATES_REQUIRED_FILING_STATUS = [
    'Married Filing Jointly',
    'Married Filing Separately',
  ]

  const showMerriageDates = MARRIEGA_DATES_REQUIRED_FILING_STATUS.includes(data.filing_status);

  if (showMerriageDates) {
    validationObject.spouse_date_of_marriage = Yup.string().required('Required');
    validationObject.spouse_date_of_birth = Yup.string().required('Required');
  }

  if (data.filing_status === 'Married Filing Separate') {
    validationObject.spouse_deduction_type = Yup.string().required('Required');
  };

  if (data.filing_status === 'Qualifying Widow(er)') {
    validationObject.spouse_date_of_death = Yup.string().required('Required');
  };

  const validationSchema = Yup.object().shape(validationObject);

  const [isConfirmation, setIsConfirmation] = useState(true);

  useEffect(() => {
    setIsConfirmation(!data.spouse_first_name && !!previousData.spouse_first_name)
  }, [data, previousData]);

  const SPOUSE_DEDUCTION_OPTIONS = [
    {
      label: 'Standard',
      value: 'Standard',
    },
    {
      label: 'Itemized',
      value: 'Itemized',
    },
  ];

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnMount
      validateOnChange
      enableReinitialize>
      {({ setFieldValue, values, submitForm }) => (
        <Form>
          {!isConfirmation && (
            <>
              <Divider orientation="left" orientationMargin="0" style={{ color: '#6A7581' }}>
                Spouse Details
              </Divider>
              <TextInput
                required
                name="spouse_first_name"
                label="First Name" />
              <TextInput
                required
                name="spouse_last_name"
                label="Last Name" />
              <TextInput
                required
                name="spouse_ssn"
                label="Spouse SSN"
                onChange={(event) => {
                  const { value } = event.target;
                  const ssn = value.replace(/\D/g, '').slice(0, 9);
                  let formattedSSN = ssn;
                  if (ssn.length > 5) {
                    formattedSSN = `${ssn.slice(0, 3)}-${ssn.slice(3, 5)}-${ssn.slice(5)}`;
                  } else if (ssn.length > 3) {
                    formattedSSN = `${ssn.slice(0, 3)}-${ssn.slice(3)}`;
                  }
                  setFieldValue("spouse_ssn", formattedSSN);
                }}
              />

              {showMerriageDates && (
                <>
                  <DateInput
                    required
                    onChange={(value, valueStr) => { setFieldValue("spouse_date_of_birth", valueStr) }}
                    value={values.spouse_date_of_birth}
                    name="spouse_date_of_birth"
                    label="Spouse Date of Birth" />
                  <DateInput
                    required
                    onChange={(value, valueStr) => { setFieldValue("spouse_date_of_marriage", valueStr) }}
                    value={values.spouse_date_of_marriage}
                    name="spouse_date_of_marriage"
                    label="Spouse Date of Marriage" />
                  <DateInput
                    onChange={(value, valueStr) => { setFieldValue("spouse_date_of_divorce", valueStr) }}
                    value={values.spouse_date_of_divorce}
                    name="spouse_date_of_divorce"
                    label="Spouse Date of Divorce (optional)" />
                </>
              )}

              {data.filing_status === 'Married Filing Separately' && (
                <RadioGroup
                  required
                  name="spouse_deduction_type"
                  label="Does your spouse use Itemized or Standardized deductions?" 
                  options={SPOUSE_DEDUCTION_OPTIONS}
                  />
              )}

              {data.filing_status === 'Qualifying Widow(er)' && (
                <DateInput
                  required
                  onChange={(value, valueStr) => { setFieldValue("spouse_date_of_death", valueStr) }}
                  value={values.spouse_date_of_death}
                  name="spouse_date_of_death"
                  label="Spouse Date of Death" />
              )}

              <div>
                <Button type="secondary" htmlType="button" onClick={handleBack}>
                  Back
                </Button>
                <Button type="primary" htmlType="submit" style={{ float: 'right' }}>
                  Continue
                </Button>
              </div>
              <Row>
                <Col span={24}>
                  <TextAreaInput
                    name="comment_personal_spouse_info"
                    label="Comment (optional)"
                    placeholder="Enter your comment here"
                  />
                </Col>
              </Row>
            </>
          )}
          {isConfirmation && (
            <ConfirmationPrompt
              submitForm={submitForm}
              setIsConfirmation={setIsConfirmation}
            >
              <p>
                Spouse First Name: <b>{previousData.spouse_first_name}</b>
              </p>
              <p>
                Spouse Last Name: <b>{previousData.spouse_last_name}</b>
              </p>
              <p>
                Spouse SSN: <b>{previousData.spouse_ssn}</b>
              </p>
              <p>
                Spouse Date of Birth: <b>{previousData.spouse_date_of_birth}</b>
              </p>
              <p>
                Spouse Date of Marriage: <b>{previousData.spouse_date_of_marriage}</b>
              </p>
              <p>
                Spouse Date of Divorce: <b>{previousData.spouse_date_of_divorce}</b>
              </p>
            </ConfirmationPrompt>
          )}
        </Form>
      )}
    </Formik>
  )
}
