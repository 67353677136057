import { Button, Row, Col, Divider } from 'antd';
import { TextInput, RadioGroup, AddressInput, TextAreaInput } from 'components/FormFields';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { ConfirmationPrompt } from '../ConfirmationPrompt';
import { BOOLEAN_OPTIONS } from 'const';

export function SoleProprietorshipGeneral({ data, previousData, handleSubmit, handleBack }) {
  const initialValues = {
    sole_proprietorship_business_name: data.sole_proprietorship_business_name || previousData.sole_proprietorship_business_name || '',
    sole_proprietorship_business_activity: data.sole_proprietorship_business_activity || previousData.sole_proprietorship_business_activity || '',
    sole_proprietorship_business_ein: data.sole_proprietorship_business_ein || previousData.sole_proprietorship_business_ein || '',
    sole_proprietorship_business_started_or_acquired: data.sole_proprietorship_business_started_or_acquired || previousData.sole_proprietorship_business_started_or_acquired || '',
    sole_proprietorship_business_disposed: data.sole_proprietorship_business_disposed || previousData.sole_proprietorship_business_disposed || '',
    sole_proprietorship_business_accounting_method: data.sole_proprietorship_business_accounting_method || previousData.sole_proprietorship_business_accounting_method || '',
    sole_proprietorship_business_have_employees: data.sole_proprietorship_business_have_employees || previousData.sole_proprietorship_business_have_employees || '',
    sole_proprietorship_business_same_address: data.sole_proprietorship_business_same_address || previousData.sole_proprietorship_business_same_address || '',
    sole_proprietorship_address: data.sole_proprietorship_address || previousData.sole_proprietorship_address || '',
    sole_proprietorship_street_address: data.sole_proprietorship_street_address || previousData.sole_proprietorship_street_address || '',
    sole_proprietorship_apartment: data.sole_proprietorship_apartment || previousData.sole_proprietorship_apartment || '',
    sole_proprietorship_city: data.sole_proprietorship_city || previousData.sole_proprietorship_city || '',
    sole_proprietorship_state: data.sole_proprietorship_state || previousData.sole_proprietorship_state || '',
    sole_proprietorship_zip: data.sole_proprietorship_zip || previousData.sole_proprietorship_zip || '',
    sole_proprietorship_country: data.sole_proprietorship_country || previousData.sole_proprietorship_country || '',
    comment_sole_proprietorship_general: data.comment_sole_proprietorship_general || previousData.comment_sole_proprietorship_general || '',
  }

  const parsePlace = (place, setFieldValue) => {
    if (place?.formatted_address) {
      setFieldValue('sole_proprietorship_address', place.formatted_address)
    }

    if (place?.address_components) {
      const types_map = {
        'street_number': 'sole_proprietorship_street_number',
        'route': 'sole_proprietorship_street',
        'locality': 'sole_proprietorship_city',
        'administrative_area_level_1': 'sole_proprietorship_state',
        'postal_code': 'sole_proprietorship_zip',
        'country': 'sole_proprietorship_country',
        'subpremise': 'sole_proprietorship_apartment',
      }
      const addressObject = {}

      place.address_components.forEach((item) => {
        const type = item.types.find((type) => Object.keys(types_map).includes(type));
        if (type) {
          setFieldValue(types_map[type], item.long_name)
          addressObject[types_map[type]] = item.long_name
        }
      })

      const street_address = [addressObject.sole_proprietorship_street_number, addressObject.sole_proprietorship_street].filter(Boolean).join(' ')

      setFieldValue('sole_proprietorship_street_address', street_address)
    }
  }

  const validationRules = {
    sole_proprietorship_business_name: Yup.string().required('Required'),
    sole_proprietorship_business_activity: Yup.string().required('Required'),
    sole_proprietorship_business_ein: Yup.string()
      .test('ssn', 'Invalid EIN format. It should be XX-XXXXXXX', value => {
        if (!value) {
          return true
        }
        const ssnPattern = /^[0-9]{2}-[0-9]{7}$/;
        return ssnPattern.test(value);
      }),
    sole_proprietorship_business_started_or_acquired: Yup.string().required('Required'),
    sole_proprietorship_business_disposed: Yup.string().required('Required'),
    sole_proprietorship_business_accounting_method: Yup.string().required('Required'),
    sole_proprietorship_business_have_employees: Yup.string().required('Required'),
    sole_proprietorship_business_same_address: Yup.string().required('Required'),
  }

  const ACCOUNTING_METHOD_OPTIONS = [
    { value: 'cash', label: 'Cash' },
    { value: 'accrual', label: 'Accrual' },
  ]

  // TODO improve validation rules

  const validationSchema = Yup.object().shape(validationRules);

  const [isConfirmation, setIsConfirmation] = useState(true);

  useEffect(() => {
    setIsConfirmation(!data.sole_proprietorship_business_name && !!previousData.sole_proprietorship_business_name)
  }, [data, previousData]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnMount
      validateOnChange
      enableReinitialize>
      {({ setFieldValue, values, submitForm }) => (
        <Form>
          {!isConfirmation && (
            <>
              <Divider orientation="left" orientationMargin="0" style={{ color: '#6A7581' }}>
                General Information
              </Divider>

              <Row>
                <Col span={24} style={{ paddingRight: 6 }}>
                  <TextInput
                    required
                    name="sole_proprietorship_business_name"
                    label="Business Name" />
                </Col>
              </Row>
              <Row>
                <Col span={12} style={{ paddingRight: 6 }} >
                  <TextInput
                    required
                    name="sole_proprietorship_business_activity" label="Business Activity" />
                </Col>
                <Col span={12} >
                  <TextInput
                    onChange={(event) => {
                      const { value } = event.target;
                      const ssn = value.replace(/\D/g, '').slice(0, 9);
                      let formattedSSN = ssn;
                      if (ssn.length > 2) {
                        formattedSSN = `${ssn.slice(0, 2)}-${ssn.slice(2)}`;
                      }
                      setFieldValue("sole_proprietorship_business_ein", formattedSSN);
                    }}
                    
                    name="sole_proprietorship_business_ein" label="EIN" />
                </Col>
              </Row>
              <Row>
                <Col span={24} >
                  <RadioGroup
                    required
                    options={BOOLEAN_OPTIONS}
                    label={`This business started or was acquired during ${data.period}?`}
                    name="sole_proprietorship_business_started_or_acquired"
                  />
                  <RadioGroup
                    required
                    options={BOOLEAN_OPTIONS}
                    label={`This business disposed during ${data.period}?`}
                    name="sole_proprietorship_business_disposed"
                  />
                  <RadioGroup
                    required
                    options={ACCOUNTING_METHOD_OPTIONS}
                    label={`Which accounting method did this business use?`}
                    name="sole_proprietorship_business_accounting_method"
                  />
                  <RadioGroup
                    required
                    options={BOOLEAN_OPTIONS}
                    label={`This business had employees during ${data.period}?`}
                    name="sole_proprietorship_business_have_employees"
                  />
                </Col>
              </Row>
              <Divider orientation="left" orientationMargin="0" style={{ color: '#6A7581' }}>
                Business Address
              </Divider>
              <Row>
                <Col span={24}>
                  <RadioGroup
                    required
                    options={BOOLEAN_OPTIONS}
                    label={`Is the business address the same as the taxpayer's home address?`}
                    name="sole_proprietorship_business_same_address"
                  />
                </Col>
              </Row>
              {values.sole_proprietorship_business_same_address === 'No' && (
                <>
                  <AddressInput
                    onChange={(place) => {
                      parsePlace(place, setFieldValue)
                    }}
                    name="sole_proprietorship_address" label="Address"
                    value={values.sole_proprietorship_address}
                  />

                  {values.sole_proprietorship_address && (
                    <>
                      <TextInput
                        required
                        name="sole_proprietorship_street_address"
                        label="Street Address" />
                      <TextInput
                        name="sole_proprietorship_apartment"
                        label="Apartment (optional)" />
                      <TextInput
                        required
                        name="sole_proprietorship_city"
                        label="City" />
                      <TextInput
                        required
                        name="sole_proprietorship_state"
                        label="State" />
                      <TextInput
                        required
                        name="sole_proprietorship_zip"
                        label="Zip" />
                      <TextInput
                        required
                        name="sole_proprietorship_country"
                        label="Country" />
                    </>
                  )}
                </>
              )}
              <div>
                <Button type="secondary" htmlType="button" onClick={handleBack}>
                  Back
                </Button>

                <Button type="primary" htmlType="submit" style={{ float: 'right' }}>
                  Continue
                </Button>
              </div>
              <Row>
                <Col span={24}>
                  <TextAreaInput
                    name="comment_sole_proprietorship_general"
                    label="Comment (optional)"
                    placeholder="Enter your comment here"
                  />
                </Col>
              </Row>
            </>
          )}

          {isConfirmation && (
            <ConfirmationPrompt
              submitForm={submitForm}
              setIsConfirmation={setIsConfirmation}>
              <>
                  <>
                    <p> Business Name: <b>{previousData.sole_proprietorship_business_name}</b> </p>
                    <p> Business Activity: <b>{previousData.sole_proprietorship_business_activity}</b> </p>
                    <p> EIN: <b>{previousData.sole_proprietorship_business_ein}</b> </p>
                    <p> This business started or was acquired during {data.period}: <b>{previousData.sole_proprietorship_business_started_or_acquired}</b> </p>
                    <p> This business disposed during {data.period}: <b>{previousData.sole_proprietorship_business_disposed}</b> </p>
                    <p> Which accounting method did this business use: <b>{previousData.sole_proprietorship_business_accounting_method}</b> </p>
                    <p> Is the business address the same as the taxpayer&apos;s home address: <b>{previousData.sole_proprietorship_business_same_address}</b> </p>
                    {previousData.sole_proprietorship_business_same_address === 'No' && (
                      <>
                        <p> Street Address: <b>{previousData.sole_proprietorship_street_address}</b> </p>
                        <p> Apartment: <b>{previousData.sole_proprietorship_apartment}</b> </p>
                        <p> City: <b>{previousData.sole_proprietorship_city}</b> </p>
                        <p> State: <b>{previousData.sole_proprietorship_state}</b> </p>
                        <p> Zip: <b>{previousData.sole_proprietorship_zip}</b> </p>
                        <p> Country: <b>{previousData.sole_proprietorship_country}</b> </p>
                      </>
                    )}
                  </>
              </>
            </ConfirmationPrompt>
          )}
        </Form>
      )}
    </Formik>
  )
}
