import { Button, Divider, Row, Col } from 'antd';
import { RadioGroup, TextAreaInput } from 'components/FormFields';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { BOOLEAN_OPTIONS } from 'const';
import { ConfirmationPrompt } from '../ConfirmationPrompt';
import { useState, useEffect } from 'react';
import TaxOrgAddtlQuestion from 'components/FormFields/TaxOrgAddtlQuestion';


export function OtherEvents({ data, previousData, handleSubmit, handleBack }) {

  const initialValues = {
    comment_questionnaire_other_events: data.comment_questionnaire_other_events || previousData.comment_questionnaire_other_events || '',
  }

  for (const question of data.additional_questions) {
    if (question.answer) {
      initialValues[question.id] = question.answer
    }
  }

  const yupValidationObject = {}

  for (const question of data.additional_questions) {
    yupValidationObject[question.id] = Yup.string().required('Required')
  }


  const validationSchema = Yup.object().shape(yupValidationObject);

  function addAnswersAndSubmitForm(answers){

      for (const question of data.additional_questions) {
        if (answers[question.id]) {
          question.answer = answers[question.id]
        }
      
    }
    handleSubmit({
      additional_questions: data.additional_questions, 
      comment_questionnaire_other_events: answers.comment_questionnaire_other_events
    })
  }


  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={addAnswersAndSubmitForm}
      validateOnMount
      validateOnChange
      enableReinitialize>
      {({ setFieldValue, values, submitForm }) => (
        <Form>
            <>
              <Divider orientation="left" orientationMargin="0" style={{ color: '#6A7581' }}>
                Other Events
              </Divider>

              {
                data.additional_questions && 
                data.additional_questions.map((data) => {
                      return <TaxOrgAddtlQuestion
                          	key={data.id}
                            name={data.id}
                          	id={data.id}
                          	question={data.question}
                          	type={data.type}
                          	options={data.options}
                            onChange={(val) => {setFieldValue(data.id, val)}}
                      	/>
                })
              }

              <div>
                <Button type="secondary" htmlType="button" onClick={handleBack}>
                  Back
                </Button>
                <Button type="primary" htmlType="submit" style={{ float: 'right' }}>
                  Continue
                </Button>
              </div>
              <Row>
                <Col span={24}>
                  <TextAreaInput
                    name="comment_questionnaire_other_events"
                    label="Comment (optional)"
                    placeholder="Enter your comment here"
                  />
                </Col>
              </Row>
            </>
        </Form>
      )}
    </Formik>
  )
}
