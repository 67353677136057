import { Button, Divider, Row, Col } from 'antd';
import { TextInput, DateInput, TextAreaInput } from 'components/FormFields';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { ConfirmationPrompt } from '../ConfirmationPrompt';
import { useState, useEffect } from 'react';
import _ from 'lodash';

export function IncomeDetails({ data, previousData, handleSubmit, handleBack }) {
  const initialValues = {
    income_alimony_amount: data.income_alimony_amount || previousData.income_alimony_amount || '',
    income_alimoni_payer_first_name: data.income_alimoni_payer_first_name || previousData.income_alimoni_payer_first_name || '',
    income_alimoni_payer_last_name: data.income_alimoni_payer_last_name || previousData.income_alimoni_payer_last_name || '',
    income_alimoni_payer_ssn: data.income_alimoni_payer_ssn || previousData.income_alimoni_payer_ssn || '',
    spouse_date_of_divorce: data.spouse_date_of_divorce || previousData.spouse_date_of_divorce || '',
    comment_income_details: data.comment_income_details || previousData.comment_income_details || '',
  }

  const validationObject = {}

  if (data.income_is_alimony) {
    validationObject.income_alimony_amount = Yup.number().required('Required')
    validationObject.spouse_date_of_divorce = Yup.string().required('Required')
    validationObject.income_alimoni_payer_first_name = Yup.string().required('Required')
    validationObject.income_alimoni_payer_last_name = Yup.string().required('Required')
    validationObject.income_alimoni_payer_ssn = Yup.string()
      .required('Required')
      .test('ssn', 'Invalid SSN format. It should be XXX-XX-XXXX', value => {
        const ssnPattern = /^[0-9]{3}-[0-9]{2}-[0-9]{4}$/;
        return ssnPattern.test(value);
      })
  }

  const validationSchema = Yup.object().shape(validationObject);

  const [isConfirmation, setIsConfirmation] = useState(true);

  useEffect(() => {
    const dataWithInitialKeys = _.pick(data, ['income_alimony_amount']);

    const previousDataWithInitialKeys = _.pick(previousData, ['income_alimony_amount']);

    setIsConfirmation(_.keys(dataWithInitialKeys).length === 0 && _.keys(previousDataWithInitialKeys).length > 0)
  }, [data, previousData]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnMount
      validateOnChange
      enableReinitialize>
      {({ setFieldValue, values, submitForm }) => (
        <Form>
          {!isConfirmation && (
            <>
              {data.income_is_alimony && (
                <>
                  <Divider orientation="left" orientationMargin="0" style={{ color: '#6A7581' }}>
                    Alimony Income
                  </Divider>

                  <Row>
                    <Col span={12} style={{ paddingRight: 8 }}>
                      <TextInput
                        required
                        name="income_alimoni_payer_first_name"
                        label="Payer First Name" />
                    </Col>
                    <Col span={12} style={{ paddingRight: 8 }}>

                      <TextInput
                        required
                        name="income_alimoni_payer_last_name"
                        label="Payer Last Name" />
                    </Col>
                    <Col span={24} style={{ paddingRight: 8 }}>

                      <TextInput
                        required
                        name="income_alimoni_payer_ssn"
                        label="Payer SSN"
                        onChange={(event) => {
                          const { value } = event.target;
                          const ssn = value.replace(/\D/g, '').slice(0, 9);
                          let formattedSSN = ssn;
                          if (ssn.length > 5) {
                            formattedSSN = `${ssn.slice(0, 3)}-${ssn.slice(3, 5)}-${ssn.slice(5)}`;
                          } else if (ssn.length > 3) {
                            formattedSSN = `${ssn.slice(0, 3)}-${ssn.slice(3)}`;
                          }
                          setFieldValue("income_alimoni_payer_ssn", formattedSSN);
                        }}
                      />
                    </Col>

                    <Col span={12} style={{ paddingRight: 8 }}>
                      <DateInput
                        required
                        onChange={(value, valueStr) => { setFieldValue("spouse_date_of_divorce", valueStr) }}
                        value={values.spouse_date_of_divorce}
                        name="spouse_date_of_divorce"
                        label="Date of divorce" />
                    </Col>
                    <Col span={12}>
                      <TextInput
                        required
                        type="number"
                        prefix="$"
                        name="income_alimony_amount"
                        label={`Amount received in ${data.period}`} />
                    </Col>
                  </Row>
                </>
              )}

              <div>
                <Button type="secondary" htmlType="button" onClick={handleBack}>
                  Back
                </Button>
                <Button type="primary" htmlType="submit" style={{ float: 'right' }}>
                  Continue
                </Button>
              </div>
              <Row>
                <Col span={24}>
                  <TextAreaInput
                    name="comment_income_details"
                    label="Comment (optional)"
                    placeholder="Enter your comment here"
                  />
                </Col>
              </Row>
            </>
          )}
          {isConfirmation && (
            <ConfirmationPrompt
              submitForm={submitForm}
              setIsConfirmation={setIsConfirmation}
            >
              {previousData.income_is_alimony && (
                <>
                  <p>
                    Date of divorce: <b>{previousData.spouse_date_of_divorce}</b>
                  </p>
                  <p>
                    Alimony Income: <b>{previousData.income_alimony_amount}</b>
                  </p>
                </>
              )}
            </ConfirmationPrompt>
          )}
        </Form>)}
    </Formik>
  )
}
