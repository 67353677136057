import styled from 'styled-components';

export const Card = styled.div`
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 0px 30px 30px 30px;
  margin: 30px;
  background-color: white;
  box-shadow: 0 0 5px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  &:hover {
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);
  }
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0px;
  border-bottom: 1px solid lightgray;
`;


