import styled from 'styled-components';

function ContentWrap({ children, ...props }) {
    return <Wrapper {...props}>{children}</Wrapper>;
}

const Wrapper = styled.div`
    max-width: ${(props) => props.width || "600px"};
    margin: 16px auto;
    width: 100%;
`;

export default ContentWrap;
