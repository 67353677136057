import { Button, Row, Col, Divider } from 'antd';
import { TextInput, TextAreaInput, RadioGroup } from 'components/FormFields';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { BOOLEAN_OPTIONS } from 'const';
import { ConfirmationPrompt } from '../ConfirmationPrompt';

export function SoleProprietorshipHome({ data, previousData, handleSubmit, handleBack }) {
  const initialValues = {
    sole_proprietorship_is_home_used: data.sole_proprietorship_is_home_used || previousData.sole_proprietorship_is_home_used || '',
    sole_proprietorship_home_area_total: data.sole_proprietorship_home_area_total || previousData.sole_proprietorship_home_area_total || '',
    sole_proprietorship_home_area_business: data.sole_proprietorship_home_area_business || previousData.sole_proprietorship_home_area_business || '',
    sole_proprietorship_home_expenses_mortgage_interest_total: data.sole_proprietorship_home_expenses_mortgage_interest_total || previousData.sole_proprietorship_home_expenses_mortgage_interest_total || '',
    sole_proprietorship_home_expenses_mortgage_interest_business: data.sole_proprietorship_home_expenses_mortgage_interest_business || previousData.sole_proprietorship_home_expenses_mortgage_interest_business || '',
    sole_proprietorship_home_expenses_property_taxes_total: data.sole_proprietorship_home_expenses_property_taxes_total || previousData.sole_proprietorship_home_expenses_property_taxes_total || '',
    sole_proprietorship_home_expenses_property_taxes_business: data.sole_proprietorship_home_expenses_property_taxes_business || previousData.sole_proprietorship_home_expenses_property_taxes_business || '',
    sole_proprietorship_home_expenses_utilities_total: data.sole_proprietorship_home_expenses_utilities_total || previousData.sole_proprietorship_home_expenses_utilities_total || '',
    sole_proprietorship_home_expenses_utilities_business: data.sole_proprietorship_home_expenses_utilities_business || previousData.sole_proprietorship_home_expenses_utilities_business || '',
    sole_proprietorship_home_expenses_repairs_total: data.sole_proprietorship_home_expenses_repairs_total || previousData.sole_proprietorship_home_expenses_repairs_total || '',
    sole_proprietorship_home_expenses_repairs_business: data.sole_proprietorship_home_expenses_repairs_business || previousData.sole_proprietorship_home_expenses_repairs_business || '',
    sole_proprietorship_home_expenses_other_total: data.sole_proprietorship_home_expenses_other_total || previousData.sole_proprietorship_home_expenses_other_total || '',
    sole_proprietorship_home_expenses_other_business: data.sole_proprietorship_home_expenses_other_business || previousData.sole_proprietorship_home_expenses_other_business || '',
    sole_proprietorship_home_expenses_insurance_total: data.sole_proprietorship_home_expenses_insurance_total || previousData.sole_proprietorship_home_expenses_insurance_total || '',
    sole_proprietorship_home_expenses_insurance_business: data.sole_proprietorship_home_expenses_insurance_business || previousData.sole_proprietorship_home_expenses_insurance_business || '',
    sole_proprietorship_home_expenses_rent_total: data.sole_proprietorship_home_expenses_rent_total || previousData.sole_proprietorship_home_expenses_rent_total || '',
    sole_proprietorship_home_expenses_rent_business: data.sole_proprietorship_home_expenses_rent_business || previousData.sole_proprietorship_home_expenses_rent_business || '',
    comment_sole_proprietorship_home: data.comment_sole_proprietorship_home || previousData.comment_sole_proprietorship_home || '',
  }

  const validationSchema = Yup.object().shape({
    sole_proprietorship_is_home_used: Yup.string().required('Required'),
  });

  const [isConfirmation, setIsConfirmation] = useState(true);

  useEffect(() => {
    setIsConfirmation(!data.first_name && !!previousData.first_name)
  }, [data, previousData]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      validateOnMount
      validateOnChange
      enableReinitialize>
      {({ setFieldValue, values, submitForm }) => (
        <Form>
          {!isConfirmation && (
            <>
              <Divider orientation="left" orientationMargin="0" style={{ color: '#6A7581' }}>
                Home Office
              </Divider>

              <Row>
                <Col span={24}>
                  <RadioGroup
                    required
                    name="sole_proprietorship_is_home_used"
                    label="Is your home used for business?"
                    options={BOOLEAN_OPTIONS}
                  />
                </Col>
              </Row>
              {values.sole_proprietorship_is_home_used === 'Yes' && (
                <>
                  <Row>
                    <Col span={12} style={{ paddingRight: 8 }}>
                      <TextInput
                        name="sole_proprietorship_home_area_total"
                        label="Total area of home (sq. ft.)"
                        placeholder="Enter total area of home"
                        type="number"
                      />
                    </Col>
                    <Col span={12}>
                      <TextInput
                        name="sole_proprietorship_home_area_business"
                        label="Area of home used for business (sq. ft.)"
                        placeholder="Enter area of home used for business"
                        type="number"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12} style={{ paddingRight: 8 }}>
                      <TextInput
                        name="sole_proprietorship_home_expenses_mortgage_interest_total"
                        label="Mortgage interest paid on home"
                        placeholder="Enter mortgage interest paid on home"
                        type="number"
                        prefix="$"
                      />
                    </Col>
                    <Col span={12}>
                      <TextInput
                        name="sole_proprietorship_home_expenses_mortgage_interest_business"
                        label="Mortgage interest paid on home used for business"
                        placeholder="Enter mortgage interest paid on home used for business"
                        type="number"
                        prefix="$"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12} style={{ paddingRight: 8 }}>
                      <TextInput
                        name="sole_proprietorship_home_expenses_property_taxes_total"
                        label="Property taxes paid on home"
                        placeholder="Enter property taxes paid on home"
                        type="number"
                        prefix="$"
                      />
                    </Col>
                    <Col span={12}>
                      <TextInput
                        name="sole_proprietorship_home_expenses_property_taxes_business"
                        label="Property taxes paid on home used for business"
                        placeholder="Enter property taxes paid on home used for business"
                        type="number"
                        prefix="$"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12} style={{ paddingRight: 8 }}>
                      <TextInput
                        name="sole_proprietorship_home_expenses_utilities_total"
                        label="Utilities paid on home"
                        placeholder="Enter utilities paid on home"
                        type="number"
                        prefix="$"
                      />
                    </Col>
                    <Col span={12}>
                      <TextInput
                        name="sole_proprietorship_home_expenses_utilities_business"
                        label="Utilities paid on home used for business"
                        placeholder="Enter utilities paid on home used for business"
                        type="number"
                        prefix="$"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12} style={{ paddingRight: 8 }}>
                      <TextInput
                        name="sole_proprietorship_home_expenses_repairs_total"
                        label="Repairs paid on home"
                        placeholder="Enter repairs paid on home"
                        type="number"
                        prefix="$"
                      />
                    </Col>
                    <Col span={12}>
                      <TextInput
                        name="sole_proprietorship_home_expenses_repairs_business"
                        label="Repairs paid on home used for business"
                        placeholder="Enter repairs paid on home used for business"
                        type="number"
                        prefix="$"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12} style={{ paddingRight: 8 }}>
                      <TextInput
                        name="sole_proprietorship_home_expenses_insurance_total"
                        label="Insurance paid on home"
                        placeholder="Enter insurance paid on home"
                        type="number"
                        prefix="$"
                      />
                    </Col>
                    <Col span={12}>
                      <TextInput
                        name="sole_proprietorship_home_expenses_insurance_business"
                        label="Insurance paid on home used for business"
                        placeholder="Enter insurance paid on home used for business"
                        type="number"
                        prefix="$"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12} style={{ paddingRight: 8 }}>
                      <TextInput
                        name="sole_proprietorship_home_expenses_other_total"
                        label="Other expenses paid on home"
                        placeholder="Enter other expenses paid on home"
                        type="number"
                        prefix="$"
                      />
                    </Col>
                    <Col span={12}>
                      <TextInput

                        name="sole_proprietorship_home_expenses_other_business"
                        label="Other expenses paid on home used for business"
                        placeholder="Enter other expenses paid on home used for business"
                        type="number"
                        prefix="$"
                      />
                    </Col>
                  </Row>
                </>
              )}
              <div>
                <Button type="secondary" htmlType="button" onClick={handleBack}>
                  Back
                </Button>

                <Button type="primary" htmlType="submit" style={{ float: 'right' }}>
                  Continue
                </Button>
              </div>
              <Row>
                <Col span={24}>
                  <TextAreaInput
                    name="comment_sole_proprietorship_home"
                    label="Comment (optional)"
                    placeholder="Enter your comment here"
                  />
                </Col>
              </Row>
            </>
          )}

          {isConfirmation &&
            <ConfirmationPrompt
              submitForm={submitForm}
              setIsConfirmation={setIsConfirmation}>
              <Row>
                <Col span={24} >
                  <p>
                    Is your home used for business: <b>{previousData.sole_proprietorship_is_home_used}</b>
                  </p>

                  {previousData.sole_proprietorship_is_home_used === 'Yes' && (
                    <>
                      <p>
                        Total area of home (sq. ft.): <b>{previousData.sole_proprietorship_home_area_total}</b>
                      </p>

                      <p>
                        Area of home used for business (sq. ft.): <b>{previousData.sole_proprietorship_home_area_business}</b>
                      </p>

                      <p>
                        Mortgage interest paid on home: <b>{previousData.sole_proprietorship_home_expenses_mortgage_interest_total}</b>
                      </p>

                      <p>
                        Mortgage interest paid on home used for business: <b>{previousData.sole_proprietorship_home_expenses_mortgage_interest_business}</b>
                      </p>

                      <p>
                        Property taxes paid on home: <b>{previousData.sole_proprietorship_home_expenses_property_taxes_total}</b>
                      </p>

                      <p>
                        Property taxes paid on home used for business: <b>{previousData.sole_proprietorship_home_expenses_property_taxes_business}</b>
                      </p>

                      <p>
                        Utilities paid on home: <b>{previousData.sole_proprietorship_home_expenses_utilities_total}</b>
                      </p>

                      <p>
                        Utilities paid on home used for business: <b>{previousData.sole_proprietorship_home_expenses_utilities_business}</b>
                      </p>

                      <p>
                        Repairs paid on home: <b>{previousData.sole_proprietorship_home_expenses_repairs_total}</b>

                      </p>

                      <p>
                        Repairs paid on home used for business: <b>{previousData.sole_proprietorship_home_expenses_repairs_business}</b>
                      </p>

                      <p>
                        Insurance paid on home: <b>{previousData.sole_proprietorship_home_expenses_insurance_total}</b>
                      </p>

                      <p>
                        Insurance paid on home used for business: <b>{previousData.sole_proprietorship_home_expenses_insurance_business}</b>
                      </p>

                      <p>
                        Other expenses paid on home: <b>{previousData.sole_proprietorship_home_expenses_other_total}</b>
                      </p>

                      <p>
                        Other expenses paid on home used for business: <b>{previousData.sole_proprietorship_home_expenses_other_business}</b>
                      </p>
                    </>
                  )}
                </Col>
              </Row>
            </ConfirmationPrompt>}
        </Form>
      )}
    </Formik>
  )
}
