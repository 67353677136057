import styled from 'styled-components';
import { FormattedText } from 'utilities';

function Version(props) {
    return (
        <VersionText className="text-center">
            <FormattedText id="app.brand" defaultValue="Taxrise" /> Client
            Portal V1.0
        </VersionText>
    );
}

const VersionText = styled.p`
    //margin-bottom: 0;
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
    letter-spacing: 0.2em;
    margin-bottom: 100px;
`;

export default Version;
