import React, { useState } from 'react';
import { Formik } from 'formik';
import { TextInput } from 'components/FormFields';
import Button from 'components/Button';
import { Link, useHistory } from 'react-router-dom';
import { Divider } from 'antd';
import styled from 'styled-components';
import * as Yup from 'yup';
import { endpoints } from 'api';
import paths from 'routes/paths';
import store from 'store';
import { PageHeader } from 'components/Header';
import Version from 'components/Version';
import Message from 'components/Message';
import { ContentWrap } from 'components/Layout';
import { useApi } from 'api';
import { FormattedText } from 'utilities';
import 'url-search-params-polyfill';
import Login from "./Login";

const validationRules = Yup.object().shape({
    email: Yup.string().email()
        .typeError('Invalid email')
        .required('Please enter your email address'),
});

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 122px);

  form {
    @media (max-width: 767px) {
      padding: 0 32px;
    }
  }
`;

const LoginPortalRedirect = () => {
    const [initialValues, updateInitialValues] = useState({
        email: ''
    });
    const history = useHistory();

    const [api] = useApi(false);

    const [errorMsg, setErrorMsg] = useState();
    const [showErrMsg, hideErrMsg] = useState(false);
    const [redirectToLogin, setRedirectToLogin] = useState(false);

    const handleRedirect = (redirectData) => {
        if (redirectData.is_salesforce_client) {
            window.location.href = `https://taxrise.my.site.com/s/login/?username=${redirectData.username}`;
        } else {
            history.push(`/login/?case_id=${redirectData.case_id}`);
        }
    };

    const checkIfSalesforceClient = async (data) => {
        const { email } = data;
        try {

            const endpoint = `${endpoints.SALESFORCE_CLIENT_CHECK}?email=${email}`;

            // If you're testing this locally uncomment this string and substitute it into the request
            // also make the baseURL in useApi.js an empty string
            // const runningAuthServiceLocallyEndpoint = `http://localhost:8005/api/v1/cses/?email=${email}`;

            const req = await api.get(endpoint, { headers: {'no-auth': 'true'}});

            const redirectData = req.data;

            handleRedirect(redirectData)

        } catch (error) {
            console.log(error);
            const { data } = error.response;
            const result = JSON.parse(data.detail);
            const { msg, is_email_verified, is_phone_verified } = result;
            setErrorMsg(msg);
            hideErrMsg(true);
            if (!is_email_verified && is_email_verified !== undefined && !is_phone_verified && is_phone_verified !== undefined) {
                store.set('email', email);
                store.set('key', 'only_resend');
                setTimeout(() => history.push(paths.SEND_OTP), 5000);
            }
        }
    };

    return (
        <Wrapper>
            <section style={{ flex: 1 }}>
                <PageHeader>
                    <h1 className="mb-8">
                        <FormattedText
                            id="redirect-message"
                            defaultMessage="Please enter your email address to be directed to the login portal"
                        />
                    </h1>
                </PageHeader>
                <ContentWrap width="490px">
                    {redirectToLogin ? <Login /> : (
                        <Formik
                            initialValues={initialValues}
                            onSubmit={checkIfSalesforceClient}
                            validationSchema={validationRules}
                            validateOnMount
                            enableReinitialize
                        >
                            {({ isValid, handleSubmit, isSubmitting }) => (
                                <form onSubmit={handleSubmit}>
                                    {showErrMsg === true && <Message message={errorMsg} />}
                                    <TextInput name="email" label="Email" />
                                    <Button
                                        block
                                        type="primary"
                                        htmlType="submit"
                                        className="mt-16"
                                        disabled={!isValid}
                                        onClick={handleSubmit}
                                        loading={isSubmitting}
                                    >
                                        Submit
                                    </Button>
                                </form>
                            )}
                        </Formik>
                    )}

                    <Divider plain>or</Divider>

                    <p className="text-center">Don’t have an account?{' '}</p>
                    <p className="text-center">
                        <Link to={paths.SIGN_UP} className="text-underline">
                            Sign Up
                        </Link>
                    </p>
                </ContentWrap>
            </section>
            <Version />
        </Wrapper>
    );
};

export default LoginPortalRedirect;
