export default function useDisplayServerErrors() {
    const handleErrors = (errorDetails, setErrors) => {
        const errors = {};

        if (Array.isArray(errorDetails)) {
            for (let i = 0; i < errorDetails.length; i++) {
                const { loc, msg } = errorDetails[i];
                errors[loc[1]] = msg;
            }
        }

        setErrors(errors);
    };

    return handleErrors;
}
