import { Button, Divider, Row, Col } from 'antd';
import { RadioGroup, CheckboxField, TextAreaInput } from 'components/FormFields';
import { Formik, Form } from 'formik';
import { BOOLEAN_OPTIONS } from 'const';
import * as Yup from 'yup';
import { ConfirmationPrompt } from '../ConfirmationPrompt';
import { useState, useEffect } from 'react';


export function ExpensesSummary({ data, previousData, handleSubmit, handleBack }) {
  const initialValues = {
    is_expenses_payed: data.is_expenses_payed || previousData.is_expenses_payed || '',
    expenses_is_adoption: data.expenses_is_adoption || previousData.expenses_is_adoption || '',
    expenses_is_alimony: data.expenses_is_alimony || previousData.expenses_is_alimony || '',
    expenses_is_dependent_care: data.expenses_is_dependent_care || previousData.expenses_is_dependent_care || '',
    expenses_is_educator: data.expenses_is_educator || previousData.expenses_is_educator || '',
    expenses_is_higher_education: data.expenses_is_higher_education || previousData.expenses_is_higher_education || '',
    expenses_is_household_worker: data.expenses_is_household_worker || previousData.expenses_is_household_worker || '',
    expenses_is_health_savings: data.expenses_is_health_savings || previousData.expenses_is_health_savings || '',
    expenses_is_retirement: data.expenses_is_retirement || previousData.expenses_is_retirement || '',
    expenses_is_student_loan: data.expenses_is_student_loan || previousData.expenses_is_student_loan || '',
    expenses_is_energy_efficiency: data.expenses_is_energy_efficiency || previousData.expenses_is_energy_efficiency || '',
    comment_expenses_summary: data.comment_expenses_summary || previousData.comment_expenses_summary || '',
  };

  const validationSchema = Yup.object().shape({
    is_expenses_payed: Yup.string().required('Required'),
  });

  const [isConfirmation, setIsConfirmation] = useState(true);

  useEffect(() => {
    setIsConfirmation(!data.is_expenses_payed && !!previousData.is_expenses_payed)
  }, [data, previousData]);


  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnMount
      validateOnChange
      enableReinitialize>
      {({ setFieldValue, values, submitForm }) => (
        <Form>
          {!isConfirmation && (
            <>
              <Divider orientation="left" orientationMargin="0" style={{ color: '#6A7581' }}>
                Specify your Expenses
              </Divider>
              <RadioGroup
                required
                options={BOOLEAN_OPTIONS}
                label={`Did you pay any of the following expenses during ${data.period}?`}
                name="is_expenses_payed"
              />

              {values.is_expenses_payed !== "Yes" && (
                <ul>
                  <li>
                    Adoption expenses
                  </li>
                  <li>
                    Alimony from a divorce finalized on or before 12/31/{data.period}
                  </li>
                  <li>
                    Child or dependent care expenses
                  </li>
                  <li>
                    Educator expenses
                  </li>
                  <li>
                    Higher education expenses
                  </li>
                  <li>
                    Household worker expenses
                  </li>
                  <li>
                    Contributions to a health savings account (HSA)
                  </li>
                  <li>
                    Contributions to an individual retirement arrangement (IRA)
                  </li>
                  <li>
                    Student loan interest
                  </li>
                  <li>
                    Energy-efficient home improvements
                  </li>
                </ul>
              )}
              {values.is_expenses_payed === "Yes" && (
                <>
                  <CheckboxField
                    onChange={(value) => { setFieldValue("expenses_is_adoption", value.target.checked == true) }}
                    value={values.expenses_is_adoption === true}
                    name="expenses_is_adoption">
                    Adoption expenses
                  </CheckboxField>

                  <CheckboxField
                    onChange={(value) => { setFieldValue("expenses_is_alimony", value.target.checked == true) }}
                    value={values.expenses_is_alimony === true}
                    name="expenses_is_alimony">
                    Alimony from a divorce finalized on or before 12/31/{data.period}
                  </CheckboxField>

                  <CheckboxField
                    onChange={(value) => { setFieldValue("expenses_is_dependent_care", value.target.checked == true) }}
                    value={values.expenses_is_dependent_care === true}
                    name="expenses_is_dependent_care">
                    Child or dependent care expenses
                  </CheckboxField>

                  <CheckboxField
                    onChange={(value) => { setFieldValue("expenses_is_educator", value.target.checked == true) }}
                    value={values.expenses_is_educator === true}
                    name="expenses_is_educator">
                    Educator expenses
                  </CheckboxField>

                  <CheckboxField
                    onChange={(value) => { setFieldValue("expenses_is_higher_education", value.target.checked == true) }}
                    value={values.expenses_is_higher_education === true}
                    name="expenses_is_higher_education">
                    Higher education expenses
                  </CheckboxField>

                  <CheckboxField
                    onChange={(value) => { setFieldValue("expenses_is_household_worker", value.target.checked == true) }}
                    value={values.expenses_is_household_worker === true}
                    name="expenses_is_household_worker">
                    Household worker expenses
                  </CheckboxField>

                  <CheckboxField
                    onChange={(value) => { setFieldValue("expenses_is_health_savings", value.target.checked == true) }}
                    value={values.expenses_is_health_savings === true}
                    name="expenses_is_health_savings">
                    Contributions to a health savings account (HSA)
                  </CheckboxField>

                  <CheckboxField
                    onChange={(value) => { setFieldValue("expenses_is_retirement", value.target.checked == true) }}
                    value={values.expenses_is_retirement === true}
                    name="expenses_is_retirement">
                    Contributions to an individual retirement arrangement (IRA)
                  </CheckboxField>

                  <CheckboxField
                    onChange={(value) => { setFieldValue("expenses_is_student_loan", value.target.checked == true) }}
                    value={values.expenses_is_student_loan === true}
                    name="expenses_is_student_loan">
                    Student loan interest
                  </CheckboxField>

                  <CheckboxField
                    onChange={(value) => { setFieldValue("expenses_is_energy_efficiency", value.target.checked == true) }}
                    value={values.expenses_is_energy_efficiency === true}
                    name="expenses_is_energy_efficiency">
                    Energy-efficient home improvements
                  </CheckboxField>
                </>
              )}

              <div>
                <Button type="secondary" htmlType="button" onClick={handleBack}>
                  Back
                </Button>
                <Button type="primary" htmlType="submit" style={{ float: 'right' }}>
                  Continue
                </Button>
              </div>
              <Row>
                <Col span={24}>
                  <TextAreaInput
                    name="comment_expenses_summary"
                    label="Comment (optional)"
                    placeholder="Enter your comment here"
                  />
                </Col>
              </Row>

            </>
          )}
          {isConfirmation && (
            <ConfirmationPrompt
              submitForm={submitForm}
              setIsConfirmation={setIsConfirmation}
            >
              <p>
                Additional Expenses: <b>{previousData.is_expenses_payed}</b>
              </p>

              {previousData.is_expenses_payed === "Yes" && (
                <ul>
                  {previousData.expenses_is_adoption && (
                    <li>
                      Adoption expenses
                    </li>
                  )}
                  {previousData.expenses_is_alimony && (
                    <li>
                      Alimony from a divorce finalized on or before 12/31/{data.period}
                    </li>
                  )}
                  {previousData.expenses_is_dependent_care && (
                    <li>
                      Child or dependent care expenses
                    </li>
                  )}
                  {previousData.expenses_is_educator && (
                    <li>
                      Educator expenses
                    </li>
                  )}
                  {previousData.expenses_is_higher_education && (
                    <li>
                      Higher education expenses
                    </li>
                  )}
                  {previousData.expenses_is_household_worker && (
                    <li>
                      Household worker expenses
                    </li>
                  )}
                  {previousData.expenses_is_health_savings && (
                    <li>
                      Contributions to a health savings account (HSA)
                    </li>
                  )}
                  {previousData.expenses_is_retirement && (
                    <li>
                      Contributions to an individual retirement arrangement (IRA)
                    </li>
                  )}
                  {previousData.expenses_is_student_loan && (
                    <li>
                      Student loan interest
                    </li>
                  )}
                  {previousData.expenses_is_energy_efficiency && (
                    <li>
                      Energy-efficient home improvements
                    </li>
                  )}
                </ul>
              )}
            </ConfirmationPrompt>
          )}
        </Form>
      )}
    </Formik>
  )
}
