import styled from 'styled-components';

function TaskInfo(props) {
    const { title, subTitle, status, addon } = props;
    return (
        <Section status={status}>
            <h3> {title} </h3>
            <p> {subTitle} </p>
            <div className="addon">{addon}</div>
        </Section>
    );
}

const Section = styled.div`
    padding: 16px;
    margin: 10px;
    background: #ffffff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    border-radius: 3px 4px 4px 3px;
    border-left: ${(props) =>
        props.status === 'Not started' ? '3px solid #F36D28' : '0'};

    h3 {
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        color: #0f212e;
        margin: 0;
        padding-bottom: 3px;
    }
    p {
        font-weight: 500;
        font-size: 10px;
        line-height: 15px;
        color: #6a7581;
        margin: 0;
        padding-bottom: 5px;
    }
    .addon {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        span {
            margin: 0;
            color: #6a7581;
            font-size: 12px;
            i {
                color: #f36d28;
            }
            b {
                color: #5aac5e;
                font-weight: 500;
                font-size: 12px;
                line-height: 18px;
            }
        }
        p,
        button {
            margin: 0;
            font-size: 12px;
            padding: 0;
            height: auto;
            font-weight: 500;

            img {
                width: 16px;
                margin-left: 8px;
            }
        }
        a {
            span {
                color: #0288d1;
            }
            img {
                margin-left: 8px;
                width: 15px;
            }
        }
    }
`;

export default TaskInfo;
