import { Drawer } from 'antd';
import Menu from './Menu';

const drawerBodyStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'baseline',
    padding: 0,
};

function MenuDrawer({ menuVisibility, hideMenu }) {
    return (
        <Drawer
            title={null}
            placement={'left'}
            closable={false}
            onClose={hideMenu}
            visible={menuVisibility}
            key={'menu-drawer'}
            bodyStyle={drawerBodyStyle}
        >
            <Menu hideMenu={hideMenu} />
        </Drawer>
    );
}

export default MenuDrawer;
