import { purgoTexts } from 'locale';
import { createIntl, createIntlCache } from 'react-intl';

const cache = createIntlCache();

const texts = {
    purgo: purgoTexts,
};

const appId = process.env['REACT_APP_PROJECT_ID'];

const intl = createIntl(
    {
        locale: 'en',
        messages: texts[appId],
    },
    cache
);

export default intl;
