
import { Button, Divider, Row, Col } from 'antd';
import { TextInput, AddressInput, TextAreaInput } from 'components/FormFields';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { ConfirmationPrompt } from '../ConfirmationPrompt';
import { useState, useEffect } from 'react';


export function Address({ data, previousData, handleSubmit, handleBack }) {
  const initialValues = {
    address: data.address || previousData.address || '',
    street_address: data.street_address || previousData.street_address || '',
    city: data.city || previousData.city || '',
    state: data.state || previousData.state || '',
    zip: data.zip || previousData.zip || '',
    country: data.country || previousData.country || '',
    apartment: data.apartment || previousData.apartment || '',
    comment_personal_address: data.comment_personal_address || previousData.comment_personal_address || '',
  }

  const validationSchema = Yup.object().shape({
    address: Yup.string().required('Required'),
    street_address: Yup.string().required('Required'),
    city: Yup.string().required('Required'),
    state: Yup.string().required('Required'),
    zip: Yup.string().required('Required'),
    country: Yup.string().required('Required'),
  });

  const parsePlace = (place, setFieldValue) => {
    if (place?.formatted_address) {
      setFieldValue('address', place.formatted_address)
    }

    if (place?.address_components) {
      const types_map = {
        'street_number': 'street_number',
        'route': 'street',
        'locality': 'city',
        'administrative_area_level_1': 'state',
        'postal_code': 'zip',
        'country': 'country',
        'subpremise': 'apartment',
      }
      const addressObject = {}

      place.address_components.forEach((item) => {
        const type = item.types.find((type) => Object.keys(types_map).includes(type));
        if (type) {
          setFieldValue(types_map[type], item.long_name)
          addressObject[types_map[type]] = item.long_name
        }
      })

      const street_address = [addressObject.street_number, addressObject.street].filter(Boolean).join(' ')

      setFieldValue('street_address', street_address)
    }
  }

  const [isConfirmation, setIsConfirmation] = useState(true);

  useEffect(() => {
    setIsConfirmation(!data.address && !!previousData.address)
  }, [data, previousData]);


  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnMount
      validateOnChange
      enableReinitialize>
      {({ setFieldValue, values, submitForm }) => (
        <Form>
          {!isConfirmation && (
            <>
              <Divider orientation="left" orientationMargin="0" style={{ color: '#6A7581' }}>
                Home Address
              </Divider>

              <AddressInput
                onChange={(place) => {
                  parsePlace(place, setFieldValue)
                }}
                name="address" label="Address"
                value={values.address}
              />

              {values.address && (
                <>
                  <TextInput
                    required
                    name="street_address"
                    label="Street Address" />
                  <TextInput
                    name="apartment"
                    label="Apartment (optional)" />
                  <TextInput
                    required
                    name="city"
                    label="City" />
                  <TextInput
                    required
                    name="state"
                    label="State" />
                  <TextInput
                    required
                    name="zip"
                    label="Zip" />
                  <TextInput
                    required
                    name="country"
                    label="Country" />
                </>
              )}
              <div>
                <Button type="secondary" htmlType="button" onClick={handleBack}>
                  Back
                </Button>

                <Button type="primary" htmlType="submit" style={{ float: 'right' }}>
                  Continue
                </Button>
              </div>

              <Row>
                <Col span={24}>
                  <TextAreaInput
                    name="comment_personal_address"
                    label="Comment (optional)"
                    placeholder="Enter your comment here"
                  />
                </Col>
              </Row>

            </>
          )}
          {isConfirmation && (
            <ConfirmationPrompt
              submitForm={submitForm}
              setIsConfirmation={setIsConfirmation}
            >
              <Divider orientation="left" orientationMargin="0" style={{ color: '#6A7581' }}>
                Home Address
              </Divider>
              <p>
                Street Address: <b>{previousData.street_address}</b>
              </p>
              <p>
                Apartment: <b>{previousData.apartment}</b>
              </p>
              <p>
                City: <b>{previousData.city}</b>
              </p>
              <p>
                State: <b>{previousData.state}</b>
              </p>
              <p>
                Zip: <b>{previousData.zip}</b>
              </p>
              <p>
                Country: <b>{previousData.country}</b>
              </p>


            </ConfirmationPrompt>
          )}
        </Form>
      )}
    </Formik>
  )
}
