const initialState = {
  documentsData: null,
  documentsLoading: false,
  documentsError: null,
  workflowData: {},
  pastWorkflowData: {},
  signatureData: { loading: false, modalVisible: false },
};

export const documentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_DOCUMENTS':
      return { ...state, documentsLoading: true, documentsError: null, documentsData: [] };
    case 'FETCH_DOCUMENTS_SUCCESS':
      return { ...state, documentsLoading: false, documentsError: null, documentsData: action.payload };
    case 'FETCH_DOCUMENTS_ERROR':
      return { ...state, documentsLoading: true, documentsError: action.payload, documentsData: [] };
    case 'FETCH_DOCUMENT_WORKFLOW_DATA':
      return { ...state, documentsLoading: true, documentsError: null };
    case 'FETCH_DOCUMENT_WORKFLOW_DATA_SUCCESS':
      return { ...state, documentsLoading: false, documentsError: null, workflowData: { ...action.payload.data, period: action.payload.period } };
    case 'FETCH_DOCUMENT_WORKFLOW_DATA_ERROR':
      return { ...state, documentsLoading: true, documentsError: action.payload, workflowData: {} };
    case 'SAVE_DOCUMENT_WORKFLOW_DATA':
      return { ...state, documentsLoading: true, documentsError: null, workflowData: { ...state.workflowData, ...action.payload } };
    case 'SAVE_DOCUMENT_WORKFLOW_DATA_SUCCESS':
      return { ...state, documentsLoading: false, documentsError: null, workflowData: { ...state.workflowData, ...action.payload } };
    case 'SAVE_DOCUMENT_WORKFLOW_DATA_ERROR':
      return { ...state, documentsLoading: true, documentsError: action.payload };
    case 'FETCH_DOCUMENT_WORKFLOW_DATA_PAST':
      return { ...state, documentsLoading: true, documentsError: null };
    case 'FETCH_DOCUMENT_WORKFLOW_DATA_PAST_SUCCESS':
      return { ...state, documentsLoading: false, documentsError: null, pastWorkflowData: { ...action.payload.data, period: action.payload.period } };
    case 'FETCH_DOCUMENT_WORKFLOW_DATA_PAST_ERROR':
      return { ...state, documentsLoading: true, documentsError: action.payload };
    case 'DOCUMENT_SIGNATURE_SUCCEED':
      return { ...state, signatureData: { ...action.payload, loading: false } };
    case 'DOCUMENT_SIGNATURE_STARTED':
      return { ...state, signatureData: { loading: true } };
    case 'MODLA_VISIBLE_UPDATE':
      return { ...state, signatureData: { ...state.signatureData, modalVisible: action.payload.modalVisible } };
    default:
      return state;
  }
}