import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import NotificationDot from '../NotificationDot';

function MenuItem(props) {
    const {
        title,
        icon,
        link,
        onClick,
        style,
        sideEffect,
        disabled,
        active,
        hasNotification,
    } = props;
    const history = useHistory();

    const clickHandler = () => {
        if (disabled) return;
        if (sideEffect) sideEffect();

        if (link) {
            history.push(link);
            return;
        }

        if (onClick) {
            onClick();
            return;
        }
    };

    return (
        <Item
            style={style}
            onClick={clickHandler}
            disabled={disabled}
            active={active}
        >
            {icon && <img src={icon} alt="" />}
            {title}
            {hasNotification && <NotificationDot />}
        </Item>
    );
}

const Item = styled.li`
    margin-top: 33px;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    align-items: center;
    color: ${({ active, theme }) =>
        active ? theme.primaryColor : theme.textColor};
    cursor: pointer;

    img {
        width: 20px;
        height: auto;
        margin-right: 10px;
    }

    &:hover {
        color: ${({ theme }) => theme.primaryColor};
    }

    &[disabled] {
        opacity: 0.2;
        cursor: not-allowed;
    }
`;

export default MenuItem;
