import { hamburgerIcon, logoImg } from 'assets/img';
import styled from 'styled-components';

function Header({ showMenu, showTrigger, style, onLogoClick }) {
    const handleLogoClick = () => {
        if (onLogoClick) {
            onLogoClick();
        } else {
            return;
        }
    };

    return (
        <Wrapper collapsed={showTrigger} style={style}>
            {showTrigger && (
                <img src={hamburgerIcon} alt="" onClick={showMenu} />
            )}
            <img
                className="main-logo"
                src={logoImg}
                alt="taxrise"
                onClick={handleLogoClick}
            />
        </Wrapper>
    );
}

const Wrapper = styled.header`
    height: 58px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25);
    padding: 18px 32px;
    position: fixed;
    top: 0;
    left: ${({ collapsed }) => (collapsed ? '0' : '240px')};
    right: 0;
    background-color: #fff;
    z-index: 999;

    img {
        height: 15px;
        cursor: pointer;
    }
    .main-logo {
        height: 21px;
        position: absolute;
        top: 18px;
        left: 50%;
        margin-left: -56px;
    }

    @media (min-width: 992px) {
        box-shadow: none;
    }
`;

export default Header;
