import { useEffect } from 'react';
import UserDetailContext from './UserDetailContext';
import { useLocalStorage } from 'hooks';

function UserDetailProvider({ children }) {
    const [userDetailInfo, dispatchUserDetail] = useLocalStorage('userInfo', {
        first_name: '',
        last_name: '',
        case_id: '',
    });

    useEffect(() => {
          window.FreshworksWidget('identify', 'ticketForm', {
              name: `${userDetailInfo.first_name} ${userDetailInfo.last_name}`,
              email: userDetailInfo.email,
          });
          if(userDetailInfo?.case_id){
              window.FreshworksWidget('prefill', 'ticketForm', {
                priority: 3,
                status: 2,
                custom_fields: {
                  cf_test: userDetailInfo.case_id,
                },
              });
          }
          else{
              window.FreshworksWidget('prefill', 'ticketForm', {
                  priority: 3,
                  status: 2,
              });
          }

          window.FreshworksWidget('hide', 'ticketForm', ['priority','status','custom_fields.cf_test']);
      },
      [userDetailInfo]);


    const storeUserInfo = (userDetails) => {
        dispatchUserDetail(userDetails);
    };

    return (
        <UserDetailContext.Provider
            value={{ userDetailInfo, dispatchUserDetail: storeUserInfo }}
        >
            {children}
        </UserDetailContext.Provider>
    );
}

export default UserDetailProvider;
