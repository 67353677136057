import { Button, Divider, Row, Col } from 'antd';
import { RadioGroup, TextAreaInput, TextInput } from 'components/FormFields';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { BOOLEAN_OPTIONS } from 'const';
import { ConfirmationPrompt } from '../ConfirmationPrompt';
import { useState, useEffect } from 'react';


export function LifeEvents({ data, previousData, handleSubmit, handleBack }) {
  const initialValues = {
    is_marital_status_changed: data.is_marital_status_changed || previousData.is_marital_status_changed || '',
    is_changed_name: data.is_changed_name || previousData.is_changed_name || '',
    is_irs_notice_received: data.is_irs_notice_received || previousData.is_irs_notice_received || '',
    is_pin_received: data.is_pin_received || previousData.is_pin_received || '',
    is_child_born: data.is_child_born || previousData.is_child_born || '',
    is_armed_forces: data.is_armed_forces || previousData.is_armed_forces || '',
    comment_questionnaire_life_events: data.comment_questionnaire_life_events || previousData.comment_questionnaire_life_events || '',
    pin_number: data.pin_number || previousData.pin_number || '',
  }

  const validationSchema = Yup.object().shape({
    is_marital_status_changed: Yup.string().required('Required'),
    is_changed_name: Yup.string().required('Required'),
    is_irs_notice_received: Yup.string().required('Required'),
    is_pin_received: Yup.string().required('Required'),
    is_child_born: Yup.string().required('Required'),
    is_armed_forces: Yup.string().required('Required'),
  });

  const [isConfirmation, setIsConfirmation] = useState(true);

  useEffect(() => {
    setIsConfirmation(!data.is_marital_status_changed && !!previousData.is_marital_status_changed)
  }, [data, previousData]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnMount
      validateOnChange
      enableReinitialize>
      {({ setFieldValue, values, submitForm }) => (
        <Form>
          {!isConfirmation && (
            <>
              <Divider orientation="left" orientationMargin="0" style={{ color: '#6A7581' }}>
                Life Events
              </Divider>

              <RadioGroup
                required
                options={BOOLEAN_OPTIONS}
                label={`Did your marital status change in ${data.period}?`}
                name="is_marital_status_changed"
              />
              <RadioGroup
                required
                options={BOOLEAN_OPTIONS}
                label={"Did you, spouse, or a dependent change their name with the Social Security Administration?"}
                name="is_changed_name"
              />
              <RadioGroup
                required
                options={BOOLEAN_OPTIONS}
                label={"Did you receive a notice or letter from the IRS or a state revenue agency?"}
                name="is_irs_notice_received"
              />
              <RadioGroup
                required
                options={BOOLEAN_OPTIONS}
                label={`Did you, spouse, or a dependent receive an identity protection PIN (IP PIN) from the IRS in ${data.period}?`}
                name="is_pin_received"
              />

              {values.is_pin_received === 'Yes' && (
                <TextInput 
                  name="pin_number"
                  label="Enter your PIN number"
                />
              )}

              <RadioGroup
                required
                options={BOOLEAN_OPTIONS}
                label={`Were any children born or adopted in ${data.period}?`}
                name="is_child_born"
              />
              <RadioGroup
                required
                options={BOOLEAN_OPTIONS}
                label={"Were you a member of the Armed Forces?"}
                name="is_armed_forces"
              />

              <div>
                <Button type="secondary" htmlType="button" onClick={handleBack}>
                  Back
                </Button>
                <Button type="primary" htmlType="submit" style={{ float: 'right' }}>
                  Continue
                </Button>
              </div>
              <Row>
                <Col span={24}>
                  <TextAreaInput
                    name="comment_questionnaire_life_events"
                    label="Comment (optional)"
                    placeholder="Enter your comment here"
                  />
                </Col>
              </Row>
            </>
          )}
          {isConfirmation && (
            <ConfirmationPrompt
              submitForm={submitForm}
              setIsConfirmation={setIsConfirmation}
            >
              <p>
                Is marital status changed: <b>{previousData.is_marital_status_changed}</b>
              </p>
              <p>
                Did the taxpayer, spouse, or a dependent change their name with the Social Security Administration: <b>{previousData.is_changed_name}</b>
              </p>
              <p>
                Did you receive a notice or letter from the IRS or a state revenue agency: <b>{previousData.is_irs_notice_received}</b>
              </p>
              <p>
                Did the taxpayer, spouse, or a dependent receive an identity protection PIN (IP PIN) from the IRS: <b>{previousData.is_pin_received}</b>
              </p>
              <p>
                Were any children born or adopted: <b>{previousData.is_child_born}</b>
              </p>
              <p>
                Were you a member of the Armed Forces: <b>{previousData.is_armed_forces}</b>
              </p>
            </ConfirmationPrompt>
          )}
        </Form>
      )}
    </Formik>
  )
}
