import { useEffect } from 'react';
import styled from 'styled-components';
import { Link, useHistory } from 'react-router-dom';
import paths from 'routes/paths';
import { PageHeader } from 'components/Header';
import { ContentWrap } from 'components/Layout';
function ResetSuccess(props) {
    const history = useHistory();

    useEffect(() => {
        setTimeout(
          () => history.push(paths.LOGIN),
          5000
        );
    },[history])

    return (
        <Wrapper>
            <PageHeader>
                <h1>
                    You’ve successfully reset your password!
                </h1>
            </PageHeader>
            <ContentWrap width="490px">
                <form>
                    <p>
                        You will be automatically redirected to your client portal log in page
                        in 5 seconds. If you are not redirected, please{' '}
                        <Link to={paths.LOGIN} className="text-underline">
                            click here
                        </Link>
                    </p>
                </form>
            </ContentWrap>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 122px);
    form {
        @media (max-width: 767px) {
            padding: 0 32px;
        }
    }
`;

export default ResetSuccess;
