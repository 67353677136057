import moment from 'moment';
import { DATE_FORMAT } from 'const';
/**
 * This function converts a number to US Currency Format
 *
 * @param {number} amount - Number to convert to US Currency
 *
 * @example
 *     toUsCurrency(1230000);
 */

export const toUsCurrency = (amount) => {
    const format = {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
    };

    const NumberFormat = new Intl.NumberFormat('en-US', format);
    const formattedAmount = parseFloat(amount);

    if (!isNaN(formattedAmount)) {
        return NumberFormat.format(formattedAmount);
    }
};

// Function to calculate percentage based on the total amount.
export const calculatePercentage = (amount, totalAmount) => {
    if (totalAmount === 0) return 0;
    return ((amount / totalAmount) * 100).toFixed(1);
};

export const toThousandAmount = (amount) => {
    const num = amount / 1000;
    return `$${Math.round(num * 10) / 10}K`;
};

export const parseAmount = (amount) => {
    if(amount){
        amount = amount.toString()
        return parseFloat(amount.replace(/[,|$]/g, ''));
    }
    else {
        return 0.00
    }
    
};

export const toUsDateFormat = (isoDate) => {
    if (!isoDate || !moment(isoDate).isValid()) return '';

    return moment(isoDate).format('MM/DD/YYYY');
};

export const getCardExpireDate = (dateString) => {
    if (!dateString) return '';
    return `${dateString.slice(0, 2)}/${dateString.slice(-2)}`;
};

/* export const redirectToVerifilink = () => {
    window.open('https://www.verifilink.com/', '_blank');
}; */

export const ellipsisText = (text, ellipsisLength = 4) => {
    return text.length <= ellipsisLength
        ? text
        : `${text.substring(0, ellipsisLength)}...`;
};

export const processAddress = (addressObj) => {
    if (!addressObj) return;

    const { line_1, line_2, zip_code, city, state } = addressObj;

    let address = line_1;

    if (line_2) address += `, ${line_2}`;

    address += `, ${city} ${state}, ${zip_code}`;

    return address;
};

export const toCapitalize = (text) => {
    return text.replace(/\b\w/g, (l) => l.toUpperCase());
};

export function cleanObj(obj) {
    for (const propName in obj) {
        if (
            obj[propName] === null ||
            obj[propName] === undefined ||
            obj[propName] === ''
        ) {
            delete obj[propName];
        }
    }
    return obj;
}

export const formateDateTime = (dateTime, format = DATE_FORMAT) => {
    return moment(dateTime).format(format);
};
