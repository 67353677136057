import { useEffect, useState } from 'react';
import TaskInfo from 'components/TaskInfo';
import { Col, Row } from 'components/Layout';
import { PopupText } from 'react-calendly';
import { Fragment } from 'react';
import { arrowRightBlue } from 'assets/img';
import { useApi, endpoints } from 'api';
import { LinkButton } from 'components/Button';
import { Link } from 'react-router-dom';
import paths from 'routes/paths';
import { useVerifilinkRedirect } from 'hooks';

function TasksTab({ tasks, available, completed }) {
    const [cmCalendlyUrl, setCalendlyUrl] = useState('');
    const [api] = useApi();

    useEffect(() => {
        const getCalendlyData = async () => {
            try {
                const req = await api({
                    url: endpoints.CM_CALENDLY,
                    params: { todo_id: tasks[0].id },
                });
                const res = await req.data;

                if (res['data'] && res['data']['CalendlyPage']) {
                    setCalendlyUrl(res.data.CalendlyPage);
                }
            } catch (error) {
                console.log(error);
            }
        };

        if (tasks.length) {
            getCalendlyData();
        }
    }, [api, tasks]);

    if (completed) {
        return (
            <p className="text-center color-gray" style={{ maxWidth: 600 }}>
                <small>
                    You&apos;re all caught up! You have no tasks to complete at
                    the moment! We will send you a text or email when you have a
                    new task to complete!
                </small>
            </p>
        );
    }

    if (!available) {
        return (
            <p className="text-center color-gray">
                <small>Tasks are not available!</small>
            </p>
        );
    }

    return (
        <Row gutter={16} justify="center" type="flex">
            {tasks.map((data, index) => (
                <Col xs={24} md={12} key={index} className="mt-24">
                    <TaskInfo
                        title={data.title}
                        status="Not started"
                        addon={
                            <BeginTask task={tasks[0].id} url={cmCalendlyUrl} />
                        }
                        subTitle={data.details}
                    />
                </Col>
            ))}
        </Row>
    );
}

const BeginTask = ({ task, url }) => {
    const [, redirectToVerifilink] = useVerifilinkRedirect();

    const buttonText = (
        <Fragment>
            Begin task&nbsp;
            <img src={arrowRightBlue} alt="" />
        </Fragment>
    );

    // Task 3 is Complete Verifilink
    if (task === 3) {
        return (
            <LinkButton onClick={redirectToVerifilink}>{buttonText}</LinkButton>
        );
    }

    // Task 4 is Upload Documents
    if (task === 4) {
        return <Link to={paths.DOCUMENTS}>{buttonText}</Link>;
    }

    // Schedule Task When calendly url is available
    if (url) {
        return <PopupText text={buttonText} url={url} />;
    }

    return null;
};

export default TasksTab;
