import styled, { keyframes } from 'styled-components';

function NotificationDot() {
    return (
        <RingContainer>
            <div className="center-circle" />
            <div className="mid-circle" />
            <div className="out-circle" />
        </RingContainer>
    );
}

const pulsateMidCircle = keyframes`
    0% {
        transform: scale(0.1, 0.1);
        opacity: 1;
    }
    50% {
      opacity: 0.1;
    }
    100% {
        transform: scale(2, 2);
        opacity: 0.0;
    }
`

const pulsateOutCircle = keyframes`
    0% {
        transform: scale(0.5, 0.5);
        opacity: 1;
    }
    50% {
      opacity: 0.05;
    }
    100% {
        transform: scale(3, 3);
        opacity: 0.0;
    }
`

const RingContainer = styled('div')`
    display: inline-block;
    position: relative;
    width: 18px;
    height: 18px;
    
    .center-circle {
        width: 6px;
        height: 6px;
        background-color: #DA1E27;
        border-radius: 50%;
        position: absolute;
        top: 6px;
        left: 6px;
        z-index: 3;
    }
    
    .mid-circle {
        background-color: #DA1E27;
        border-radius: 30px;
        height: 6px;
        width: 6px;
        position: absolute;
        left: 6px;
        top: 6px;
        animation: ${pulsateMidCircle} 2s ease-out;
        animation-iteration-count: infinite;
        opacity: 0.0;
        z-index: 2;
    }
    
    .out-circle {
        background-color: #DA1E27;
        border-radius: 30px;
        height: 6px;
        width: 6px;
        position: absolute;
        left: 6px;
        top: 6px;
        animation: ${pulsateOutCircle} 2s ease-out;
        animation-iteration-count: infinite;
        z-index: 0;
    }
`;

export default NotificationDot;
