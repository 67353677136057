import { Button, Divider, Row, Col } from 'antd';
import { RadioGroup, TextAreaInput } from 'components/FormFields';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { BOOLEAN_OPTIONS } from 'const';
import { ConfirmationPrompt } from '../ConfirmationPrompt';
import { useState, useEffect } from 'react';

export function FinancialEvents({ data, previousData, handleSubmit, handleBack }) {
  const initialValues = {
    is_property_sold: data.is_property_sold || previousData.is_property_sold || '',
    is_property_purchesed: data.is_property_purchesed || previousData.is_property_purchesed || '',
    is_property_damage: data.is_property_damage || previousData.is_property_damage || '',
    is_retirement_accounts_converted: data.is_retirement_accounts_converted || previousData.is_retirement_accounts_converted || '',
    is_digital_assets_sold: data.is_digital_assets_sold || previousData.is_digital_assets_sold || '',
    is_foreign_account: data.is_foreign_account || previousData.is_foreign_account || '',
    is_gift: data.is_gift || previousData.is_gift || '',
    comment_questionnaire_financial_events: data.comment_questionnaire_financial_events || previousData.comment_questionnaire_financial_events || '',
  }

  const validationSchema = Yup.object().shape({
    is_property_sold: Yup.string().required('Required'),
    is_property_purchesed: Yup.string().required('Required'),
    is_property_damage: Yup.string().required('Required'),
    is_retirement_accounts_converted: Yup.string().required('Required'),
    is_digital_assets_sold: Yup.string().required('Required'),
    is_foreign_account: Yup.string().required('Required'),
    is_gift: Yup.string().required('Required'),
  });

  const [isConfirmation, setIsConfirmation] = useState(true);

  useEffect(() => {
    setIsConfirmation(!data.is_property_sold && !!previousData.is_property_sold)
  }, [data, previousData]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnMount
      validateOnChange
      enableReinitialize>
      {({ setFieldValue, values, submitForm }) => (
        <Form>
          {!isConfirmation && (
            <>
              <Divider orientation="left" orientationMargin="0" style={{ color: '#6A7581' }}>
                Financial Events
              </Divider>

              <RadioGroup
                required
                options={BOOLEAN_OPTIONS}
                label={"Did you sell any property?"}
                name="is_property_sold"
              />
              <RadioGroup
                required
                options={BOOLEAN_OPTIONS}
                label={"Did you buy any property?"}
                name="is_property_purchesed"
              />
              <RadioGroup
                required
                options={BOOLEAN_OPTIONS}
                label={"Did you incur property damage or theft caused by a federally declared disaster?"}
                name="is_property_damage"
              />
              <RadioGroup
                required
                options={BOOLEAN_OPTIONS}
                label={"Did you convert or roll over any retirement accounts?"}
                name="is_retirement_accounts_converted"
              />
              <RadioGroup
                required
                options={BOOLEAN_OPTIONS}
                label={"Did you receive, sell, exchange, gift, or otherwise dispose of a digital asset?"}
                name="is_digital_assets_sold"
              />
              <RadioGroup
                required
                options={BOOLEAN_OPTIONS}
                label={"Did you have a financial interest in or signature authority over a foreign account or trust?"}
                name="is_foreign_account"
              />
              <RadioGroup
                required
                options={BOOLEAN_OPTIONS}
                label={"Did you gift $16,000 or more to another individual?"}
                name="is_gift"
              />
              <div>
                <Button type="secondary" htmlType="button" onClick={handleBack}>
                  Back
                </Button>
                <Button type="primary" htmlType="submit" style={{ float: 'right' }}>
                  Continue
                </Button>
              </div>
              <Row>
                <Col span={24}>
                  <TextAreaInput
                    name="comment_questionnaire_financial_events"
                    label="Comment (optional)"
                    placeholder="Enter your comment here"
                  />
                </Col>
              </Row>
            </>
          )}
          {isConfirmation && (
            <ConfirmationPrompt
              submitForm={submitForm}
              setIsConfirmation={setIsConfirmation}
            >
              <p>
                Did you buy a home or rental property?: <b>{previousData.is_property_sold}</b>
              </p>
              <p>
                Did you sell a home or rental property?: <b>{previousData.is_property_purchesed}</b>
              </p>
              <p>
                Did you incur property damage or theft caused by a federally declared disaster?: <b>{previousData.is_property_damage}</b>
              </p>
              <p>
                Did you convert or roll over any retirement accounts?: <b>{previousData.is_retirement_accounts_converted}</b>
              </p>
              <p>
                Did you receive, sell, exchange, gift, or otherwise dispose of a digital asset?: <b>{previousData.is_digital_assets_sold}</b>
              </p>
              <p>
                Did you have a financial interest in or signature authority over a foreign account or trust?: <b>{previousData.is_foreign_account}</b>
              </p>
              <p>
                Did you gift $16,000 or more to another individual?: <b>{previousData.is_gift}</b>
              </p>
            </ConfirmationPrompt>
          )}
        </Form>
      )}
    </Formik>
  )
}
